import { getInfoServerLink } from '../utilities'

export default {
  yes: 'Sì',
  no: 'No',
  ok: 'OK',
  cancel: 'Annulla',
  information: 'Informazione',
  home: 'Posizione',
  header: 'Skitourenguru',
  news: 'News',
  attention: 'Attenzione',
  filterTable: {
    search: 'Cerca per nome della cima',
    filter: 'Filtra gli itinerari',
    to: 'fino a',
    of: 'di',
    results: 'Risultato:',
    routes: 'itinerari',
  },
  regions: {
    ch: 'Svizzera',
    au: 'Alpi orientali',
    fr: 'Francia',
    ie: 'Italia nordorientale',
    iw: 'Italia nordoccidentale',
  },
  tabs: {
    map: 'Mappa',
    regionsList: 'Introduzione',
    trackDetails: 'Itinerario in dettaglio',
    trackSearch: 'Ricerca un itinerario',
  },
  menu: {
    info: 'Background',
    language: 'de en fr it',
    manual: 'Manuale',
    video: 'Videos di formazione',
    news: 'News',
    media: 'Media',
    prevention: 'Prevenzione incidenti (BPI)',
    about: 'About',
    drawRoute: 'Disegnare i percorsi'
  },
  intro: {
    summer: {
      title: 'Stagione estiva',
      content:
        "Di solito i bollettini valanghe sono disponibili tra novembre e maggio. Per le regioni senza bollettini valanghe, viene visualizzata una versione demo (Off). Attenzione: i risultati in queste regioni non sono validi!",
    },
    guru: {
      title: "Che cos'è Skitourenguru",
      content:
        'Skitourenguru ti aiuta a scegliere e pianificare gite scialpinistiche a basso rischio valanghe. Skitourenguru lo fa assegnando ogni giorno un indicatore di rischio a migliaia di gite in tutta la regione alpina:',
      table: {
        header: {
          symbol: 'Simbolo',
          values: 'Valori',
          risks: 'Rischio valanghe',
        },
        content: {
          values: {
            0: '0\u200B\u2013\u200B1',
            1: '1\u200B\u2013\u200B2',
            2: '2\u200B\u2013\u200B3',
          },
          risks: {
            0: 'Rischio valanghe basso',
            1: 'Rischio valanghe considerevole',
            2: 'Rischio valanghe elevato',
          },
          riskQualifiers: {
            0: 'basso',
            1: 'considerevole',
            2: 'elevato',
          },
        },
      },
    },
    cruxes: {
      content: 'In aggiunta a questo, Skitourenguru identifica i passi chiave con anelli grigi:',
      table: {
        header: {
          symbol: 'Simbolo',
          values: 'Classe',
          risks: 'Significato',
        },
        content: {
          values: {
            0: '1',
            1: '2',
            2: '3',
          },
          classes: {
            0: 'Terreno valanghivo',
            1: 'Terreno valanghivo tipico',
            2: 'Terreno valanghivo molto tipico',
          },
        },
      },
    },
    limits: {
      1: 'Sul posto e nel singolo pendio, sono di solito accessibili informazioni che non sono disponibili a Skitourenguru. ',   
      2: 'Le informazioni presentate su Skitourenguru sono soggette a incertezze (vedi ',
      3: {
        text: 'Manuale',
        link: getInfoServerLink() + '/download/intro/Intro_IT.pdf',
      },
      4: '). ',
      5: "Pertanto Skitourenguru non deve essere l'unico criterio per decidere se attraversare un pendio."
    },
    choose: {
      title: 'Scegli una regione',
      table: {
        header: {
          region: 'Regione',
          status: 'Stato',
          edition: 'Edizione',
          valid: 'Valido',
        },
      },
    },
    supported: {
      title: 'Partner',
      content: 'Skitourenguru è per questo sostenuto dai seguenti partner.',
      partners: {
        bfu: {
          name: 'BPI',
          link: 'https://www.bfu.ch/it/consigli/sciescursionismo',
        },
        sac: {
          name: 'CAS',
          link: 'https://www.sac-cas.ch/it/formazione-e-sicurezza/sicuri/sicuri-nelle-escursioni-con-gli-sci/'
        },
        colltex: {
          name: 'colltex',
          link: 'https://www.colltex.ch/'
        }   
      }
    },
  },
  difficultyScales: {
    0: '/',
    1: 'F/1.2',
    2: 'F+/1.3',
    3: 'PD\u2212/2.1',
    4: 'PD/2.2',
    5: 'PD+/2.3',
    6: 'AD\u2212/3.1',
    7: 'AD/3.2',
    8: 'AD+/3.3',
    9: 'D\u2212/4.1',
    10: 'D/4.2',
    11: 'D+/4.3',
    12: 'TD\u2212/5.1',
    13: 'TD/5.2',
    14: 'TD+/5.3',
    15: 'ED\u2212/5.3',
    16: 'ED/5.4',
    17: 'ED+/5.4',
    18: 'EX/5.5',
  },
  langDialog: {
    chooseLang: 'Scegli una lingua',
  },
  disclaimer: {
    title: "Condizioni per l’uso",
    text: {
      0: "Questo prototipo adatta un percorso scelto da té e ne stima il rischio valanghe. Si noti che si tratta di un prototipo e che i risultati sono sostanzialmente incerti!",
      1:
        "Skitourenguru è da intendersi come strumento aggiuntivo per la selezione e la pianificazione indipendente e autonoma, sotto la propria responsabilità, di un tour di " + 
        "scialpinismo e per la relativa valutazione del rischio valanghe. A tal fine, migliaia di tour di scialpinismo dell'arco alpino vengono classificati automaticamente " +
        "ogni giorno, assegnando loro un livello di rischio valanghe in base agli attuali ",
      2: {
        text: 'bollettini valanghe',
        link: getInfoServerLink() + '/index.php/ostalpen',
      },
      3: '  locali e ai ',
      4: {
        text: 'dati ufficiali relativi al terreno',
        link: getInfoServerLink() + '/index.php/athm-de',
      },
      5: ', secondo regole di esecuzione programmate: verde (rischio basso), arancione (rischio considerevole) o rosso (rischio elevato). ',
      6: "Skitourenguru GmbH non offre alcuna garanzia e quindi non si assume alcuna responsabilità per l'accuratezza delle informazioni e delle valutazioni presentate. ",
      7: 'Tali informazioni possono essere errate (vedere il ',
      8: {
        text: 'manuale',
        link: getInfoServerLink() + '/download/intro/Intro_IT.pdf',
      },
      9: "). Pertanto, si ricorda espressamente che Skitourenguru può essere utilizzato solo come fonte di informazioni supplementari per la pianificazione autonoma di un tour " +
        "di scialpinismo. ",
      10: "Durante l'utilizzo di Skitourenguru vengono raccolti dati anonymizati. ",
      11: {
        text: 'L’informativa sulla privacy',
        link: getInfoServerLink() + '/privacy-en',   
      },
      12: ' ti informa su quali dati raccogliamo e quali scopi perseguiamo con i tuoi dati.',
      13: "Sei d'accordo con queste condizioni per l’uso?",      
    },
  },
  editionDescription: 'Orario ideale per la consultazione di Skitourenguru',
  regionStatus: {
    0: {
      short: '-',
      medium: '-',
      long: '',
      validity: '',
    },
    1: {
      short: 'On',
      medium: 'In servizio',
      long: 'Il servizio per la regione è attivo.',
      validity: '',
    },
    2: {
      short: 'Test',
      medium: 'Modalità test',
      long:
        'Il servizio per la regione è attivo, ma in modalità test. La modalità test viene attivata per regioni aventi poca esperienza. Le informazioni visualizzate sono meno affidabili delle regioni completamente attive.',
      validity: 'Risultati incerti',
    },
    3: {
      short: 'Wait',
      medium: 'In calcolo',
      long:
        'Per questa regione non sono momentaneamente disponibili dati validi. Skitourenguru li sta calcolando. Riprova tra 15 minuti con un Refresh (F5).',
      validity: 'Risultati scaduti',
    },
    4: {
      short: 'Off',
      medium: 'Modalità demo',
      long:
        'Nessun bollettino valanghe valido. La regione è in modalità dimostrazione. I risultati visualizzati sono invalidi.',
      validity: 'Risultati non validi',
    },
    5: {
      short: 'Project',
      medium: 'In progettazione',
      long: 'La regione è in fase di pianificazione. Tutti i risultati visualizzati sono invalidi.',
      validity: 'Risultati invalidi',
    },
  },
  vectorLayers: {
    1: 'Regioni',
    2: 'Rischio valanghe',
    3: 'Itinerari',
    4: 'Posizioni',
    5: 'Profili di rischio',
    6: 'Passaggi chiave',
    7: 'Webcams',
    8: 'Bolletino valanghe',
    9: 'Incidenti da valanga',
    10: 'Posizione attuale',
    11: 'Deposito sci',
    12: 'Aree protette'
  },
  layerGroups: {
    title: 'Layer Switcher',
    base: 'Mappe base',
    overlay: 'Mappe overlay',
    objects: 'Ogetti topografici',
  },
  rasterLayers: {
    1009: 'Test',
    1010: 'BaseMap TopoMap (Austria)',
    1011: 'BEV/OTM (Alpi orientali)',
    1012: 'BKG TopoMap (Germania)',
    1013: 'Bergfex TopoMap (Austria)',
    1014: 'Kompass TopoMap (Alpi)',
    1015: 'Google Hybrid',
    1016: 'ESRI TopoMap',
    1017: 'OpenStreetMap',
    1018: 'OpenTopoMap (Mondo)',
    1019: 'MAP1',
    1020: 'OpenCycleMap',
    1021: 'OSMAnd',
    1022: 'OSM Humanitarian',
    1023: 'Wikimedia',
    1024: 'MountainMap',
    1025: 'IGN TopoMap (Francia)',
    1026: 'IGN TopoMap (Francia)',
    1027: 'IGN Slope (Francia)',
    1028: 'Swisstopo TopoMap',
    1029: 'Classi di pendenza',
    1030: 'TMC TopoMap (Svizzera)',
    1031: 'OpenTopoMap (Alpi)',
    1032: 'IGN TopoMap (Francia)',
    1033: 'IGM TopoMap (NE-Italia)',
    1034: 'IGM TopoMap (NO-Italia)',
    1045: 'Corridoi (Svizzera)',
    1046: 'SkiMap (Svizzera)',
    1047: 'Exolabs Maschera neve',
    1048: 'Exolabs Altezza neve',
    1049: '4UMaps',
    1050: 'Siti di protezione',
    1051: 'Zone di tranquillità',
    1052: 'Itinerari sci (CAS)',  
    1053: 'Fermate mezzi',
    1054: 'Swissimage',
    1055: 'Swisstopo 1:10K',
    1056: 'Swisstopo TopoMap (x2)',
    1057: 'Terreno valanghivo (ATH)',
    1058: 'Terreno valanghivo (CAT)',
    1059: 'Rischio di caduta',
    1060: 'Reclusione',
    1061: 'Aree protette',
    1062: 'Terreno valanghivo (ATHM)',
    1063: 'Classi di pendenza',
    1064: 'Rischio di caduta',
    1065: 'IGN Ortofoto',
    1066: 'Austria Ortofoto',
    1067: 'OTM/Swisstopo/BEV/LDBV',
    1068: 'Exolabs Differenza di neve (-48h)',
    1069: 'Exolabs Differenza di neve (+48h)',          
    1070: 'Piste da sci',
    1071: 'Percorsi escursionistici',
    1072: 'Corridoio',
    1073: 'Itinerari Skitourenguru'      
  },
  bulletin: {
    title: 'Bolletino valanghe',
    dangerLevels: {
      1: 'Debole',
      2: 'Moderato',
      3: 'Marcato',
      4: 'Forte',
      5: 'Molto forte',
    },
    dayCourseWarning: 'Attenzione: grado di pericolo in risalita durante il giorno. Le stime sono valide solo per le ore favorevoli del mattino (solitamente prima di mezzogiorno).',
    invalidWarning: 'Attenzione: bollettino valanghe non aggiornato. Le stime sono più valide!',
    skitourenguruWarning: "Il grado di pericolo e i 'punti pericolosi' sono mostrati di seguito, così come vengono inseriti nell'algoritmo di Skitourenguru. Il grado di pericolo ed i 'punti pericolosi' vengono letti da bollettini valanghe strutturalmente diversi. In casi particolari, questo può portare a differenze rispetto al bollettino valanghe originale.",
    originalWarning: "Il contenuto del bollettino delle valanghe originale è presentato di seguito in una forma armonizzata e il più possibile completa. Poiché i bollettini delle valanghe della regione alpina sono strutturalmente diversi, in casi particolari possono verificarsi deviazioni dal bollettino originale.",
    coreZoneInfo: "Skitourenguru presume che il grado di pericolo al di fuori dei 'punti pericolosi'sia di un livello inferiore.",
    dangerLevelWarning3: "Attenzione: Questo percorso si trova in un'area con grado di pericolo 'Marcato'. Il modello Skitourenguru si concentra sulle valanghe provocate dalle persone (95% degli incidenti mortali). Tuttavia, anche delle valanghe spontanee sono possibili al grado dei pericolo 'Marcato'. Includetele nella vostra pianificazione a partire dal grado di pericolo 'Marcato (3+)' al più tardi!",
    dangerLevelWarning4: "Attenzione: Questo percorso si trova in una zona con un livello di pericolo 'Forte'. Il modello Skitourenguru si concentra sulle valanghe provocate dalle persone (95% degli incidenti mortali). Tuttavia, anche le valanghe spontanee sono probabili al grado dei pericolo 'Forte', quindi includetele nella vostra pianificazione!",
    table: {
      edition: 'Edizione',
      validTill: 'Valido fino a',
      dangerLevel: 'Grado di pericolo',
    },
    periode: {
      day_periode: 'Tutto il giorno',
      cold_periode: 'Mattina',
      hot_periode: 'Pomeriggio'
    },
    moisture: {
      overview: 'Panoramica',
      dry: 'Valanghe asciutte',
      wet: 'Valanghe di neve bagnata'
    },        
    panelHeaders: {
      texts: 'Descrizione testuale',
      feedback: 'Feedback',
      algorithm: 'Base per Skitourenguru'
    },   
    problems: {
      NEW_SNOW: "Neve fresca",
      WIND_SLAB: "Neve ventata",
      WET_SNOW: "Valanghe bagnate",
      PERSISTENT_WEAK_LAYERS: "Neve vecchia",
      GLIDING_SNOW: "Valanghe da reptazione",
      FAVOURABLE_SITUATION: "Situazione favorevole",
      NO_DISTICT_AVALANCHE_PROBLEM: "Nessun problema di valanghe pronunciato"
    },
    problemLink: 'https://www.slf.ch/it/bollettino-valanghe-e-situazione-nivologica/info-bollettino-valanghe/problemi-tipici-valanghivi/',
    publisher: {
      ch: {
        title: '©\u2006SLF',
        link: 'https://whiterisk.ch/it/conditions',
      },
      au: {
        title: 'a seconda della regione',
        link: getInfoServerLink() + '/index.php/ostalpen',
      },
      fr: {
        title: '©\u2006Meteo France',
        link: 'http://www.meteofrance.com/previsions-meteo-montagne/bulletin-avalanches',
      },
      ie: {
        title: '©\u2006AINEVA',
        link: 'https://bollettini.aineva.it/bulletin/latest',
      },
      iw: {
        title: '©\u2006AINEVA',
        link: 'https://bollettini.aineva.it/bulletin/latest',
      },
      title: "Link all'originale",
    },
    feedback: {
      text: 'Segnalate le vostre osservazioni al SLF e contribuite a migliorare la qualità del bollettino valanghe:',
      link1: 'https://www.slf.ch/it/bollettino-valanghe-e-situazione-nivologica.html#osservazioni',
      link2: 'https://pro.slf.ch/reply/public/?lang=it',
      link_text: ' Al modulo...',
    },
    poll: {  
      text1_ch: 'Il SLF è',
      text1_au: 'I servizi di valanghe del Tirolo del Alto Adige e del Trentino sono',          
      text2: " alla ricerca di appassionati di sport invernali che siano disposti a partecipare ai sondaggi sul bollettino delle valanghe per diversi inverni. ",
      text3: "Unisciti e partecipa all'ulteriore sviluppo del bollettino delle valanghe! ",
      link: 'Al sondaggio...',
      link_ch: 'https://eurosignup.avalancheresearch.ca/slf_it/',
      link_au: 'https://eurosignup.avalancheresearch.ca/euregio_it/'
    }
  },
  route: {
    title: 'Descrizione itinerario',
    diffWarning:
      "Attenzione: gli itinerari a partire da AD/3.2 (abbastanza difficile) richiedono capacità particolari ed un'adeguata esperienza alpinistica!",
    stop: 'Cima',
    start: 'Partenza',
    startElevation: 'Quota partenza',
    stopElevation: 'Quota della cima',
    travelDistance: 'Distanza di viaggio',
    elevationDifference: 'Dislivello',
    length: 'Lunghezza',
    time: 'Tempo ascesa',
    snowAmount: 'Neve',
    snowFilter: 'Altezza neve alla partenza',
    difficulty: 'Grado di difficoltà',
    type: ['Valle alla cima', 'Valle al rifugio', 'Rifugio alla cima'], 
    beta: ' Beta!',
    riskIndicator: 'Rischio valanghe',
    rising: 'aumentando!',
    elevationGain: 'm',
    all: 'tutti',
    links: {
      gps: {
        title: 'Traccia GPS',
        link: 'Archivio GPX',
      },
      gipfelbuch: {
        title: 'Info itinerario',
        link: 'Gipfelbuch',
      },
      sac: {
        title: 'Info itinerario',
        link: 'Portale escursionistico CAS',
      },      
      weather: {
        title: 'Meteo',
        link: 'MeteoBlue',
      },
      literature: {
        title: 'Letteratura',
        link: {
          panico: 'Guida di Panico'
        }
      },
      wild: {
        title: 'Siti di prot. della fauna selvatica',
        crosses: 'Sì',
        nearby: 'A xxx m di distanza',
        uri: 'https://natur-freizeit.ch/sport-invernali-e-rispetto',
      },
      transportSchedule: {
        title: 'Viaggio',
        link: 'Google Map',
      },
    },
    resetFilters: 'Resetta i filtri',
    diff: {
      manual: '(manuale)',
      automatic: '(automatico)',
    },
  },
  ratings: {
    title: 'Valutazioni tipici',
    intro: 'Cosa succederebbe se... Rischio valanghe per 10 bollettini valanghe tipici:',
    displayMore: 'Mostra di più...',
    displayLess: 'Mostra di meno...',
    bulletins: {
      0: 'Debole | tutte | tutte',
      1: 'Moderato | 2200 m | NO-N-NE',
      2: 'Moderato | 1800 m | O-N-E',
      3: 'Moderato | tutte | tutte',
      4: 'Marcato | 2200 m | NO-N-NE',
      5: 'Marcato | 1800 m | O-N-E',
      6: 'Marcato | 1600 m | tutte',
      7: 'Marcato | tutte | tutte',
      8: 'Forte | 1800 m | tutte',
      9: 'Forte | tutte | tutte',
    }
  },
  info: {
    title: 'Ulteriori informazioni',
  },
  protectedAreas: {
    title: 'Aree protette',
    intro: "Quest'area protetta è relevante per gli sport invernali.",
    info: 'Informazione',
    org: 'Risponsabile',
    states: {
      S10: "Accesso vietato al di fuori dei sentieri e dei percorsi (rosso).",
      S20: "Accesso sconsigliato al di fuori dei sentieri e dei percorsi (giallo)."
    }
  },  
  accidents: {
    title: 'Incidente di valanga',
    content: {
      0: 'Grado di pericolo:',
      1: 'Data:',
      fatality: {
        1: 'Incidente mortale',
        0: 'Incidente non mortale',
      },
      activity: {
        1: 'Incidente nella zona di escursioni',
        2: 'Incidente nella zona di fuoripista'
      },
      sourceName: {
        1: '© SLF',
        2: '© LAWIS',
        3: '© Data-Avalanche',
        4: '© AINEVA',        
      }, 
      sourceUrl: {
        1: 'https://www.slf.ch/de/lawinen/unfaelle-und-schadenlawinen.html',
        2: 'https://www.lawis.at/incident?#${id}',
        3: 'http://www.data-avalanche.org/avalanche/${id}',
        4: 'https://aineva.it/incidenti/',        
      },        
    },
    link: '',
  },  
  help: {
    cruxes: {
      title: 'Passaggi chiave',
      content: {
        0: 'Skitourenguru marca i passaggi chiave con anelli grigi.',
        1: 'Un anello: terreno valanghivo',
        2: 'Due anelli: terreno valanghivo tipico',
        3: 'Tre anelli: terreno valanghivo molto tipico',
        4: "L'assegnazione viene effettuata indipendentemente dalla situazione attuale di neve e valanghe. Solo la topografia viene considerata. Prima di ogni passaggio chiave è necessario valutare individualmente il singolo pendio.",
      },
      link: getInfoServerLink() + '/index.php/cruxes',
    },
    difficulty: {
      title: 'Grado di difficoltà',
      content: {
        0: "A seconda della regione, i gradi di difficoltà sono espressi utilizzando la 'scala SAC' o la 'scala Toponeige':",
        1: 'F/2: Facile',
        2: 'PD/3: Poco difficile',
        3: 'AD/4: Abbastanze difficile',
        4: 'D/5: Difficile',
        5: 'TD/6: Molto difficile',
        6: 'Con +/\u2013 (scala CAS) o un numero successivo (scala Toponeige) il grado di difficoltà può avere una granularità ancor maggiore.',
        7: 'Raccomandazione: scegli itinerari che sai poter gestire. Confronta il grado di difficoltà con i percorsi che conosci.',
        8: 'A seconda del percorso e della regione, i gradi di difficoltà vengono assegnati manualmente e/o automaticamente:',
        9: 'Assegnazione manuale: ',
        10: "Il livello di difficoltà si basa su un giudizio esperto. I criteri principali sono la pendenza, l'esposizione e le condizioni di spazio. La valutazione si riferisce principalmente alla parte sciistica del percorso.",
        11: 'Assegnazione automatica: ',
        12: 'Il grado di difficoltà è stato calcolato automaticamente da un algoritmo. I criteri utilizzati sono la pendenza e il pericolo di caduta. La valutazione si riferisce principalmente alla parte sciistica del percorso.',
        13: "Attenzione: Il grado di difficoltà effettivo dipende dalle condizioni attuali e dalla tua costituzione!",
      },
      link: getInfoServerLink() + '/index.php/schwierigkeit',
    },
    skiDepots: {
      title: 'Deposito sci',
      content: {
        0: 'Skitourenguru calcola una probabile posizione per il deposito sci in base alle caratteristiche del terreno (ripidità e curvatura).',
        1: 'Di solito è possibile salire al deposito sci con gli sci. Dal deposito sci fino alla cima, di solito si deve camminare a piedi.',
        2: 'Il grado di difficoltà tiene conto principalmente del tratto tra il punto di partenza e il deposito sci, ma secondariamente anche del tratto tra il deposito sci e il punto di arrivo.',
      },
      link: '',
    },
    homes: {
      title: 'Posizione',
      content: {
        0: "In quali dintorni cerchi i percorsi? Cerca la posizione utilizzando l'elenco o semplicemente digita il nome di un luogo. Puoi anche cliccare su una delle icone grigie sulla mappa.",
        1: 'Nel passo successivo è possibile limitare la distanza dal luogo selezionato.',
      },
      link: '',
    },
    travelDistance: {
      title: 'Distanza',
      content: {
        0: "Fino a quale distanza cerchi gite? La distanza è espressa in 'chilometri su strada'.",
      },
      link: '',
    },
    startElevation: {
      title: 'Quota della partenza',
      content: {
        0: 'A quale quota vuoi iniziare la gita?',
      },
      link: '',
    },
    elevationGain: {
      title: 'Dislivello',
      content: {
        0: 'Quanto dislivello vuoi fare?',
      },
      link: '',
    },
    routeType: {
      title: 'Tipo di percorso',
      content: {
        0: 'A quale tipo di percorso sei interessato?',
      },
      link: '',
    },      
    riskIndicator: {
      title: 'Rischio valanghe',
      content: {
        0: 'Cosa significano le tre categorie di rischio?',
        1: 'Rischio basso (Valori 0\u200B\u2013\u200B1): ',
        2: 'Rischio considerevole (Valori 1\u200B\u2013\u200B2): ',
        3: 'Rischio elevato (Valori 2\u200B\u2013\u200B3): ',
        4: 'Relativamente sicuro, se non sono presenti particolari segnali di pericolo. Le escursioni verdi hanno in ogni caso un rischio residuale.',
        5: 'Solo per esperti. Se vuoi pianificare una gita di questo tipo bisogna prima analizzare a fondo la situazione.',
        6: 'È meglio evitare gite scialpinistiche ad alto rischio valanghe.',
        7: 'Il valore compreso tra 0 e 3 indica la vicinanza di un itinerario al confine della classe successiva. Il rischio aumenta esponenzialmente con il valore specificato.',
      },
      link: getInfoServerLink() + '/index.php/symbolik',
    },
    ratings: {
      title: 'Valutazioni tipici',
      content: {
        0: 'Skitourenguru ha valutato questo itinerario con 10 bollettini valanghe tipici. Due esempi:',
        1: '\'Marcato | 1800 m | O-N-E\': Grado di pericolo \'Marcato\' al di sopra dei 1800 m nelle esposizioni da ovest verso nord e verso est.',
        2: '\'Moderato | tutte | tutte\': Grado di pericolo \'Moderato\' a tutte le altitudini e a tutte le esposizioni.',
      },
    },    
    status: {
      title: 'Stato della regione',
      content: {
        0: "Nei prossimi anni, Skitourenguru amplierà gradualmente la offerta degli itinerari all'intera regione alpina. Nel frattempo, le regioni interessate si trovano a diversi punti di sviluppo:",
      },
      link: '',
    },
    edition: {
      title: 'Edizione',
      content: {
        0: "Orario ideale per consultare di Skitourenguru. A quest'ora è solitamente disponibile il bollettino valanghe serale; Skitourenguru ha aggiornato le stime del rischio.",
      },
      link: getInfoServerLink() + '/index.php/ostalpen',
    },
    ascentTime: {
      title: 'Tempo di salita',
      content: {
        0: 'Il tempo di salita è calcolato a partire da una componente orizzontale e una verticale:',
        1: 'Velocità orizzontale: 4400 m/h',
        2: 'Velocità verticale: 440 m/h',
        3: "A seconda del grado di difficoltà dell'itinerario, il tempo sarà leggermente maggiore o minore. Le pause non sono incluse.",
      },
      link: '',
    },
    dangerLevel: {
      title: 'Gradi di pericolo',
      content: {
        0: 'Il grado di pericolo è quello diviso in cinque gradi della scala europea del pericolo valanghe:',
        1: 'Debole (grado 1): situazione valanghiva generalmente favorevole',
        2: 'Moderato (grado 2): situazione valanghiva per lo più favorevole',
        3: 'Marcato (grado 3): situazione valanghiva critica',
        4: 'Forte (grado 4): situazione valanghiva molto critica',
        5: 'Molto forte (grado 5): situazione valanghiva eccezionale',
        6: 'Il grado di pericolo dipende da diversi fattori, in particolare: probabilità di distacco di valanghe, distribuzione dei punti pericolosi e dimensioni delle potenziali valanghe. I gradi di pericolo valgono per una regione estesa e mai per i singoli pendii. ',
        7: "Singoli servizi di avviso valanghe pubblicano direttamente o indirettamente gradi intermedi. Questi indicano se il pericolo si trova tendenzialmente nella fascia inferiore (-), centrale (=) o superiore (+) del grado di pericolo."
      },
      link1: 'https://www.slf.ch/it/bollettino-valanghe-e-situazione-nivologica/info-bollettino-valanghe/gradi-di-pericolo.html',
      link2: getInfoServerLink() + '/index.php/imdl-de',
    },
    coreZone: {
      title: 'Punti pericolosi',
      content: {
        0: "I 'punti pericolosi' designano le zone pericolose particolarmente critiche:",
        1: "Esposizioni critiche: su una rosa dei venti, le esposizioni critiche sono contrassegnate in 'nero'.",
        2: "Quote critiche: le quote critiche sono contrassegnate con il simbolo della montagna in 'nero'.",
        3: "Studi statistici mostrano che il rischio valanghe è molto più alto all'interno dei 'punti pericolosi' che all'esterno.",
        4: "Alcuni servizi valanghe in Europa hanno iniziato ad assegnare dei 'punti pericolosi' separati per ogni 'situazione valanghiva tipica'. Skitourenguru combina questi 'punti pericolosi' in un unico 'punto pericoloso' e lo inserisce nell'algoritmo.",
      },
      link:
        'https://www.slf.ch/it/bollettino-valanghe-e-situazione-nivologica/info-bollettino-valanghe/punti-pericolosi.html',
    },
    snowAmount: {
      title: 'Altezza della neve',
      content: {
        0: "Questa linea mostra l'altezza della neve sull'itinerario:",
        1: "I primi due numeri indicano l'altezza della neve all'inizio e alla fine dell'itinerario.",
        2: "Il numero tra parentesi indica l'altezza media della neve.",
        3: "L'ultima cifra indica la percentuale dell'itinerario coperto con almeno 10 cm di neve.",
        4: "L'altezza della neve è fornita da Exolabs GmbH. Si basano sui seguenti dati:",
        5: 'Altezza della neve dalle stazioni di misura automatiche.',
        6: 'Dati satellitari (Sentinel, Landsat e Modis).',
        7: "Conoscenza dell'altezza della neve in funzione delle caratteristiche del terreno.",
        8: "Sulla mappa, l'altezza della neve (in cm) è indicata con i seguenti colori:",
      },
      link: getInfoServerLink() + '/index.php/exolabs',
    },
    snowFilter: {
      title: 'Altezza neve alla partenza',
      content: {
        0: 'Con questo cursore è possibile filtrare quei itinerari che hanno una certa altezza di neve minima alla partenza.',
      },
    },
    about: {
      title: 'About',
      subTitle: 'Informazione legale',
      content: {
        0: 'Skitourenguru è un progetto comunitario. Da un punto di vista giuridico Skitourenguru è però costituita come società a responsabilità limitata (GmbH):',
        1: 'Vuoi saperne di più su Skitourenguru?',
        2: "Prima di scrivere un'e-mail, dare un'occhiata alla pagina web:",
        3: {
          0: {
            content: 'Medias',
            link: getInfoServerLink() + '/index.php/mymedia',
          },
          1: {
            content: 'Videos di formazione',
            link: getInfoServerLink() + '/index.php/services/221-videos',
          },
          2: {
            content: 'Scienza delle valanghe',
            link: getInfoServerLink() + '/index.php/lawinenkunde',
          },          
          3: {
            content: 'Modello (Algorithmo)',
            link: getInfoServerLink() + '/index.php/model',
          },
          4: {
            content: 'Assistenza',
            link: getInfoServerLink() + '/index.php/help',
          },
          5: {
            content: 'Manuale',
            link: getInfoServerLink() + '/download/intro/Intro_IT.pdf',
          },  
          6: {
            content: 'Disegnare i percorsi',
            link: getInfoServerLink() + '/index.php/rudr-de',
          },              
        }
      },
      link: getInfoServerLink() + '/index.php/about',
    },
    wildlife: {
      title: 'Siti di protezione della fauna selvatica',
      content: {
        0: "Con il pulsante dei livello (sulla mappa in basso a sinistra) nel nodo 'Oggetti topografici' puoi far vedere i siti di protezione della fauna selvatica."
      },
      link: 'https://natur-freizeit.ch/sport-invernali-e-rispetto',
    },
    degreeOfFreedom: {
      title: 'Margine di manovra',
      content: {
        0: "Più alto è il valore, maggiore è il margine di manovra concesso all'algoritmo nel adattamento del percorso. Se il valore è 0 %, non viene effettuata alcun adattamento del percorso e viene valutato il percorso inserito.",
      }
    },    
    avalancheTerrainWeight: {
      title: 'Terreno valanghivo',
      content: {
        0: 'Più alto è il valore, più il terreno valanghivo viene preso in considerazione nel adattamento del itinerario.',
      }
    },    
    movementWeight: {
      title: 'Peso del costo del movimento',
      content: {
        0: 'Più alto è il valore, più i costi di movimento vengono presi in considerazione rispetto ai costi di transito.',
        1: 'Il costo del movimento dipende dalla distanza verticale e orizzontale.',
        2: 'I costi di transito dipendono dalle caratteristiche del terreno, ad esempio dalla pendenza.'
      }
    },  
    horizontalMovingCost: {
      title: 'Costi della distanza',
      content: {
        0: 'Più alto è il valore, più le deviazioni vengono evitate.',
      }
    },    
    elevationGainCost: {
      title: 'Costi di salita',
      content: {
        0: 'Più alto è il valore, più le salite vengono evitate.',
      }
    },    
    elevationLossCost: {
      title: 'Costi di discesa',
      content: {
        0: 'Più alto è il valore, più le discese vengono evitate.',
      }
    },   
  },   
  donation: {
    title: 'Richiesta di aiuto',
    content:
      "Ogni donazione, grande o piccola che sia, sarà la benvenuta. Il tuo denaro è investito  direttamente nel miglioramento e nello sviluppo tecnologico della piattaforma. " +
      'Skitourenguru continuerà a rimanere gratuito, privo di pubblicità, di login, tracker e lettere di notizie.',
  },
  address: {
    jurisdiction: 'Foro competente',
    manager: 'Amministratore delegato',
  },
  locationSearch: {
    title: 'Cerca un luogo sulla mappa',
    types: {
      peak: 'Cima',
      hill: 'Collina',
      saddle: 'Passo',
      alpine_hut: 'Capanna',
      wilderness_hut: 'Bivacco',
      administrative: 'Zona',
      municipality: 'Comune',
      city: 'Città ',
      town: 'Città',
      village: 'Villaggio',
      locality: 'Località',
      hamlet: 'Frazione',
      isolated_dwelling: 'Nome del campo',
      station: 'Stazione',
      bus_stop: "Fermata dell'autobus",
      valley: 'Valle',
      water: 'Lago',
      ridge: 'Dorsale',
      arete: 'Cresta',
      glacier: 'Ghiacciaio',
      river: 'Fiume',
      stream: 'Ruscello',
      district: 'Distretto',
      province: 'Provincia',
      region: 'Regione',
      shelter: 'Ricovero',
      viewpoint: 'Punto panoramico',
      camp_site: 'Campeggio',
    },
  },
  newsChannel: {
    title: 'Guru News',
  },
  message: {
    type: {
      0: 'Informazione',
      1: 'Avvertimento',
      2: 'Errore',
    },
    texts: {
      1: 'Il server ha un problema di elaborazione, riprovare più tardi!',
      2: 'Questa funzione non è ancora stata implementata!',
      3: 'Il percorso non deve essere più lungo di xxx km et più corto di yyy m!',
      4: "Il percorso è al di fuori dell'area attualmente supportata!",
      5: "La richiesta non è valida!",
      6: "La richiesta non è consentita!",
      7: 'Il file GPX non contiene un oggetto linea',
      8: 'Questo link non è valido',
      9: 'Questo itinerario presenta un alto grado di difficoltà!',
      10: 'Questo itinerario presenta un grado di difficoltà talmente elevato da essere difficilmente percorribile con gli sci!',
      11: 'Il server è attualmente occupato, riprovare più tardi!',
      12: 'Non tutti gli itinerari delle Alpi Orientali vengono visualizzati tra le 17h e le 18.15h circa, poiché alcuni servizi di avviso valanghe aggiornano il bollettino solo alle 18h.',
      13: "Ora è possibile disegnare un percorso da soli. Skitourenguru adatta il percorso e lo valuta: Nel menu in alto a destra, alla voce 'Disegnare percorsi'.",
    }
  },
  rudr: {
    title: 'Contesto di valutazione',
    description: "Disegnare un percorso sulla mappa o caricare un file GPX. Quando si disegna, fare doppio clic per terminare il percorso. Selezionare un bollettino valanghe. Infine, fare clic sul pulsante 'Avvia valutazione' (all'ingiù).",
    forecast: {
      title: 'Inserimento del bollettino valanghe',
      dangerLevel: 'Grado di pericolo',
      critEle: 'Altezza critica',
      critExpos: 'Esposizioni critiche: Cliccate sulla rosa!'
    },
    settings: {
      title: 'Impostazioni di adattamento del percorso',
      degreeOfFreedom: 'Margine di manovra',
      avalancheTerrainWeight: 'Terreno valanghivo',
      horizontalMovingCost: 'Costi della distanza',
      elevationGainCost: 'Costi di ascesa',
      elevationLossCost: 'Costi di discesa',
      movementWeight: 'Peso del costo del movimento',
    },   
    buttons: {
      cutGpx: "Tagliare il file GPX in 'vetta'",
      loadGpx: 'Carica file GPX',
      start: 'Avvia valutazione',
      reset: 'Risetta ingresso',
      respectProtAreas: 'Nota aree protette'
    },
    output: {
      name: 'Sconosciuto'
    }
  },

  /**************************************** */
  //copied from vuetify v-data-table
  dataTable: {
    itemsPerPageText: 'Righe per pagina:',
    ariaLabel: {
      sortDescending: ": ordine decrescente. Attiva per rimuovere.",
      sortAscending: ': ordine crescente. Attiva per ordinare in ordine decrescente.',
      sortNone: ': Non ordinato. Attiva per ordinare in ordine crescente.',
    },
    sortBy: 'Ordina per',
  },
  dataFooter: {
    itemsPerPageText: 'Elementi per pagina:',
    itemsPerPageAll: 'Tutti',
    nextPage: 'Pagina seguente',
    prevPage: 'Pagina precedente',
    firstPage: 'Prima pagina',
    lastPage: 'Ultima pagina',
    pageText: '{0}\u200B\u2013\u200B{1} di {2}',
  },
  noDataText: 'Nessun risultato',
  /**************************************** */
}
