import axios from 'axios'
import { getDataServerLink, getInfoServerLink, getRudrServiceLink } from '../utilities'

// Defines, if we test on the developpement or production server
// - false: We test with the production server (default)
// - true: We test with the developpement server (needed, if we want to debug the backend)
//   * Run the follwoing command on a bash of VisulCode:
//     http-server D:/Secure/GPS/Projekte/Switzerland/ART/Skitourenguru/CH/rudr/data -p 3000 --cors
//   * Link to the service:
//     https://www.skitourenguru.com/rating-view?key=1cd7458c-a324-49cb-8c92-0189f2221c99
//     http://localhost:8080/rating-view?key=1cd7458c-a324-49cb-8c92-0189f2221c99
const isRudrDev = false;

const apiClient = axios.create({
  baseURL: getDataServerLink('json'),
  withCredentials: false, // This is the default
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

const statisticsClient = axios.create({
  baseURL: getInfoServerLink(),
  withCredentials: false, // This is the default
  headers: {},
})

const nominatimClient = axios.create({
  baseURL: 'https://nominatim.openstreetmap.org',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

const overpassApiClient = axios.create({
  baseURL: 'https://overpass-api.de',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

const routeRatingApi = axios.create({
  baseURL: getRudrServiceLink (isRudrDev, true),
  withCredentials: false,
  // Server times out after 30 secs, client would time out after 60 secs
  timeout: 60000, 
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
})

const routeRatingResult = axios.create({
  baseURL: getRudrServiceLink (isRudrDev, false),
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export default {
  getJson(url, cacheTimeMinutes) {
    if (typeof cacheTimeMinutes == 'undefined' || cacheTimeMinutes == null) {
      return apiClient.get(url)
    }
    // With a timestamp that changes all 5 minutes, all caches (incl. the browser cache) is bypassed
    var secs = Math.floor(new Date().getTime() / 1000)
    var timeStamp = Math.floor(secs / (60 * cacheTimeMinutes))
    var timestampedUrl = url + '?TS=' + timeStamp.toString()
    return apiClient.get(timestampedUrl)
  },
  getRegions() {
    return this.getJson('/Alps_Regions_900913.json', 8 * 60)
  },
  getSymbols(collection) {
    return this.getJson('/' + collection + '_Symbols_900913.json', 5)
  },
  getHomes(collection) {
    return this.getJson('/' + collection + '_Homes_900913.json', 8 * 60)
  },
  getSegments(collection) {
    return this.getJson('/' + collection + '_Segments_900913.json', 5)
  },
  getSingleSegment(collection, id) {
    return this.getJson('/' + collection + '_Segments_' + id.toString().padStart(5, '0')+ '_900913.json', 5)
  },
  getSingleCruxes(collection, id) {
    return this.getJson('/' + collection + '_Cruxes_' + id.toString().padStart(5, '0')+ '_900913.json', 8 * 60)
  },  
  getSingleSkiDepot(collection, id) {
    return this.getJson('/' + collection + '_SkiDepots_' + id.toString().padStart(5, '0')+ '_900913.json', 8 * 60)
  },   
  getRoutes(collection) {
    return this.getJson('/' + collection + '_Routes_900913.json', 8 * 60)
  },
  getDistance(collection, uic) {
    return this.getJson(`/${collection}_${uic}.json`, 8 * 60)
  },
  getBulletin(bulletin) {
    return this.getJson('/' + bulletin, 5)
  },
  getWebCams(collection) {
    return this.getJson('/' + collection + '_WebCams_900913.json', 24 * 60 * 7)
  },
  getSkiDepots(collection) {
    return this.getJson('/' + collection + '_SkiDepots_900913.json', 8 * 60)
  },
  getAccidents(collection) {
    return this.getJson('/' + collection + '_Accidents_900913.json', 24 * 60 * 7)
  },
  getCruxes(collection) {
    return this.getJson('/' + collection + '_Cruxes_900913.json', 8 * 60)
  },
  getNews() {
    return this.getJson('/SkitourenguruNews.json', 5)
  },
  getSummary() {
    return this.getJson('/RiskSummary.json', 5)
  },
  getNominatim(keyword, extent, language, limit) {
    // extent: UpperLeft to LowerRight
    var viewbox = `${extent[0]},${extent[3]},${extent[2]},${extent[1]}`
    var url = `/search?format=geojson&accept-language=${language}&q=${keyword}&limit=${limit}&viewbox=${viewbox}&bounded=1`
    return nominatimClient.get(url)
  },
  // https://nominatim.org/release-docs/latest/api/Reverse/
  getReverseNominatim(lon, lat) {
    var url = `/reverse.php?&lon=${lon}&lat=${lat}&zoom=18&format=jsonv2`
    return nominatimClient.get(url)
  },
  getOverpassApi(params) {
    var url = `/api/interpreter?${params}`
    return overpassApiClient.get(url)    
  },
  postCalcRouteRating(request) {
    return routeRatingApi.post("", request)
  },

  getRetrieveRouteRating(guid, timestamp) {
    var url = `?guid=${guid}`
    if (typeof timestamp != 'undefined' && timestamp != null) {
      url += `&timestamp=${timestamp}`
    }
    return routeRatingApi.get(url)
  },

  getGpxServiceHost() {
    return getRudrServiceLink(isRudrDev, false)
  },

  getRatingRoute(url) {
    return routeRatingResult.get(url)
  },

  postStatistics(relativeUrl) {
    return statisticsClient.post(relativeUrl)
  },
}
