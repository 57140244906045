<template>
  <div>
    <app-header @displayHelp="displayHelp" @dispose="dispose"></app-header>
    <div v-resize="onResize">
      <v-container v-if="!isMobile" fluid>
        <v-row>
          <v-col class="pa-0">
            <div id="map1" class="map"></div>
            <v-btn
              class="locationSearchButton_Desktop"
              icon
              absolute
              fixed
              top
              left
              @click="displayLocationSearchDialog()"
              min-width="0px"
              height="40px"
              width="40px"
            >  
              <!-- mdi-map-search-outline, mdi-magnify -->
              <v-icon size="25x">mdi-map-search-outline</v-icon>
            </v-btn>                      
          </v-col>
          <v-col class="pa-0 info">
            <rating-info 
              :userRoute="userRoute"
              :timestamp="timestamp"
              :gpx="gpx"
              @routeRated="onRouteRated"
              @displayMessage="displayMessage"
              @displayHelp="displayHelp"
              @fileLoaded="fileLoaded"
            >
            </rating-info>
          </v-col>
        </v-row>
      </v-container>

      <v-tabs slider-size="3" v-if="isMobile" v-model="tabs" grow>
        <v-tab ripple @change="updateMapTab">{{ $vuetify.lang.t('$vuetify.tabs.map') }}</v-tab>
        <v-tab ripple>
          {{ $vuetify.lang.t('$vuetify.tabs.trackDetails') }}
        </v-tab>
        <v-tabs-items v-model="tabs" touchless>
          <v-tab-item eager>
            <div v-resize="onResize" :style="{ height: contentHeight + 'px' }" id="map2" class="map"></div>
            <v-btn
              class="locationSearchButton_Mobile"
              icon
              absolute
              fixed
              top
              left
              @click="displayLocationSearchDialog()"
              min-width="0px"
              height="40px"
              width="40px"
            >
              <!-- mdi-map-search-outline, mdi-magnify -->
              <v-icon size="25x">mdi-map-search-outline</v-icon>
            </v-btn>
          </v-tab-item>
          <v-tab-item eager>
            <rating-info 
              :userRoute="userRoute"
              :timestamp="timestamp"              
              :gpx="gpx"              
              @routeRated="onRouteRated"
              @displayMessage="displayMessage"
              @displayHelp="displayHelp"
              @fileLoaded="fileLoaded"
            >
            </rating-info>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>

    <disclaimer-dialog ref="disclaimer" style="z-index=200"></disclaimer-dialog>
    <webcam-dialog ref="webcam" style="z-index=200"></webcam-dialog>
    <!-- <accident-dialog ref="accident" style="z-index=200"></accident-dialog> -->
    <help-dialog ref="help" style="z-index=200"></help-dialog>
    <location-search-dialog ref="locationSearchDialog" style="z-index: 200;"></location-search-dialog>
    <overlay-dialog ref="overlay" style="z-index=200"></overlay-dialog>
    <message-dialog ref="message" style="z-index=200"></message-dialog>
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader.vue'
import RatingInfo from '../components/RatingInfo.vue'
import DisclaimerDialog from '../components/DisclaimerDialog.vue'
import WebcamDialog from '../components/WebcamDialog.vue'
import HelpDialog from '../components/HelpDialog.vue'
import LocationSearchDialog from '../components/LocationSearchDialog.vue'
import OverlayDialog from '../components/OverlayDialog.vue'
import MessageDialog from '../components/MessageDialog.vue'
import { olMapMixin } from '../mixins/olMapMixin'
import { resizeMixin } from '../mixins/resizeMixin'
import { refreshMixin } from '../mixins/refreshMixin'
import NProgress from 'nprogress'
import store from '@/store/store'
import { utilityMixin } from '../mixins/utilityMixin'
import { log, addExtentMargin } from '../utilities'
import DataService from '@/services/DataService'
import { transform } from 'ol/proj'
import { defaultExtents_4326 } from '../variables'
import { register } from 'ol/proj/proj4'
import proj4 from 'proj4'

export default {
  name: 'rating-view',
  mixins: [olMapMixin, resizeMixin, utilityMixin, refreshMixin],
  components: {
    AppHeader,
    RatingInfo,
    DisclaimerDialog,
    HelpDialog,
    OverlayDialog,
    WebcamDialog,
    MessageDialog,
    LocationSearchDialog,    
    //AccidentDialog
  },
  data: () => ({
    tabs: null,
    userRoute: null,
    timestamp: 0,
    status: 1,
    agreed: false,
    warningEnabled: true,
    extentMargin: 10,
    gpx: null
  }),

  methods: {
    rejectDisclaimer() {
      this.dispose()
      this.$router.push({
        name: 'regions-view',
      })
    },
    acceptDisclaimer() {
      this.agreed = true
    },
    onWebcamClicked(webcamId) {
      var feature = store.state[this.area].webCams.features.find((item) => item.properties.id === webcamId)
      this.$refs.webcam.open(webcamId, feature.properties.name, feature.geometry.coordinates[2])
    },
    displayHelp(tag) {
      // eslint-disable-next-line no-console
      console.log('RatingView: Display help')
      this.$refs.help.open(tag)
    },
    dispose() {  
      this.disposeMap()   
    },    
    displayLocationSearchDialog() {
      const extent = defaultExtents_4326['ap']
      this.$refs.locationSearchDialog.open(extent, this.onLocationSearchHit)
    },

    // eslint-disable-next-line no-unused-vars
    onLocationSearchHit(coordinates) {
      // eslint-disable-next-line no-console
      const googleCoordinates = transform(coordinates, 'EPSG:4326', 'EPSG:3857')
      this.simpleZoomTo(googleCoordinates, 14)
    },    
    displayMessage(type, message) {
      // eslint-disable-next-line no-console
      console.log('RatingView: Display help')
      this.$refs.message.open(type, message)
    },    
    onLayerSwitcher() {
      // log('onLayerSwitcher(): Clicked!')
      this.$refs.overlay.open(this.map.getLayers())
    },
    onClick(e) {
      var features = e.target.getFeatures()
      var length = features.getLength()
      if (length == 0) return
      var feature = features.item(0)
      var type = feature.get('ltype')
      if (type == this.layerType.SYMBOLCLUSTER || type == this.layerType.ROUTES || type == this.layerType.SEGMENTS) {
        return
      }
      if (type == this.layerType.WEBCAMS) {
        var webcamId = feature.get('id')
        var geometry = feature.get('geometry')
        var coordinates = geometry.getCoordinates()
        var name = feature.get('name')
        this.$refs.webcam.open(webcamId, name, coordinates[2])
        return
      }
      if (type == this.layerType.CRUXES) {
        this.displayHelp('cruxes')
        return
      }
      if (type == this.layerType.SKIDEPOTS) {
        this.displayHelp('skiDepots')
        return
      }
      if (type == this.layerType.ACCIDENTS) {        
        this.$refs.accident.open(feature)
        return
      }      
    },
    // eslint-disable-next-line no-unused-vars
    onMouseOver(e) {

    },
    disableWarning() {
      this.warningEnabled = false
      this.displayHelp('status')
    },
    segmentDataReceived(segments) {
      log('RatingView.segmentDataReceived(): Received response.data')
      var segmentsVectorLayer = this.createVectorLayer(segments, this.layerType.SEGMENTS)
      this.removeVectorLayer(this.layerType.SEGMENTS)
      this.addVectorLayer(segmentsVectorLayer, this.layerType.ROUTES)

      var features = segmentsVectorLayer.getSource().getFeatures()
      var geometry = features[0].get('geometry')
      var extent = geometry.getExtent()
      extent = addExtentMargin(extent, this.extentMargin)
      this.zoomTo(extent, 8, 16)
    },

    // eslint-disable-next-line no-unused-vars
    routeDataReceived(routes) {
      log('RatingView.routeDataReceived(): Received response.data')
      var routesVectorLayer = this.createVectorLayer(routes, this.layerType.ROUTES)
      var features = routesVectorLayer.getSource().getFeatures()

      // Get existing vempty vector layer
      var source = this.getLayer(this.layerType.ROUTES).getSource()
      source.addFeature(features[0])

      this.userRoute = features[0].get('geometry')
    },
    onDrawEnd(event) {
      log('onDrawEnd')
      this.userRoute = event.feature.getGeometry();
    },
    // eslint-disable-next-line no-unused-vars
    onDrawStart(event) {
      log('onDrawStart')
      this.userRoute = null;  
      this.removeVectorLayer(this.layerType.SEGMENTS)
      this.removeImageLayer(1072)   
      this.updateHeader("", 1, null)
    },

    // eslint-disable-next-line no-unused-vars
    onRouteRated(response, isNew) {
      if (response == null) {
        this.removeVectorLayer(this.layerType.SEGMENTS)
        this.removeImageLayer(1072)   
        this.updateHeader("", 1, null)
        return
      }

      if (typeof response.gpx != 'undefined' && response.gpx != null) {
        this.gpx = response.gpx
      }

      this.updateHeader("", 1, response.correctedRouteInfo.risk)
      var that = this
      DataService.getRatingRoute(response.automaticRouteVector).then((response) => {
        that.segmentDataReceived(response.data)
      })

      if (response.corridorRaster != null) {
        var url = DataService.getGpxServiceHost() + response.corridorRaster
        var attributions = '© <a href="https://info.skitourenguru.ch/index.php/corridors">Corridors</a>'
        this.addImageLayer(1072, url, response.rasterEpsg, response.corridorExtent, attributions)
      }
 
      if (isNew) {
        // Make sure the url changes

        let index = response.timestamp.indexOf('T')
        let timestamp = response.timestamp.substr(0, index)
        var newUrl = `rating-view?guid=${response.guid}&timestamp=${timestamp}`
        let key = this.$route.query.key
        if (typeof key != 'undefined' && key != null) {
          newUrl += `&key=${key}`
        }
        history.replaceState(null, '', newUrl)
      } else {
        // Get the manual route
        that = this
        DataService.getRatingRoute(response.manualRouteVector).then((response) => {
          that.routeDataReceived(response.data)
        })
      }
    },
    fileLoaded(file, cutGpx) {
      log("fileLoaded(): " + file + ' , ' +  cutGpx)

      var reader = new FileReader();
      reader.readAsText(file, "UTF-8");

      var that = this
      reader.onload = function (evt) {
        let data = evt.target.result
        var feature = that.getFeatureFromGpx(data)
        if (feature == null) {
          that.displayMessage(1, that.$vuetify.lang.t(`$vuetify.message.texts.7`))
          return
        }
        var geometry = that.getLineStringGeometry(feature, cutGpx)
        if (geometry == null) {
          that.displayMessage(1, that.$vuetify.lang.t(`$vuetify.message.texts.7`))
          return
        }
        feature.setGeometry(geometry)
        that.removeVectorLayer(that.layerType.SEGMENTS)
        that.updateHeader("", 1, null)
        var source = that.getLayer(that.layerType.ROUTES).getSource()
        source.clear()
        source.addFeature(feature)
        that.userRoute = geometry
        var extent = geometry.getExtent()
        extent = addExtentMargin(extent, that.extentMargin)
        that.zoomTo(extent, 8, 16)

        that.timestamp = that.parseTimestamp(data)
        log(`fileLoaded(): ${that.timestamp}`)
      }
    }, 
    parseTimestamp(data) {
      var timestamp = 0
      try { 
        var parser = new DOMParser();
        var xml = parser.parseFromString(data,"application/xml")
        //var node = xml.evaluate('string(/gpx/metadata/time)', xml, function(prefix) { if (prefix === 'myns') { return 'http://url.com/services'; } else { return null; }}, XPathResult.STRING_TYPE, null)
        var metadata = xml.querySelector('metadata')
        if (metadata == null) return 0
        var time = metadata.querySelector('time')
        if (time == null) return 0
        timestamp = Date.parse(time.innerHTML)
        if (isNaN(timestamp)) return 0
      }
      catch(err)  {
        log('parseTimestamp(): Exception when parsing timestamp')
      }
      return timestamp
    }, 
    enableLayer(id, opacity) {
      let title = this.$vuetify.lang.t(`$vuetify.rasterLayers.${id}`)
      let layer = this.getLayerByTitle(title)
      if (typeof layer != 'undefined' && layer != null) {
        layer.set('visible', true)
        layer.set('opacity', opacity)
      }
    }, 
  },
  created() {
    log('RatingView.created(): Enter')
    proj4.defs('EPSG:21781', '+proj=somerc +lat_0=46.9524055555556 +lon_0=7.43958333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs +type=crs')
    proj4.defs('EPSG:3035', '+proj=laea +lat_0=52 +lon_0=10 +x_0=4321000 +y_0=3210000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs')
    // proj4.defs('EPSG:3857', '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs')
		// proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
		// proj4.defs('EPSG:25833', '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
		// proj4.defs('EPSG:900913', '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs');	
		// proj4.defs('EPSG:62632', '+proj=utm +zone=32 +datum=WGS84 +units=m +no_defs');
		// proj4.defs('EPSG:62633', '+proj=utm +zone=33 +datum=WGS84 +units=m +no_defs');    
    
    register(proj4)    

    const manualVector = this.createEmptyVectorLayer(this.layerType.ROUTES)

    var rasterBaseLayerList = this.createRasterLayers('ap', this.trackViewBaseLayers)
    var rasterOverlayLayerList = this.createRasterLayers('ap', this.ratingViewOverlayLayers)

    var centerX = 1015500
    var centerY = 5871000
    this.createMap(
      centerX,
      centerY,
      14,
      [manualVector],
      rasterBaseLayerList,
      rasterOverlayLayerList,
      this.onLayerSwitcher
    )
    //this.registerSingleClickHandler(this.onClick)
    //this.registerMouseOverHandler(this.onMouseOver)

    this.registerDrawHandler(this.onDrawStart, this.onDrawEnd, manualVector)

    log('RatingView.created(): Map created')

    this.enableLayer(1052, 0.5)
    this.enableLayer(1061, 0.8)

    log('RatingView.created(): Exit')
  },
  mounted() {
    log('RatingView.mounted(): Enter')
    this.updateHeader("", this.status, null)
    this.agreed = this.$refs.disclaimer.open('ch', 1, true, this.rejectDisclaimer, this.acceptDisclaimer)
    this.startRefresher()
    log('RatingView.mounted(): Exit')
  },
  unmounted() {
  },
  beforeRouteEnter(routeTo, routeFrom, next) {
    // inline async function to wait for async dispatch calls
    // eslint-disable-next-line no-unused-vars
    const getDataAsync = async function (area) {

      await store.dispatch('news/fetchNews')
    }

    NProgress.start()
    const area = routeTo.query.area ? routeTo.query.area : 'ch'
    getDataAsync(area).then(() => {
      NProgress.done()
      next()
    })
  },  

}
</script>

<style scoped>
.map {
  height: 100%;
}

.info {
  max-width: 500px;
}

.locationSearchButton_Desktop {
  background-color: var(--navigationColor);
  color: white !important;
  border-radius: 50%;
  margin-top: 35px !important;
}

.locationSearchButton_Mobile {
  background-color: var(--navigationColor);
  color: white !important;
  border-radius: 50%;
  margin-top: 75px !important;
}
</style>
