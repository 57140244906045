import { getInfoServerLink } from '../utilities'

export default {
  yes: 'Ja',
  no: 'Nein',
  ok: 'OK',
  cancel: 'Abbrechen',
  information: 'Information',
  home: 'Standort',
  header: 'Skitourenguru',
  news: 'News',
  attention: 'Achtung',
  filterTable: {
    search: 'Nach Gipfelname suchen',
    filter: 'Routen filtern',
    to: 'bis',
    of: 'von',
    results: 'Resultat:',
    routes: 'Routen',
  },
  regions: {
    ch: 'Schweiz',
    au: 'Ostalpen',
    fr: 'Frankreich',
    ie: 'Nordostitalien',
    iw: 'Nordwestitalien',
  },
  tabs: {
    map: 'Karte',
    regionsList: 'Einführung',
    trackDetails: 'Routendetails',
    trackSearch: 'Routensuche',
  },
  menu: {
    info: 'Hintergrund',
    language: 'de en fr it',
    manual: 'Handbuch',
    video: 'Schulungsvideos',
    news: 'News',
    media: 'Medien',
    prevention: 'Unfallprävention (BFU)',
    about: 'About',
    drawRoute: 'Route zeichnen'
  },
  intro: {
    summer: {
      title: 'Sommerbetrieb',
      content:
        'Gewöhnlich stehen zwischen November und Mai Lawinenbulletins zur Verfügung. Für Regionen ohne Lawinenbulletins wird eine Demoversion (Off) angezeigt. Achtung: Die Resultate in solchen Regionen sind ungültig!',
    },
    guru: {
      title: 'Was ist Skitourenguru',
      content:
        'Skitourenguru unterstützt dich bei der Auswahl und Planung einer geeigneten Skitour mit tiefem Lawinenrisiko. Zu diesem Zweck weist Skitourenguru tausenden Skitouren des Alpenraumes täglich ein Lawinenrisiko zu:',
      table: {
        header: {
          symbol: 'Symbol',
          values: 'Werte',
          risks: 'Lawinenrisiko',
        },
        content: {
          values: {
            0: '0\u200B\u2013\u200B1',
            1: '1\u200B\u2013\u200B2',
            2: '2\u200B\u2013\u200B3',
          },
          risks: {
            0: 'Tiefes Lawinenrisiko',
            1: 'Erhöhtes Lawinenrisiko',
            2: 'Hohes Lawinenrisiko',
          },
          riskQualifiers: {
            0: 'tief',
            1: 'erhöht',
            2: 'hoch',
          },
        },
      },
    },
    cruxes: {
      content: 'Zusätzlich markiert Skitourenguru statische Schlüsselstellen mit grauen Ringen:',
      table: {
        header: {
          symbol: 'Symbol',
          values: 'Klasse',
          risks: 'Bedeutung',
        },
        content: {
          values: {
            0: '1',
            1: '2',
            2: '3',
          },
          classes: {
            0: 'Lawinengelände',
            1: 'Typisches Lawinengelände',
            2: 'Sehr typisches Lawinengelände',
          },
        },
      },
    },
    limits: {
      1: 'Vor Ort und im Einzelhang werden in der Regel Informationen zugänglich, die Skitourenguru nicht zur Verfügung stehen. ',   
      2: 'Die auf Skitourenguru dargestellten Informationen unterliegen zudem Unsicherheiten (siehe ',
      3: {
        text: 'Handbuch',
        link: getInfoServerLink() + '/download/intro/Intro_DE.pdf',
      },
      4: '). ',
      5: 'Skitourenguru darf daher nicht das einzige Kriterium zur Begehung eines Einzelhanges sein.'
    },
    choose: {
      title: 'Wähle eine Region',
      table: {
        header: {
          region: 'Region',
          status: 'Status',
          edition: 'Ausgabe',
          valid: 'Gültig',
        },
      },
    },
    supported: {
      title: 'Partner',
      content: 'Skitourenguru wird unter anderem durch die nachfolgenden Partner unterstützt.',
      partners: {
        bfu: {
          name: 'BFU',
          link: 'https://www.bfu.ch/de/ratgeber/skitouren',
        },
        sac: {
          name: 'SAC',
          link: 'https://www.sac-cas.ch/de/ausbildung-und-sicherheit/sicher-unterwegs/sicher-unterwegs-auf-skitouren/'
        },
        colltex: {
          name: 'colltex',
          link: 'https://www.colltex.ch/'
        }
      }
    },
  },
  difficultyScales: {
    0: '/',
    1: 'L/1.2',
    2: 'L+/1.3',
    3: 'WS\u2212/2.1',
    4: 'WS/2.2',
    5: 'WS+/2.3',
    6: 'ZS\u2212/3.1',
    7: 'ZS/3.2',
    8: 'ZS+/3.3',
    9: 'S\u2212/4.1',
    10: 'S/4.2',
    11: 'S+/4.3',
    12: 'SS\u2212/5.1',
    13: 'SS/5.2',
    14: 'SS+/5.3',
    15: 'AS\u2212/5.3',
    16: 'AS/5.4',
    17: 'AS+/5.4',
    18: 'EX/5.5',
  },
  langDialog: {
    chooseLang: 'Wähle eine Sprache',
  },
  disclaimer: {
    title: 'Nutzungsbedingungen',
    text: {
      0: 'Dieser Prototyp passt eine von dir gewählte Route an und schätzt anschliessend deren Lawinenrisiko. Beachte, dass es sich um einen Prototypen handelt und die Resultate im Grundsatz unsicher sind!',
      1:
        'Skitourenguru versteht sich als zusätzliches Hilfsmittel für die selbständige und eigenverantwortliche Auswahl und Planung einer Skitour und die dafür ' + 
        'vorzunehmende Bewertung des Lawinenrisikos. Dazu werden tausenden Skitouren des Alpenraums täglich automatisiert und auf der Grundlage der aktuellen, lokalen ',
      2: {
        text: 'Lawinenbulletins',
        link: getInfoServerLink() + '/index.php/ostalpen',
      },
      3: ' und der amtlichen ',
      4: {
        text: 'Geländedaten',
        link: getInfoServerLink() + '/index.php/athm-de',
      },
      5: ', in Anwendung programmierter Ausführungsregeln, Lawinenrisiken zugewiesen: Grün (tiefes Risiko), orange (erhöhtes Risiko) oder rot (hohes Risiko). ',
      6: 'Skitourenguru GmbH übernimmt keine Garantie und somit auch keine Haftung für die Richtigkeit der dargestellten Informationen und Bewertungen. ',
      7: 'Diese können fehlerhaft sein (siehe ',
      8: {
        text: 'Handbuch',
        link: getInfoServerLink() + '/download/intro/Intro_DE.pdf',
      },
      9: '). Daher wird ausdrücklich darauf hingewiesen, dass Skitourenguru lediglich als ergänzende Informationsquelle zur eigenverantwortlichen Planung einer Skitour herangezogen werden kann. ',
      10: 'Durch die Nutzung von Skitourenguru werden anonymisierte Daten erhoben. Aus der ',
      11: {
        text: 'Datenschutzerklärung',
        link: getInfoServerLink() + '/privacy-de',   
      },
      12: ' erfährst du, welche Daten von uns erhoben werden und welche Zwecke wir damit verfolgen.',
      13: 'Bist du mit diesen Nutzungsbedingungen einverstanden? ',
    },
  },
  editionDescription: 'Ideale Uhrzeit für die Konsulaltion von Skitourenguru',
  regionStatus: {
    0: {
      short: '-',
      medium: '-',
      long: '',
      validity: '',
    },
    1: {
      short: 'On',
      medium: 'Vollbetrieb',
      long: 'Der Dienst für die Region ist aktiv.',
      validity: '',
    },
    2: {
      short: 'Test',
      medium: 'Testbetrieb',
      long:
        'Der Dienst ist als Testversion aktiv. Solche Testversionen werden für Regionen mit noch wenigen Erfahrungen aufgeschaltet. Die angezeigten Informationen weisen daher eine geringere Zuverlässigkeit auf als in vollständig aktivierten Regionen.',
      validity: 'Resultate unsicher',
    },
    3: {
      short: 'Wait',
      medium: 'Am rechnen',
      long:
        'Zur Zeit stehen für diese Region keine gültigen Daten zur Verfügung, denn Skitourenguru ist gerade im Begriffe diese zu berechnen. Versuche es 15 Minuten später mit einem Refresh (F5).',
      validity: 'Resultate abgelaufen',
    },
    4: {
      short: 'Off',
      medium: 'Demobetrieb',
      long:
        'Es steht kein gültiges Lawinenbulletin zur Verfügung. Daher befindet sich die Region im Demobetrieb. Die angezeigten Resultate sind ungültig.',
      validity: 'Resultate ungültig',
    },
    5: {
      short: 'Project',
      medium: 'In Planung',
      long: 'Der Dienst für die Region ist erst in Planung. Alle angezeigten Resultate sind ungültig.',
      validity: 'Resultate ungültig',
    },
  },
  vectorLayers: {
    1: 'Regionen',
    2: 'Lawinenrisiko',
    3: 'Routen',
    4: 'Standorte',
    5: 'Risikoprofile',
    6: 'Schlüsselstellen',
    7: 'Webcams',
    8: 'Lawinenbulletin',
    9: 'Lawinenunfälle',
    10: 'Aktueller Standort',
    11: 'Ski-Depot',
    12: 'Schutzgebiete'
  },
  layerGroups: {
    title: 'Layer Switcher',
    base: 'Basiskarten',
    overlay: 'Overlaykarten',
    objects: 'Kartenobjekte',
  },
  rasterLayers: {
    1009: 'Test',
    1010: 'BaseMap TopoMap (Österreich)',
    1011: 'BEV/OTM (Ostalpen)',
    1012: 'BKG TopoMap (Deutschland)',
    1013: 'Bergfex TopoMap (Österreich)',
    1014: 'Kompass TopoMap (Alpen)',
    1015: 'Google Hybrid',
    1016: 'ESRI TopoMap',
    1017: 'OpenStreetMap',
    1018: 'OpenTopoMap (Welt)',
    1019: 'MAP1',
    1020: 'OpenCycleMap',
    1021: 'OSMAnd',
    1022: 'OSM Humanitarian',
    1023: 'Wikimedia',
    1024: 'MountainMap',
    1025: 'IGN TopoMap (Frankreich)',
    1026: 'IGN TopoMap (Frankreich)',
    1027: 'IGN Slope (Frankreich)',
    1028: 'Swisstopo TopoMap',
    1029: 'Hangneigungsklassen',
    1030: 'TMC TopoMap (Schweiz)',
    1031: 'OpenTopoMap (Alpen)',
    1032: 'IGN TopoMap (Frankreich)',
    1033: 'IGM TopoMap (NE-Italien)',
    1034: 'IGM TopoMap (NW-Italien)',
    1045: 'Korridore (Schweiz)',
    1046: 'SkiMap (Schweiz)',
    1047: 'Exolabs Schneemaske',
    1048: 'Exolabs Schneehöhe',
    1049: '4UMaps',
    1050: 'Wildtierschutzgebiete',
    1051: 'Wildruhezonen',
    1052: 'Skirouten (SAC)',
    1053: 'Haltestellen',
    1054: 'Swissimage',
    1055: 'Swisstopo 1:10K',
    1056: 'Swisstopo TopoMap (x2)',
    1057: 'Lawinengelände (ATH)',
    1058: 'Lawinengelände (CAT)',
    1059: 'Absturzrisiko',
    1060: 'Abgeschiedenheit',
    1061: 'Schutzgebiete',
    1062: 'Lawinengelände (ATHM)',
    1063: 'Hangneigungsklassen', 
    1064: 'Absturzrisiko',
    1065: 'IGN Orthofoto',    
    1066: 'Austria Orthofoto',
    1067: 'OTM/Swisstopo/BEV/LDBV',
    1068: 'Exolabs Schneedifferenz (-48h)',
    1069: 'Exolabs Schneedifferenz (+48h)',   
    1070: 'Skipisten',
    1071: 'Wanderwege',
    1072: 'Korridor',
    1073: 'Routen Skitourenguru'
  },
  bulletin: {
    title: 'Lawinenbulletin',
    dangerLevels: {
      1: 'Gering',
      2: 'Mässig',
      3: 'Erheblich',
      4: 'Gross',
      5: 'Sehr Gross',
    },
    dayCourseWarning: 'Achtung: Anstieg der Gefahrenstufe im Tagesverlauf. Die Bewertungen sind nur gültig für die frühen Morgenstunden!',
    invalidWarning: 'Achtung: Dieses Lawinenbulletin ist nicht aktuell. Damit sind auch die Bewertungen ungültig!',
    skitourenguruWarning: 'Nachfolgend wird die Gefahrenstufe und die Kernzone so angezeigt, wie sie in den Algorithmus von Skitourenguru eingespiesen werden. Die Gefahrenstufe und die Kernzone werden aus strukturell unterschiedlichen Lawinenbulletin ausgelesen. Dies kann in Sonderfällen zu Unterschieden vom originalen Lawinenbulletin führen.',
    originalWarning: 'Nachfolgend wird der möglichst vollständige Inhalt des originalen Lawinenbulletins in harmonisierter Form dargestellt. Da sich die Lawinenbulletins im Alpenraum strukturell unterscheiden, kann dies in Sonderfällen zu Abweichungen vom originalen Lawinenbulletin führen.',
    coreZoneInfo: 'Skitourenguru geht davon aus, dass die Gefahrenstufe ausserhalb der Kernzone um eins tiefer liegt.',
    dangerLevelWarning3: "Achtung: Diese Route liegt in einem Gebiet mit der Gefahrenstufe 'Erheblich'. Das Modell von Skitourenguru fokussiert auf durch Personen ausgelöste Lawinen (95% der tödlichen Unfälle). Bei 'Erheblich' sind aber auch Spontanlawinen möglich. Beziehe diese spätestens ab Gefahrenstufe 'Erheblich (3+)' mit ein in deine Planung!",
    dangerLevelWarning4: "Achtung: Diese Route liegt in einem Gebiet mit der Gefahrenstufe 'Gross'. Das Modell von Skitourenguru fokussiert auf durch Personen ausgelöste Lawinen (95% der tödlichen Unfälle). Bei 'Gross' sind aber auch Spontanlawinen wahrscheinlich, beziehe diese mit ein in deine Planung!",
    table: {
      edition: 'Ausgabe',
      validTill: 'Gültig bis',
      dangerLevel: 'Gefahrenstufe',
    },
    periode: {
      day_periode: 'Ganzer Tag',
      cold_periode: 'Morgen',
      hot_periode: 'Nachmittag'
    },
    moisture: {
      overview: 'Überblick',
      dry: 'Trockene Lawinen',
      wet: 'Nasse Lawinen'
    },
    panelHeaders: {
      texts: 'Textuelle Beschreibung',
      feedback: 'Feedback',
      algorithm: 'Grundlage für Skitourenguru'
    },
    problems: {
      NEW_SNOW: "Neuschnee",
      WIND_SLAB: "Triebschnee",
      WET_SNOW: "Nasse Lawinen",
      PERSISTENT_WEAK_LAYERS: "Altschnee",
      GLIDING_SNOW: "Gleitschnee",
      FAVOURABLE_SITUATION: "Günstige Situation",
      NO_DISTICT_AVALANCHE_PROBLEM: "Kein ausgeprägtes Lawinenproblem" 
    },
    problemLink: 'https://www.slf.ch/de/lawinenbulletin-und-schneesituation/wissen-zum-lawinenbulletin/typische-lawinenprobleme/',
    publisher: {
      ch: {
        title: '©\u2006SLF',
        link: 'https://whiterisk.ch/de/conditions',
      },
      au: {
        title: 'je nach Region',
        link: getInfoServerLink() + '/index.php/ostalpen',
      },
      fr: {
        title: '©\u2006Meteo France',
        link: 'http://www.meteofrance.com/previsions-meteo-montagne/bulletin-avalanches',
      },
      ie: {
        title: '©\u2006AINEVA',
        link: 'https://bollettini.aineva.it/bulletin/latest',
      },
      iw: {
        title: '©\u2006AINEVA',
        link: 'https://bollettini.aineva.it/bulletin/latest',
      },
      title: 'Link zum Original',
    },
    feedback: {
      text: 'Melde Deine Beobachtung dem SLF und hilf so die Qualtität des Lawinenbulletins zu verbessern: ',
      link1: 'https://www.slf.ch/de/lawinenbulletin-und-schneesituation.html#beobachtungen',
      link2: 'https://pro.slf.ch/reply/public/?lang=de',
      link_text: 'Zum Formular...',
    },
    poll: {
      text1_ch: 'Das SLF sucht',
      text1_au: 'Die Lawinenwarndienste aus Tirol, Südtirol und dem Trentino suchen',
      text2: ' Schneesportler, die bereit sind, über mehrere Winter an Umfragen zum Lawinenbulletin teilzunehmen. ',
      text3: 'Mach mit und beteilige Dich an der Weiterentwicklung des Lawinenbulletins! ',
      link: 'Zur Umfrage...',
      link_ch: 'https://eurosignup.avalancheresearch.ca/slf_de/',
      link_au: 'https://eurosignup.avalancheresearch.ca/euregio_de/'
    }
  },
  route: {
    title: 'Routenbeschreibung',
    diffWarning:
      'Achtung: Routen ab ZS/3.2 (Ziemlich Schwierig) stellen hohe Anforderungen an die Ausbildung und an das Können der Schneesportler!',
    stop: 'Gipfel',
    start: 'Start',
    startElevation: 'Starthöhe',
    stopElevation: 'Gipfelhöhe',
    travelDistance: 'Anreisedistanz vom Standort',
    elevationDifference: 'Höhendifferenz',
    length: 'Routenlänge',
    time: 'Aufstiegszeit',
    snowAmount: 'Schnee',
    snowFilter: 'Schneehöhe am Start',
    difficulty: 'Schwierigkeitsgrad',
    type: ['Tal zum Gipfel', 'Tal zur Hütte', 'Hütte zum Gipfel'],
    beta: ' Beta!',
    riskIndicator: 'Lawinenrisiko',
    rising: 'ansteigend!',
    elevationGain: 'hm',
    all: 'alle',
    links: {
      gps: {
        title: 'GPS-Track',
        link: 'GPX-File',
      },
      gipfelbuch: {
        title: 'Routeninfo',
        link: 'Gipfelbuch',
      },
      sac: {
        title: 'Routeninfo',
        link: 'SAC Tourenportal',
      },      
      weather: {
        title: 'Wetter',
        link: 'MeteoBlue',
      },
      literature: {
        title: 'Literatur',
        link: {
          panico: 'Panico-Skitourenführer'
        }
      },
      wild: {
        title: 'Wildtierschutzgebiete',
        crosses: 'Ja',
        nearby: 'In xxx m Distanz',
        uri: 'https://natur-freizeit.ch/schneesport-mit-ruecksicht',
      },
      transportSchedule: {
        title: 'Anreise',
        link: 'Google Map',
      },
    },
    resetFilters: 'Filter zurücksetzen',
    diff: {
      manual: '(manuell)',
      automatic: '(automatisch)',
    },
  },
  ratings: {
    title: 'Typische Bewertungen',
    intro: 'Was wäre wenn... Lawinenrisiko für 10 typische Lawinenbulletins:',    
    displayMore: 'Mehr anzeigen...',
    displayLess: 'Weniger anzeigen...',
    bulletins: {
      0: 'Gering | alle | alle',
      1: 'Mässig | 2200 m | NW-N-NE',
      2: 'Mässig | 1800 m | W-N-E',
      3: 'Mässig | alle | alle',
      4: 'Erheblich | 2200 m | NW-N-NE',
      5: 'Erheblich | 1800 m | W-N-E',
      6: 'Erheblich | 1600 m | alle',
      7: 'Erheblich | alle | alle',
      8: 'Gross | 1800 m | alle',
      9: 'Gross | alle | alle',
    }
  },
  info: {
    title: 'Weiterführende Informationen',
  },
  protectedAreas: {
    title: 'Schutzgebiet',
    intro: 'Dieses Schutzgebiet ist relevant für den Wintersport.',
    info: 'Information',
    org: 'Verantwortlich',
    states: {
      S10: 'Betreten ausserhalb erlaubter Wege und Routen verboten (rot).',
      S20: 'Betreten ausserhalb erlaubter Wege und Routen nicht empfohlen (gelb).'
    }
  },
  accidents: {
    title: 'Lawinenunfall',
    content: {
      0: 'Gefahrenstufe:',
      1: 'Datum:',
      fatality: {
        1: 'Unfall mit Todesfolge',
        0: 'Unfall ohne Todesfolge',
      },
      activity: {
        1: 'Unfall im Tourengelände',
        2: 'Unfall im Variantengelände'
      },
      sourceName: {
        1: '© SLF',
        2: '© LAWIS',
        3: '© Data-Avalanche',
        4: '© AINEVA',        
      }, 
      sourceUrl: {
        1: 'https://www.slf.ch/de/lawinen/unfaelle-und-schadenlawinen.html',
        2: 'https://www.lawis.at/incident?#${id}',
        3: 'http://www.data-avalanche.org/avalanche/${id}',
        4: 'https://aineva.it/incidenti/',        
      },              
    },
    link: '',
  },  
  help: {
    cruxes: {
      title: 'Schlüsselstellen',
      content: {
        0: 'Schlüsselstellen auf Skitouren markiert der Skitourenguru mit grauen Ringen.',
        1: 'Ein grauer Ring: Lawinengelände',
        2: 'Zwei graue Ringe: Typisches Lawinengelände',
        3: 'Drei graue Ringe: Sehr typisches Lawinengelände',
        4: 'Die Markierung erfolgt unabhängig von der aktuellen Schnee- und Lawinensituation ausschliesslich auf Grund des Geländes. Während der Skitour solltest du vor jeder Schlüsselstelle eine Einzelhangbeurteilung vornehmen.',
      },
      link: getInfoServerLink() + '/index.php/cruxes',
    },
    difficulty: {
      title: 'Schwierigkeitsgrade',
      content: {
        0: "Schwierigkeitsgrade werden je nach Region mit der 'SAC-Skala' oder der 'Toponeige-Skala' ausgedrückt:",
        1: 'L/2: Leicht',
        2: 'WS/3: Wenig Schwierig',
        3: 'ZS/4: Ziemlich schwierig',
        4: 'S/5: Schwierig',
        5: 'SS/6: Sehr schwierig',
        6: 'Mit +/\u2013 (SAC-Skala) bzw. einer nachgesetzten Zahl (Toponeige) kann der Schwierigkeitsgrad jeweils noch feiner unterteilt werden.',
        7: 'Empfehlung: Wähle Skitouren aus, die du bewältigen kannst. Vergleiche den Schwierigkeitsgrad mit dir bekannten Routen.',
        8: 'Je nach Route und Region werden Schwierigkeitsgrade manuell und/oder automatisch zugewiesen:',
        9: 'Manuelle Zuweisung: ',
        10: 'Der Schwierigkeitsgrad basiert auf einem Expertenurteil. Als Hauptkriterien dienen die Steilheit, die Ausgesetztheit und die Platzverhältnisse. Die Bewertung bezieht sich vorwiegend auf den skifahrerischen Teil der Route.',
        11: 'Automatische Zuweisung: ',
        12: 'Der Schwierigkeitsgrad wurde durch einen Algorithmus automatisch berechnet. Als Kriterien dienen die Steilheit und die Absturzgefährdung. Die Bewertung bezieht sich vorwiegend auf den skifahrerischen Teil der Route.',
        13: 'Achtung: Der tatsächliche Schwierigkeitsgrad hängt von den aktuellen Verhältnissen und deiner Verfassung ab!',
      },
      link: getInfoServerLink() + '/index.php/schwierigkeit',
    },
    skiDepots: {
      title: 'Ski-Depot',
      content: {
        0: 'Skitourenguru berechnet auf Grund der Geländeeigenschaften (Steilheit und Kurvatur) einen wahrscheinlichen Ort für das Ski-Depot.',
        1: 'In der Regel kann bis zum Ski-Depot mit den Skiern aufgestiegen werden. Ab dem Ski-Depot bis auf den Gipfel muss in der Regel zu Fuss gegangen werden.',
        2: 'Der Schwierigkeitsgrad berücksichtigt in erster Linie den Abschnitt zwischen dem Startpunkt und dem Ski-Depot, in zweiter Linie aber auch den Abschnitt zwischen dem Ski-Depot und dem Zielpunkt.',
      },
      link: '',
    },  
    homes: {
      title: 'Standort',
      content: {
        0: 'In welcher Umgebung suchst du Routen? Suche den Ort über die Liste oder tippe einfach den Namen einer Ortschaft ein. Du kannst auch auf der Karte auf eines der grauen Standort-Symbole klicken.',
        1: 'Im nächsten Schritt kannst du die Anreisedistanz einschränken.',
      },
      link: '',
    },
    travelDistance: {
      title: 'Anreisedistanz',
      content: {
        0: "In welcher Entfernung um den Standort suchst du Routen? Die Entfernung wird in 'Kilometern über die Strasse' angegeben.",
      },
      link: '',
    },
    startElevation: {
      title: 'Starthöhe',
      content: {
        0: 'In welcher Höhe über Meer soll die Skitour beginnen?',
      },
      link: '',
    },
    elevationGain: {
      title: 'Höhendifferenz',
      content: {
        0: 'Wie viele Höhenmeter willst du aufsteigen?',
      },
      link: '',
    },
    routeType: {
      title: 'Routentyp',
      content: {
        0: 'Für welchen Routentyp interessierst du dich?',
      },
      link: '',
    },    
    riskIndicator: {
      title: 'Lawinenrisiko',
      content: {
        0: 'Was bedeuten die drei Risikokategorien?',
        1: 'Tiefes Risiko (Werte 0\u200B\u2013\u200B1): ',
        2: 'Erhöhtes Risiko (Werte 1\u200B\u2013\u200B2): ',
        3: 'Hohes Risiko (Werte 2\u200B\u2013\u200B3): ',
        4: 'Relativ sicher, wenn keine speziellen Gefahrenzeichen vorliegen. Auch grüne Routen weisen ein Restrisiko auf.',
        5: 'Nur für erfahrene Tourengängerinnen und Tourengänger. Wenn du eine solche Tour planen willst, musst du die Situation zuerst umfassend analysieren.',
        6: 'Auf Skitouren mit hohem Lawinenrisiko solltest du am besten verzichten.',
        7: 'Der Wert zwischen 0 und 3 zeigt an, wie nahe eine Route an der Grenze zur nächsten Kategorie liegt. Das Risiko steigt exponentiell mit dem angegebenen Wert.',
      },
      link: getInfoServerLink() + '/index.php/symbolik',
    },
    ratings: {
      title: 'Typische Bewertungen',
      content: {
        0: 'Skitourenguru hat diese Route bei 10 typischen Lawinenbulletins bewertet. Zwei Beispiele:',
        1: '\'Erheblich | 1800 m | W-N-E\': Gefahrenstufe \'Erheblich\' oberhalb 1800 m in den Expositionen von West über Nord nach Ost.',
        2: '\'Mässig | alle | alle\': Gefahrenstufe \'Mässig\' in allen Höhenstufen und allen Expositionen.',
      },
    },
    status: {
      title: 'Regions-Status',
      content: {
        0: 'In den nächsten Jahren wird Skitourenguru das Angebot schrittweise auf den ganzen Alpenraum ausweiten. In der Zwischenzeit befinden sich die abgedeckten Regionen in einem unterschiedlichen Ausbaustadium:',
      },
      link: '',
    },
    edition: {
      title: 'Ausgabe',
      content: {
        0: 'Ideale Uhrzeit für die Konsultation von Skitourenguru. Zu dieser Uhrzeit steht das Abendbulletin in der Regel zur Verfügung und Skitourenguru hat die Bewertungen aktualisiert.',
      },
      link: getInfoServerLink() + '/index.php/ostalpen',
    },
    ascentTime: {
      title: 'Aufstiegszeit',
      content: {
        0: 'Die Aufstiegszeit berechnet sich aus einer horizontalen und einer vertikalen Komponente:',
        1: 'Horizontal-Geschwindigkeit: 4400 m/h',
        2: 'Vertikal-Geschwindigkeit: 440 hm/h',
        3: 'Je nach Schwierigkeitsgrad der Route wird die Zeit etwas verlängert oder verkürzt. Pausen sind nicht eingerechnet.',
      },
      link: '',
    },
    dangerLevel: {
      title: 'Gefahrenstufe',
      content: {
        0: 'Die Lawinengefahr wird mit der fünfteiligen europäischen Lawinengefahrenskala beschrieben:',
        1: 'Gering (Stufe 1): Allgemein günstige Lawinensituation',
        2: 'Mässig (Stufe 2): Mehrheitlich günstige Lawinensituation',
        3: 'Erheblich (Stufe 3): Kritische Lawinensituation',
        4: 'Gross (Stufe 4): Sehr kritische Lawinensituation',
        5: 'Sehr gross (Stufe 5): Ausserordentliche Lawinensituation',
        6: 'Die Gefahrenstufe hängt von verschiedenen Grössen ab, insbesondere der Auslösewahrscheinlichkeit von Lawinen, der Verbreitung der Gefahrenstellen und der Lawinengrösse. Die Gefahrenstufe ist immer eine Prognose, die im Einzelhang überprüft werden muss.',
        7: 'Einzelne Lawinenwarndienste publizieren direkt oder indirekt Zwischenstufen. Diese geben an, ob die Gefahr eher im unteren Bereich (-), etwa in der Mitte (=) oder im oberen Bereich der Gefahrenstufe (+) eingeschätzt wird.'
      },
      link1: 'https://www.slf.ch/de/lawinenbulletin-und-schneesituation/wissen-zum-lawinenbulletin/gefahrenstufen.html',
      link2: getInfoServerLink() + '/index.php/imdl-de',      
    },
    coreZone: {
      title: 'Kernzone',
      content: {
        0: 'Die Kernzone bezeichnet die besonders kritischen Gefahrenstellen:',
        1: "Kritische Expositionen: Auf einer Kompassrose werden kritische Expositionen mit 'schwarz' markiert.",
        2: "Kritische Höhenstufen: Mit Hilfe eines Berg-Symbols werden kritische Höhenstufen mit 'schwarz' markiert.",
        3: 'Statistische Untersuchungen zeigen, dass das Lawinenrisiko innerhalb der Kernzone um ein vielfaches höher ist, als ausserhalb.',
        4: 'Einzelne Lawinenwarnungen Europas sind dazu übergegangen für jedes Lawinenproblem gesondert eine Kernzone auszuweisen. Skitourenguru fasst diese Kernzonen zu einer einzigen Kernzone zusammen und speist diese in den Algorithmus ein.',
      },
      link: getInfoServerLink() + '/index.php/kernzone',
    },
    snowAmount: {
      title: 'Schneehöhen',
      content: {
        0: 'In dieser Zeile werden die Schneehöhen auf der Route angezeigt:',
        1: 'Die ersten zwei Zahlen geben die Schneehöhen am Start- und Endpunkt der Route an.',
        2: 'Die Zahl in Klammern bezeichnet die durchschnittliche Schneehöhe.',
        3: 'Die letzte Zahl gibt an wie viel Prozent der Route mit mindestens 10 cm Schnee bedeckt sind.',
        4: 'Die Schneehöhen stammen von der Exolabs GmbH. Sie basieren auf den folgenden Daten:',
        5: 'Schneehöhen von automatischen Messstationen.',
        6: 'Satellitendaten (Sentinel, Landsat und Modis).',
        7: 'Wissen zu Schneehöhen in Abhängigkeit von Geländeeigenschaften.',
        8: 'Auf der Karte wird die Schneehöhe (in cm) mit den folgenden Farben angezeigt:',
      },
      link: getInfoServerLink() + '/index.php/exolabs',
    },
    snowFilter: {
      title: 'Schneehöhe am Start',
      content: {
        0: 'Mit diesem Schieber kannst du Routen filtern, die am Start eine bestimmte Mindestschneehöhe aufweisen.',
      },
    },
    about: {
      title: 'About',
      subTitle: 'Impressum',
      content: {
        0: 'Skitourenguru versteht sich als Community-Projekt. In juristischer Hinsicht ist Skitourenguru jedoch als GmbH konstituiert:',
        1: 'Du möchtest mehr wissen zu Skitourenguru?',
        2: 'Bevor du eine E-Mail schreibst, schaue dich bitte zuerst auf der Web-Seite um:',
        3: {
          0: {
            content: 'Medien',
            link: getInfoServerLink() + '/index.php/mymedia',
          },
          1: {
            content: 'Schulungsvideos',
            link: getInfoServerLink() + '/index.php/services/221-videos',
          },           
          2: {
            content: 'Lawinenkunde',
            link: getInfoServerLink() + '/index.php/lawinenkunde',
          },
          3: {
            content: 'Modell (Algorithmus)',
            link: getInfoServerLink() + '/index.php/model',
          },
          4: {
            content: 'Hilfe',
            link: getInfoServerLink() + '/index.php/help',
          },
          5: {
            content: 'Handbuch',
            link: getInfoServerLink() + '/download/intro/Intro_DE.pdf',
          },  
          6: {
            content: 'Routen zeichnen',
            link: getInfoServerLink() + '/index.php/rudr-de',
          },                                 
        }
      },
      link: getInfoServerLink() + '/index.php/about',
    },
    wildlife: {
      title: 'Wildtierschutzgebiete',
      content: {
        0: "Mit dem Layer-Button (auf der Karte unten links) im Knoten 'Kartenobjekte' kannst du die Wildtierschutzgebiete einblenden.",
      },
      link: 'https://natur-freizeit.ch/schneesport-mit-ruecksicht',
    },
    degreeOfFreedom: {
      title: 'Spielraum',
      content: {
        0: 'Je höher der Wert, desto mehr Spielraum gibst du dem Algorithmus bei der Routenanpassung. Bei 0 % erfolgt keine Routenanpassung, es wird die eingegebene Route bewertet.',
      }
    },    
    avalancheTerrainWeight: {
      title: 'Lawinengelände',
      content: {
        0: 'Je höher der Wert, desto stärker wird das Lawinengelände bei der Routenanpassung berücksichtigt.',
      }
    },    
    movementWeight: {
      title: 'Bewegungskostengewicht',
      content: {
        0: 'Je höher der Wert, desto stärker werden die Bewegungskosten gegenüber den Durchgangskosten berücksichtigt.',
        1: 'Die Bewegungskosten hängen von der vertikalen und horizontalen Distanz ab.',
        2: 'Die Durchgangskosten hängen von den Geländeeigenschaften, z.B. der Hangneigung ab.'
      }
    },  
    horizontalMovingCost: {
      title: 'Distanzkosten',
      content: {
        0: 'Je höher der Wert, desto stärker werden Umwege vermieden.',
      }
    },    
    elevationGainCost: {
      title: 'Aufstiegskosten',
      content: {
        0: 'Je höher der Wert, desto mehr werden Aufstiege vermieden.',
      }
    },    
    elevationLossCost: {
      title: 'Abstiegskosten',
      content: {
        0: 'Je höher der Wert, desto mehr werden Abstiege vermieden.',
      }
    },      
  },
  donation: {
    title: 'Spendenaufruf',
    content:
      'Jede Spende, egal ob gross oder klein, ist willkommen und wird direkt in den Unterhalt und die Weiterentwicklung der Plattform investiert. ' +
      'Skitourenguru wird auch in Zukunft gratis und werbefrei sein. Zudem verzichtet die Platttform bewusst auf Logins, Trackers und News-Letters.',
  },
  address: {
    jurisdiction: 'Gerichtsstand',
    manager: ' Geschäftsführer',
  },
  locationSearch: {
    title: 'Ortssuche in Karte',
    types: {
      peak: 'Gipfel',
      hill: 'Hügel',
      saddle: 'Pass',
      alpine_hut: 'Berghütte',
      wilderness_hut: 'Biwak',
      administrative: 'Gegend',
      municipality: 'Gemeinde',
      city: 'Stadt',
      town: 'Stadt',
      village: 'Dorf',
      locality: 'Ort',
      hamlet: 'Weiler',
      isolated_dwelling: 'Flurname',
      station: 'Bahnhof',
      bus_stop: 'Bushaltestelle',
      valley: 'Tal',
      water: 'See',
      ridge: 'Rippe',
      arete: 'Rippe',
      glacier: 'Gletscher',
      river: 'Fluss',
      stream: 'Bach',
      district: 'Distrikt',
      province: 'Provinz',
      region: 'Region',
      shelter: 'Unterstand',
      viewpoint: 'Aussichtspunkt',
      camp_site: 'Campingplatz',
    },
  },
  newsChannel: {
    title: 'Guru News',
  },
  message: {
    type: {
      0: 'Information',
      1: 'Warnung',
      2: 'Fehler',
    },
    texts: {
      1: 'Der Server hat zur Zeit ein Problem mit der Bearbeitung, versuche es später nochmals!',
      2: 'Diese Funktion ist noch nicht implementiert!',
      3: 'Die Route darf nicht länger als xxx km und kürzer als yyy m sein!',
      4: 'Die Route ist ausserhalb des zur Zeit unterstützten Bereiches!',
      5: 'Die Anfrage ist ungültig!',
      6: 'Die Anfrage ist nicht erlaubt!',
      7: 'Das GPX file enthält kein Linien-Objekt.',
      8: 'Dieser Link ist ungültig.',
      9: 'Diese Route hat einen hohen Schwierigkeitsgrad!',
      10: 'Diese Route hat einen dermassen hohen Schwierigkeitsgrad, dass sie mit Skiern kaum begehbar/befahrbar ist!',
      11: 'Der Server ist zur Zeit ausgelastet, versuche es später nochmals!',
      12: 'Zwischen 17h und ca. 18.15h werden in den Ostalpen nicht alle Routen angezeigt, da einzelne Lawinenwarndienste das Bulletin erst am 18h aktualisieren.' ,
      13: "Seit neustem kannst du eine Route selber zeichnen. Anschliessend passt Skitourenguru die Route an und bewertet sie: Im Menu oben rechts unter 'Route zeichnen'.",
    }
  },
  rudr: {
    title: 'Bewertungskontext',
    description: "Zeichne eine Route auf der Karte oder lade ein GPX-File. Beim Zeichnen benutze Doppel-Klick um die Route zu beenden. Wähle ein Lawinenbulletin. Zum Schluss klicke auf den Button 'Start Bewertung' (unten).",
    forecast: {
      title: 'Eingabe des Lawinenbulletins',
      dangerLevel: 'Gefahrenstufe',
      critEle: 'Kritische Höhe',
      critExpos: 'Kritische Expositionen: Klicke auf die Rose!'
    },
    settings: {
      title: 'Einstellungen zur Routenanpassung',
      degreeOfFreedom: 'Spielraum',
      avalancheTerrainWeight: 'Lawinengelände',
      horizontalMovingCost: 'Distanzkosten',
      elevationGainCost: 'Aufstiegskosten',
      elevationLossCost: 'Abstiegskosten',
      movementWeight: 'Bewegungskostengewicht',
    },   
    buttons: {
      cutGpx: "Schneide GPX-File am 'Gipfel'",
      loadGpx: 'Lade GPX-File',
      start: 'Start Bewertung',
      reset: 'Reset Eingabe',
      respectProtAreas: 'Beachte Schutzgebiete'
    },
    output: {
      name: 'Unbekannt'
    }
  },

  /**************************************** */
  //copied from vuetify v-data-table
  dataTable: {
    itemsPerPageText: 'Zeilen pro Seite:',
    ariaLabel: {
      sortDescending: ': Absteigend sortiert. Aktivieren um Sortierung zu entfernen.',
      sortAscending: ': Aufsteigend sortiert. Aktivieren um absteigend zu sortieren.',
      sortNone: ': Nicht sortiert. Aktivieren um aufsteigend zu sortieren.',
    },
    sortBy: 'Sortiere nach',
  },
  dataFooter: {
    itemsPerPageText: 'Elemente pro Seite:',
    itemsPerPageAll: 'Alle',
    nextPage: 'Nächste Seite',
    prevPage: 'Vorherige Seite',
    firstPage: 'Erste Seite',
    lastPage: 'Letzte Seite',
    pageText: '{0}\u200B\u2013\u200B{1} von {2}',
  },
  noDataText: 'Keine Treffer',
  /**************************************** */
}
