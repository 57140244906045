import { getInfoServerLink } from '../utilities'

export default {
  yes: 'Yes',
  no: 'No',
  ok: 'OK',
  cancel: 'Cancel',
  information: 'Information',
  home: 'Home',
  header: 'Skitourenguru',
  news: 'News',
  attention: 'Caution',
  filterTable: {
    search: 'Search summit name',
    filter: 'Filter routes',
    to: 'to',
    of: 'of',
    results: 'Result:',
    routes: 'Routes',
  },
  regions: {
    ch: 'Switzerland',
    au: 'East Alps',
    fr: 'France',
    ie: 'Notheast-Italy',
    iw: 'Nothwest-Italy',
  },
  tabs: {
    map: 'Map',
    regionsList: 'Introduction',
    trackDetails: 'Route details',
    trackSearch: 'Route search',
  },
  menu: {
    info: 'Background',
    language: 'de en fr it',
    manual: 'Handbook',
    video: 'Training videos',
    news: 'News',
    media: 'Media',
    prevention: 'Accident prevention (BFU)',
    about: 'About',
    drawRoute: 'Draw routes'    
  },
  intro: {
    summer: {
      title: 'Summer service',
      content:
        'Usually avalanche forecasts are available between November and May. For regions without avalanche forecasts, a demo version (Off) is displayed. Caution: The results in such regions are invalid!',
    },
    guru: {
      title: "What's Skitourenguru",
      content:
        'Skitourenguru supports you in the selection and planning of a suitable ski tour with low avalanche risk. For this purpose, Skitourenguru assigns daily an avalanche risk to thousands of ski tours in the alpine region:',
      table: {
        header: {
          symbol: 'Symbol',
          values: 'Values',
          risks: 'Avalanche risk',
        },
        content: {
          values: {
            0: '0\u200B\u2013\u200B1',
            1: '1\u200B\u2013\u200B2',
            2: '2\u200B\u2013\u200B3',
          },
          risks: {
            0: 'Low avalanche risk',
            1: 'Elevated avalanche risk',
            2: 'High avalanche risk',
          },
          riskQualifiers: {
            0: 'low',
            1: 'elevated',
            2: 'high',
          },
        },
      },
    },
    cruxes: {
      content: 'In addition Skitourenguru marks static route cruxes with grey rings:',
      table: {
        header: {
          symbol: 'Symbol',
          values: 'Class',
          risks: 'Meaning',
        },
        content: {
          values: {
            0: '1',
            1: '2',
            2: '3',
          },
          classes: {
            0: 'Avalanche terrain',
            1: 'Typical avalanche terrain',
            2: 'Very typical avalanche terrain',
          },
        },
      },
    },
    limits: {
      1: 'On site and in the individual slope usually information becomes accessible that is not available to Skitourenguru. ',   
      2: 'The information presented on Skitourenguru is subject to uncertainties (see ',
      3: {
        text: 'Handbook',
        link: getInfoServerLink() + '/download/intro/Intro_EN.pdf',
      },
      4: '). ',
      5: 'Therefore Skitourenguru must not be the only criterion to access a slope.'
    },    
    choose: {
      title: 'Choose a region',
      table: {
        header: {
          region: 'Region',
          status: 'State',
          edition: 'Edition',
          valid: 'Valid',
        },
      },
    },
    supported: {
      title: 'Partners',
      content: 'Skitourenguru is supported among others by the following partners.',
      partners: {
        bfu: {
          name: 'BFU',
          link: 'https://www.bfu.ch/de/ratgeber/skitouren',
        },
        sac: {
          name: 'SAC',
          link: 'https://www.sac-cas.ch/en/training-and-safety/safety/safety-when-ski-touring/'
        },
        colltex: {
          name: 'colltex',
          link: 'https://www.colltex.ch/'
        }
      }  
    },
  },
  difficultyScales: {
    0: '/',
    1: 'F/1.2',
    2: 'F+/1.3',
    3: 'PD\u2212/2.1',
    4: 'PD/2.2',
    5: 'PD+/2.3',
    6: 'AD\u2212/3.1',
    7: 'AD/3.2',
    8: 'AD+/3.3',
    9: 'D\u2212/4.1',
    10: 'D/4.2',
    11: 'D+/4.3',
    12: 'TD\u2212/5.1',
    13: 'TD/5.2',
    14: 'TD+/5.3',
    15: 'ED\u2212/5.3',
    16: 'ED/5.4',
    17: 'ED+/5.4',
    18: 'EX/5.5',
  },
  langDialog: {
    chooseLang: 'Choose a language',
  },
  disclaimer: {
    title: 'Terms of use',
    text: {
      0: 'This prototype adjusts a route you have chosen and estimates its avalanche risk. Note that this is a prototype and the results are in principle uncertain!',
      1:
        'Skitourenguru is an additional tool for selecting and planning a ski tour in an independent, self-responsible way and the avalanche risk assessment to be carried out therefor. ' + 
        'For this purpose, thousands of ski tours in the Alps are automatically assigned avalanche risks every day on the basis of the latest local ',
      2: {
        text: 'avalanche forecasts',
        link: getInfoServerLink() + '/index.php/ostalpen',
      },
      3: ' and official ',
      4: {
        text: 'terrain data',
        link: getInfoServerLink() + '/index.php/athm-de',
      },
      5: ', using programmed execution rules: green (slight risk), orange (elevated risk) or red (high risk). ',
      6: 'Skitourenguru GmbH assumes no guarantee and therefore no liability for the accuracy of the information and assessments provided. ',
      7: 'These may be incorrect (see ',
      8: {
        text: 'manual',
        link: getInfoServerLink() + '/download/intro/Intro_EN.pdf',
      },
      9: ').  Please note that Skitourenguru should only be used as a supplementary source of information for planning a ski tour at your own risk. ',
      10: 'Anonymized data is collected when you use Skitourenguru. Our ',
      11: {
        text: 'privacy policy',
        link: getInfoServerLink() + '/privacy-en',   
      },
      12: '  tells you what data we collect and the purposes for which we collect your data.',
      13: 'Do you agree with these terms of use? ',
    },
  },
  editionDescription: 'Ideal time for the consultation of Skitourenguru',
  regionStatus: {
    0: {
      short: '-',
      medium: '-',
      long: '',
      validity: '',
    },
    1: {
      short: 'On',
      medium: 'Full service',
      long: 'The service for the region is active.',
      validity: '',
    },
    2: {
      short: 'Test',
      medium: 'Test mode',
      long:
        'The service is active as a test version. Such test versions will be activated for regions with little experience. The information displayed is therefore less reliable, then in fully active regions.',
      validity: 'Results uncertain',
    },
    3: {
      short: 'Wait',
      medium: 'About calculating',
      long:
        'At the moment there is no valid data available for this region, because Skitourenguru is currently calculating it. Try 15 minutes later with a Refresh (F5).',
      validity: 'Results expired',
    },
    4: {
      short: 'Off',
      medium: 'Demonstration mode',
      long:
        'There is no valid avalanche forecast available. Therefore the region is in demonstration mode. The displayed results are invalid.',
      validity: 'Results invalid',
    },
    5: {
      short: 'Project',
      medium: 'In projection',
      long: 'The service for the region is only in planning. All displayed results are invalid.',
      validity: 'Results invalid',
    },
  },
  vectorLayers: {
    1: 'Regions',
    2: 'Avalanche risk',
    3: 'Routes',
    4: 'Homes',
    5: 'Risk profiles',
    6: 'Cruxes',
    7: 'Webcams',
    8: 'Avalanche forecast',
    9: 'Avalanche accidents',
    10: 'Current home',
    11: 'Ski depot',
    12: 'Protected Areas'
  },
  layerGroups: {
    title: 'Layer Switcher',
    base: 'Base maps',
    overlay: 'Overlay maps',
    objects: 'Map objects',
  },
  rasterLayers: {
    1009: 'Test',
    1010: 'BaseMap TopoMap (Austria)',
    1011: 'BEV/OTM (East Alps)',
    1012: 'BKG TopoMap (Germany)',
    1013: 'Bergfex TopoMap (Austria)',
    1014: 'Kompass TopoMap (Alps)',
    1015: 'Google Hybrid',
    1016: 'ESRI TopoMap',
    1017: 'OpenStreetMap',
    1018: 'OpenTopoMap (World)',
    1019: 'MAP1',
    1020: 'OpenCycleMap',
    1021: 'OSMAnd',
    1022: 'OSM Humanitarian',
    1023: 'Wikimedia',
    1024: 'MountainMap',
    1025: 'IGN TopoMap (France)',
    1026: 'IGN TopoMap (France)',
    1027: 'IGN Slope (France)',
    1028: 'Swisstopo TopoMap',
    1029: 'Slope classes',
    1030: 'TMC TopoMap (Switzerland)',
    1031: 'OpenTopoMap (Alps)',
    1032: 'IGN TopoMap (France)',
    1033: 'IGM TopoMap (NE-Italy)',
    1034: 'IGM TopoMap (NW-Italy)',
    1045: 'Corridors (Switzerland)',
    1046: 'SkiMap (Switzerland)',
    1047: 'Exolabs Snowcover',
    1048: 'Exolabs Snowdepth',
    1049: '4UMaps',
    1050: 'Wildlife reserves',
    1051: 'Wildlife areas',    
    1052: 'Ski routes (SAC)',
    1053: 'Public transport stops',
    1054: 'Swissimage',
    1055: 'Swisstopo 1:10K',
    1056: 'Swisstopo TopoMap (x2)',
    1057: 'Avalanche Terrain (ATH)',
    1058: 'Avalanche Terrain (CAT)',
    1059: 'Risk to fall down',
    1060: 'Remoteness',
    1061: 'Protected Areas',
    1062: 'Avalanche Terrain (ATHM)',
    1063: 'Slope classes',
    1064: 'Risk to fall down',
    1065: 'IGN Orthophoto',  
    1066: 'Austria Orthophoto',  
    1067: 'OTM/Swisstopo/BEV/LDBV',   
    1068: 'Exolabs Snow difference (-48h)',
    1069: 'Exolabs Snow difference (+48h)',     
    1070: 'Ski runs',   
    1071: 'Hiking trails',
    1072: 'Corridor',
    1073: 'Skitourenguru Routes'
  },
  bulletin: {
    title: 'Avalanche forecast',
    dangerLevels: {
      1: 'Low',
      2: 'Moderate',
      3: 'Considerable',
      4: 'High',
      5: 'Very High',
    },
    dayCourseWarning: 'Caution: Increase in danger level during the day course. The estimations are valid only for the early morning hours!',
    invalidWarning: "Caution: This avalanche forecast is not current. That means that the risk indicators aren't valid neither!",
    skitourenguruWarning: "The danger level and the 'avalanche prone locations' are shown below as they are fed into the Skitourenguru algorithm. The danger level and the 'avalanche prone locations' are read from structurally different avalanche bulletins. In special cases, this can lead to differences from the original avalanche bulletin.",
    originalWarning: "The content of the original avalanche bulletin is presented below in a harmonized form that is as complete as possible. As the avalanche bulletins in the Alpine region differ structurally, this can lead in special cases to deviations from the original avalanche bulletin.",
    coreZoneInfo: "Skitourenguru assumes that the danger level outside the 'avalanche prone locations' is one lower.",
    dangerLevelWarning3: "Attention: This route is located in an area with the danger level 'Considerable'. The Skitourenguru model focuses on avalanches triggered by people (95% of fatal accidents). However, spontaneous avalanches are also possible at 'Considerable'. Include them in your planning from danger level 'Considerable (3+)' at the latest!",
    dangerLevelWarning4: "Attention: This route is located in an area with the danger level 'High'. The Skitourenguru model focuses on avalanches triggered by people (95% of fatal accidents). However, spontaneous avalanches are also likely in 'High' areas, so include them in your planning!",
    table: {
      edition: 'Edition',
      validTill: 'Valid till',
      dangerLevel: 'Danger level',
    },
    periode: {
      day_periode: 'Whole day',
      cold_periode: 'Morning',
      hot_periode: 'Afternoon'
    },
    moisture: {
      overview: 'Overview',
      dry: 'Dry avalanches',
      wet: 'Wet avalanches'
    },
    panelHeaders: {
      texts: 'Textual description',
      feedback: 'Feedback',
      algorithm: 'Basis for Skitourenguru'
    },    
    problems: {
      NEW_SNOW: "Fresh snow",
      WIND_SLAB: "Snow drifts",
      WET_SNOW: "Wet avalanches",
      PERSISTENT_WEAK_LAYERS: "Old snow",
      GLIDING_SNOW: "Full-depth avalanches",
      FAVOURABLE_SITUATION: "Favourable situation",
      NO_DISTICT_AVALANCHE_PROBLEM: "No pronounced avalanche problem" 
    },
    problemLink: 'https://www.slf.ch/en/avalanche-bulletin-and-snow-situation/about-the-avalanche-bulletin/typical-avalanche-problems/',
    publisher: {
      ch: {
        title: '©\u2006SLF',
        link: 'https://whiterisk.ch/en/conditions',
      },
      au: {
        title: 'dependent on region',
        link: getInfoServerLink() + '/index.php/ostalpen',
      },
      fr: {
        title: '©\u2006Meteo France',
        link: 'http://www.meteofrance.com/previsions-meteo-montagne/bulletin-avalanches',
      },
      ie: {
        title: '©\u2006AINEVA',
        link: 'https://bollettini.aineva.it/bulletin/latest',
      },
      iw: {
        title: '©\u2006AINEVA',
        link: 'https://bollettini.aineva.it/bulletin/latest',
      },
      title: 'Link to the original',
    },
    feedback: {
      text: 'Report your observations to the SLF and help to improve the quality of the avalanche bulletin: ',
      link1: 'https://www.slf.ch/en/avalanche-bulletin-and-snow-situation.html#observations',
      link2: 'https://pro.slf.ch/reply/public/?lang=en',
      link_text: 'To the form...',
    },
    poll: {
      text1_ch: 'The SLF is',
      text1_au: 'The avalanche warning services of Tyrol, Alto Adige and Trentino are',      
      text2: ' looking for snow sports enthusiasts who are willing to participate  over several winters in avalanche bulletin surveys. ',
      text3: 'Join in and participate in the further development of the avalanche bulletin! ',
      link: 'To the survey...',
      link_ch: 'https://eurosignup.avalancheresearch.ca/slf_en/',
      link_au: 'https://eurosignup.avalancheresearch.ca/euregio_en/' 
    }
  },
  route: {
    title: 'Route description',
    diffWarning:
      'Caution: Routes from AD/3.2 (Rather Difficult) may require special skills and an equivalent formation!',
    stop: 'Summit',
    start: 'Start',
    startElevation: 'Start elevation',
    stopElevation: 'Summit elevation',
    travelDistance: 'Travel distance from home',
    elevationDifference: 'Elevation gain',
    length: 'Route length',
    time: 'Ascent time',
    snowAmount: 'Snow',
    snowFilter: 'Snow depth at start',
    difficulty: 'Difficulty grade',
    type: ['Valley to summit', 'Valley to hut', 'Hut to summit'],
    beta: ' Beta!',
    riskIndicator: 'Avalanche risk',
    rising: 'rising!',
    elevationGain: 'm',
    all: 'all',
    links: {
      gps: {
        title: 'GPS track',
        link: 'GPX file',
      },
      gipfelbuch: {
        title: 'Route info',
        link: 'Gipfelbuch',
      },
      sac: {
        title: 'Route info',
        link: 'SAC route portal',
      },      
      weather: {
        title: 'Weather',
        link: 'MeteoBlue',
      },
      literature: {
        title: 'Literature',
        link: {
          panico: 'Panico Guide'
        }
      },
      wild: {
        title: 'Wildlife reserves',
        crosses: 'Yes',
        nearby: 'In xxx m distance',
        uri: 'https://natur-freizeit.ch/snow-sports-and-respect',
      },
      transportSchedule: {
        title: 'Journey',
        link: 'Google Map',
      },
    },
    resetFilters: 'Reset filters',
    diff: {
      manual: '(manual)',
      automatic: '(automatic)',
    },
  },
  ratings: {
    title: 'Typical Ratings',
    intro: 'What if... Avalanche risk for 10 typical avalanche bulletins:',    
    displayMore: 'Display more...',
    displayLess: 'Display less...',    
    bulletins: {
      0: 'Low | all | all',
      1: 'Moderate | 2200 m | NW-N-NE',
      2: 'Moderate | 1800 m | W-N-E',
      3: 'Moderate | all | all',
      4: 'Considerable | 2200 m | NW-N-NE',
      5: 'Considerable | 1800 m | W-N-E',
      6: 'Considerable | 1600 m | all',
      7: 'Considerable | all | all',
      8: 'High | 1800 m | all',
      9: 'High | all | all',
    }
  },
  info: {
    title: 'Further Information',
  },
  protectedAreas: {
    title: 'Protected Areas',
    intro: 'This protected area is relevant for winter sports.',
    info: 'Information',
    org: 'Responsible',
    states: {
      S10: 'Trespassing outside permitted paths and routes is forbidden (red).',
      S20: 'Trespassing outside permitted paths and routes is not recommended (yellow).'
    }
  },  
  accidents: {
    title: 'Avalanche accident',
    content: {
      0: 'Danger level:',
      1: 'Date:',
      fatality: {
        1: 'Accident with fatality',
        0: 'Accident without fatality',
      },
      activity: {
        1: 'Backcountry accident',
        2: 'Off-piste accident'
      },
      sourceName: {
        1: '© SLF',
        2: '© LAWIS',
        3: '© Data-Avalanche',
        4: '© AINEVA',        
      }, 
      sourceUrl: {
        1: 'https://www.slf.ch/de/lawinen/unfaelle-und-schadenlawinen.html',
        2: 'https://www.lawis.at/incident?#${id}',
        3: 'http://www.data-avalanche.org/avalanche/${id}',
        4: 'https://aineva.it/incidenti/',        
      },  
    },
    link: '',
  }, 
  help: {
    cruxes: {
      title: 'Cruxes',
      content: {
        0: 'Skitourenguru marks cruxes with grey rings.',
        1: 'One ring: Avalanche terrain',
        2: 'Two rings: Typical avalanche terrain',
        3: 'Three rings: Very typical avalanche terrain',
        4: 'The marking is done independently of the current snow and avalanche conditions and exclusively based on the terrain. During the ski tour you should make an individual slope assessment before each crux point.',
      },
      link: getInfoServerLink() + '/index.php/cruxes',
    },
    difficulty: {
      title: 'Difficulty grades',
      content: {
        0: "Depending on the region, difficulty grades are expressed using the 'SAC scale' or the 'Toponeige scale':",
        1: 'F/2: Easy',
        2: 'PD/3: Little difficult',
        3: 'AD/4: Rather difficult',
        4: 'D/5: Difficult',
        5: 'TD/6: Very difficult',
        6: 'With +/\u2013 (SAC scale) or a subsequent number (Toponeige) difficulty grades can be refined.',
        7: 'Recommendation: Choose backcountry ski tours that you can manage. Compare the difficulty grade with routes you know.',
        8: 'Depending on the route and region, difficulty grades are assigned manually and/or automatically:',
        9: 'Manual assignement: ',
        10: 'The difficulty grades are based on expert judgement. The main criteria are steepness, exposure and clearance conditions. The evaluation refers mainly to the skiing part of the route.',
        11: 'Automatic assignement: ',
        12: 'The difficulty grades were automatically calculated by an algorithm. The criteria used are the steepness and the exposure. The evaluation refers mainly to the skiing part of the route.',
        13: 'Caution: The actual difficulty grade depends on the current conditions and your constitution!',
      },
      link: getInfoServerLink() + '/index.php/schwierigkeit',
    },
    skiDepots: {
      title: 'Ski Depot',
      content: {
        0: 'Skitourenguru calculates a probable location for the ski depot based on the terrain characteristics (steepness and curvature)..',
        1: 'As a rule, it is possible to walk up to the ski depot with your skis. From the ski depot to the summit, you usually have to climb on foot.',
        2: 'The difficulty grade takes into account first of all the section between the starting point and the ski depot, but secondly also the section between the ski depot and the target point.',
      },
      link: '',
    }, 
    homes: {
      title: 'Home',
      content: {
        0: 'In which area are you looking for routes? Search your home via the list or just type in the name of a place. You can also click on one of the grey home icons on the map.',
        1: 'In the next step you can limit the travel distance.',
      },
      link: '',
    },
    travelDistance: {
      title: 'Travel distance',
      content: {
        0: "How far around the home do you look for routes? The distance is given in 'kilometers by road'.",
      },
      link: '',
    },
    startElevation: {
      title: 'Start elevation',
      content: {
        0: 'At what altitude above sea level should the backcountry ski tour begin?',
      },
      link: '',
    },
    elevationGain: {
      title: 'Elevation gain',
      content: {
        0: 'How many vertical meters do you want to climb?',
      },
      link: '',
    },
    routeType: {
      title: 'Type of route',
      content: {
        0: 'What type of route are you interested in?',
      },
      link: '',
    },      
    riskIndicator: {
      title: 'Avalanche risk',
      content: {
        0: 'What do the three risk categories mean?',
        1: 'Low risk (Values 0\u200B\u2013\u200B1): ',
        2: 'Elevated risk (Values 1\u200B\u2013\u200B2): ',
        3: 'High risk (Values 2\u200B\u2013\u200B3): ',
        4: 'Relatively safe, if no special danger signs are present. Green routes also have a residual risk.',
        5: 'Only for experienced winter sportemen. If you want to plan such a tour, you first have to analyze the situation thoroughly.',
        6: 'It is best to avoid backcountry ski tours with a high avalanche risk.',
        7: 'The value between 0 and 3 indicates how close a route is to the border of the next category. The risk increases exponentially with the given value.',
      },
      link: getInfoServerLink() + '/index.php/symbolik',
    },
    ratings: {
      title: 'Typical Ratings',
      content: {
        0: 'Skitourenguru has rated this route at 10 typical avalanche bulletins. Two examples:',
        1: '\'Considerable | 1800 m | W-N-E\': Danger level \'Considerable\' above 1800 m in the aspects from west over north to east.',
        2: '\'Moderate | all | all\':  Danger level \'Considerable\' in all elevations and all aspects.',
      },
    },    
    status: {
      title: 'Region state',
      content: {
        0: 'In the next few years, Skitourenguru will gradually expand the service to the entire Alpine region. In the meantime, the covered regions are in a different stage of development:',
      },
      link: '',
    },
    edition: {
      title: 'Edition time',
      content: {
        0: 'Ideal time for consulting Skitourenguru. At this time the evening avalanche forecast is usually available and Skitourenguru has updated the ratings.',
      },
      link: getInfoServerLink() + '/index.php/ostalpen',
    },
    ascentTime: {
      title: 'Ascent time',
      content: {
        0: 'The ascent time is calculated from a horizontal and a vertical component:',
        1: 'Horizontal speed: 4400 m/h',
        2: 'Vertical speed: 440 m/h',
        3: 'Depending on the difficulty grade of the route, the time will be slightly extended or shortened. Breaks are not included.',
      },
      link: '',
    },
    dangerLevel: {
      title: 'Danger level',
      content: {
        0: 'The danger level is discribed by the  the five-level European avalanche danger scale.',
        1: 'Low (Level 1): Generally favourable avalanche situation',
        2: 'Moderate (Level 2): Mostly favourable avalanche situation',
        3: 'Considerable (Level 3): Critical avalanche situation',
        4: 'High (Level 4): Very critical avalanche situation',
        5: 'Very high (Level 5): Extraordinary avalanche situation',
        6: 'The danger level is worked out on the basis of a range of variables, in particular the avalanche triggering probability, the prevalence of avalanche prone locations and the avalanche size. A danger level always applies to a region and not to one particular slope. ',
        7: 'Individual avalanche warning services publish directly or indirectly intermediate subdivisions. These subdivisions indicate whether the danger is estimated to be towards the bottom end (-), more or less in the middle (=) or towards the top end (+) of the forecast level.'
      },
      link1: 'https://www.slf.ch/en/avalanche-bulletin-and-snow-situation/about-the-avalanche-bulletin/danger-levels.html',
      link2: getInfoServerLink() + '/index.php/imdl-de',
    },
    coreZone: {
      title: 'Avalanche prone locations',
      content: {
        0: "The 'avalanche prone locations' zone indicate the particularly critical danger zones:",
        1: "Critical aspects: On a compass rose, critical exposures are marked 'black'.",
        2: "Critical elevations: With the help of a mountain symbol, critical altitude levels are marked with 'black'.",
        3: "Statistical studies show that the avalanche risk is many times higher inside the 'avalanche prone locations' than outside.",
        4: "Some avalanche warning services in Europe have started to display separate 'avalanche prone locations' for each 'avalanche problem'. Skitourenguru combines these 'avalanche prone locations' into a single 'avalanche prone location' and feeds it into the algorithm.",
      },
      link:
        'https://www.slf.ch/en/avalanche-bulletin-and-snow-situation/about-the-avalanche-bulletin/avalanche-prone-locations.html',
    },
    snowAmount: {
      title: 'Snow depths',
      content: {
        0: 'This line displays the snow depths on the route:',
        1: 'The first two numbers indicate the snow depths at the start and end points of the route.',
        2: 'The number in brackets indicates the average snow depth.',
        3: 'The last number indicates what percentage of the route is covered with at least 10 cm of snow.',
        4: 'The snow depths are provided by Exolabs GmbH. They are based on the following data:',
        5: 'Snow depths from automatic measuring stations.',
        6: 'Satellite data (Sentinel, Landsat and Modis)',
        7: 'Knowledge of snow depths as a function of terrain characteristics.',
        8: 'On the map the snow depth (in cm) is shown with the following colors:',
      },
      link: getInfoServerLink() + '/index.php/exolabs',
    },
    snowFilter: {
      title: 'Snow depth at start',
      content: {
        0: 'With this slider you can filter routes that have a certain minimum snow depth at the start.',
      },
    },
    about: {
      title: 'About',
      subTitle: 'Imprint',
      content: {
        0: 'Skitourenguru sees itself as a community project. From a legal point of view, however, Skitourenguru is constituted as a limited liability company:',
        1: 'You want to know more about Skitourenguru?',
        2: 'Before you write an e-mail, please have a look at the web page first:',
        3: {
          0: {
            content: 'Media',
            link: getInfoServerLink() + '/index.php/mymedia',
          },
          1: {
            content: 'Training videos',
            link: getInfoServerLink() + '/index.php/services/221-videos',
          },
          2: {
            content: 'Avalanche science',
            link: getInfoServerLink() + '/index.php/lawinenkunde',
          },          
          3: {
            content: 'Model (Algorithm)',
            link: getInfoServerLink() + '/index.php/model',
          },
          4: {
            content: 'Help',
            link: getInfoServerLink() + '/index.php/help',
          },
          5: {
            content: 'Manual',
            link: getInfoServerLink() + '/download/intro/Intro_EN.pdf',
          },  
          6: {
            content: 'Draw routes',
            link: getInfoServerLink() + '/index.php/rudr-de',
          },  
        }
      },
      link: getInfoServerLink() + '/index.php/about',
    },
    wildlife: {
      title: 'Wildlife reserves',
      content: {
        0: "With the layer button (on the bottom left of the map) in the node 'Map objects' you can show wildlife reserves.",
      },
      link: 'https://natur-freizeit.ch/snow-sports-and-respect',
    },
    degreeOfFreedom: {
      title: 'Margin for maneuver',
      content: {
        0: 'The higher the value, the more margin for maneuver you give the algorithm when adjusting the route. At 0%, no route adjustment is made, the route as entered is evaluated.',
      }
    },    
    avalancheTerrainWeight: {
      title: 'Avalanche terrain',
      content: {
        0: 'The higher the value, the more the avalanche terrain is taken into account in the route adjustment.',
      }
    },    
    movementWeight: {
      title: 'Movement cost weight',
      content: {
        0: 'The higher the value, the more the movement costs are taken into account compared to the transit costs.',
        1: 'The movement cost depends on the vertical and horizontal distance.',
        2: 'Transit costs depend on terrain characteristics, such a slope.'
      }
    },  
    horizontalMovingCost: {
      title: 'Distance costs',
      content: {
        0: 'The higher the value, the more detours are avoided.',
      }
    },    
    elevationGainCost: {
      title: 'Ascent costs',
      content: {
        0: 'The higher the value, the more ascents are avoided.',
      }
    },    
    elevationLossCost: {
      title: 'Descent costs',
      content: {
        0: 'The higher the value, the more descents are avoided.',
      }
    },    
  },  
  donation: {
    title: 'Call for donations',
    content:
      'Any donation, large or small, is welcome and will be directly invested in the maintenance and development of the platform. ' +
      'Skitourenguru is and remains free of charge and without advertising. In addition, the platform deliberately works without logins, trackers and news letters.',
  },
  address: {
    jurisdiction: 'Place of jurisdiction',
    manager: 'Managing Director',
  },
  locationSearch: {
    title: 'Location search in map',
    types: {
      peak: 'Peak',
      hill: 'Hill',
      saddle: 'Saddle',
      alpine_hut: 'Alpine hut',
      wilderness_hut: 'Wilderness Hut',
      administrative: 'Area',
      municipality: 'Municipality',
      city: 'City',
      town: 'Town',
      village: 'Village',
      locality: 'Locality',
      hamlet: 'Hamlet',
      isolated_dwelling: 'Isolated Dwelling',
      station: 'Train station',
      bus_stop: 'Bus stop',
      valley: 'Valley',
      water: 'Lake',
      ridge: 'Ridge',
      arete: 'Ridge',
      glacier: 'Glacier',
      river: 'River',
      stream: 'Stream',
      district: 'District',
      province: 'Province',
      region: 'Region',
      shelter: 'Shelter',
      viewpoint: 'Viewpoint',
      camp_site: 'camp Site',
    },
  },
  newsChannel: {
    title: 'Guru News',
  },
  message: {
    type: {
      0: 'Information',
      1: 'Warning',
      2: 'Error',
    },
    texts: {
      1: 'The server has a problem with processing at the moment, try again later!',
      2: 'This function is not implemented yet!',
      3: 'The route must not be longer than xxx km and shorter then yyy m!',
      4: 'The route is outside the currently supported extent!',
      5: 'The request is invalid!',
      6: 'The request is not allowed!',
      7: 'The GPX file does not contain a line object.',
      8: 'This link is invalid.',
      9: 'This route has a high level of difficulty!',
      10: 'This route has such a high degree of difficulty that it is hardly passable with skis!',
      11: 'The server is currently busy, try again later!',
      12: 'Not all routes in the Eastern Alps are displayed between 17h and approx. 18.15h, as a few avalanche warning services only update the bulletin at 18h.',
      13: "You can now draw a route yourself. Skitourenguru then adjusts the route and rates it: In the menu at the top right under 'Draw routes'.",
    }
  },
  rudr: {
    title: 'Evaluation context',
    description: "Draw a route on the map or load a GPX file. When drawing, use double-click to finish the route. Select an avalanche forecast. Finally click on the 'Start rating' button (below).",
    forecast: {
      title: 'Input of the avalanche forecast',
      dangerLevel: 'Danger level',
      critEle: 'Critical elevation',
      critExpos: 'Critical Exposures: Click on the rose!'
    },
    settings: {
      title: 'Route adjustment settings',
      degreeOfFreedom: 'Margin for maneuver',
      avalancheTerrainWeight: 'Avalanche terrain',
      horizontalMovingCost: 'Distance costs',
      elevationGainCost: 'Ascent costs',
      elevationLossCost: 'Descent costs',
      movementWeight: 'Movement cost weight',
    },   
    buttons: {
      cutGpx: "Cut GPX file at the 'summit'",
      loadGpx: 'Load GPX file',
      start: 'Start rating',
      reset: 'Reset input',
      respectProtAreas: 'Attention to protected areas'
    },
    output: {
      name: 'Unknown'
    }
  },

  /**************************************** */
  //copied from vuetify v-data-table
  dataTable: {
    itemsPerPageText: 'Rows per page:',
    ariaLabel: {
      sortDescending: ': Sorted descending. Activate to remove sorting.',
      sortAscending: ': Sorted ascending. Activate to sort descending.',
      sortNone: ': Not sorted. Activate to sort ascending.',
    },
    sortBy: 'Sort by',
  },
  dataFooter: {
    pageText: '{0}\u200B\u2013\u200B{1} of {2}',
    itemsPerPageText: 'Items per page:',
    itemsPerPageAll: 'All',
    nextPage: 'Next page',
    prevPage: 'Previous page',
    firstPage: 'First page',
    lastPage: 'Last page',
  },
  noDataText: 'No hits',
  /**************************************** */
}
