<template>
  <div>
    <app-header @displayHelp="displayHelp" @dispose="dispose"></app-header>
    <div v-resize="onResize">
      <v-container v-if="!isMobile" fluid>
        <v-row>
          <v-col class="pa-0">
            <div id="map1" class="map"></div>
            <v-btn
              class="locationSearchButton_Desktop"
              icon
              absolute
              fixed
              top
              left
              @click="displayLocationSearchDialog()"
              min-width="0px"
              height="40px"
              width="40px"
            >
              <!-- mdi-map-search-outline, mdi-magnify -->
              <v-icon size="25x">mdi-map-search-outline</v-icon>
            </v-btn>
          </v-col>
          <v-col class="pa-0 info">
            <filter-table
              :area="area"
              :homeProp="home"
              :homes="homes"
              :skiToursList="skiToursList"
              @routesFiltered="onRoutesFiltered"
              @displayHelp="displayHelp"
              @dispose="dispose"
            ></filter-table>
          </v-col>
        </v-row>
      </v-container>

      <v-tabs slider-size="3" v-if="isMobile" v-model="tabs" grow>
        <v-tab ripple @change="updateMapTab">{{ $vuetify.lang.t('$vuetify.tabs.map') }}</v-tab>
        <v-tab ripple>{{ $vuetify.lang.t('$vuetify.tabs.trackSearch') }}</v-tab>
        <v-tabs-items v-model="tabs" touchless>
          <v-tab-item>
            <div v-resize="onResize" :style="{ height: contentHeight + 'px' }" id="map2" class="map"></div>
            <v-btn
              class="locationSearchButton_Mobile"
              icon
              absolute
              fixed
              top
              left
              @click="displayLocationSearchDialog()"
              min-width="0px"
              height="40px"
              width="40px"
            >
              <!-- mdi-map-search-outline, mdi-magnify -->
              <v-icon size="25x">mdi-map-search-outline</v-icon>
            </v-btn>
          </v-tab-item>
          <v-tab-item eager>
            <filter-table
              :area="area"
              :homeProp="home"
              :homes="homes"
              :skiToursList="skiToursList"
              @routesFiltered="onRoutesFiltered"
              @displayHelp="displayHelp"
              @dispose="dispose"
            ></filter-table>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
    <div
      v-if="status != 1 && agreed && warningEnabled"
      class="font-weight-bold fingerPointer"
      id="statusWarningId"
      :style="{ color: getWarningColor() }"
      @click="disableWarning()"
    >
      <span>{{ $vuetify.lang.t(`$vuetify.regionStatus.${status}.medium`) }} </span>
      <span>: </span>
      <span>{{ $vuetify.lang.t(`$vuetify.regionStatus.${status}.validity`) }} </span>
    </div>
    <disclaimer-dialog ref="disclaimer" style="z-index: 200;"></disclaimer-dialog>
    <help-dialog ref="help" style="z-index: 200;"></help-dialog>
    <message-dialog ref="message" style="z-index=200"></message-dialog>
    <location-search-dialog ref="locationSearchDialog" style="z-index: 200;"></location-search-dialog> 
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader.vue'
import FilterTable from '../components/FilterTable.vue'
import DisclaimerDialog from '../components/DisclaimerDialog.vue'
import HelpDialog from '../components/HelpDialog.vue'
import MessageDialog from '../components/MessageDialog.vue'
import LocationSearchDialog from '../components/LocationSearchDialog.vue'
import { olMapMixin } from '../mixins/olMapMixin'
import { resizeMixin } from '../mixins/resizeMixin'
import NProgress from 'nprogress'
import store from '@/store/store'
import { utilityMixin } from '../mixins/utilityMixin'
import { refreshMixin } from '../mixins/refreshMixin'
import { calculateMapSize, addExtentMargin, getMapServerLink, parseTime } from '../utilities'
import { transform } from 'ol/proj'
import { defaultExtents_4326, defaultExtents_3857, collections } from '../variables'

export default {
  name: 'area-view',
  mixins: [olMapMixin, resizeMixin, utilityMixin, refreshMixin],
  components: {
    AppHeader,
    FilterTable,
    DisclaimerDialog,
    HelpDialog,
    MessageDialog,
    LocationSearchDialog,
  },
  data: () => ({
    tabs: null,
    regions: null,
    symbols: null,
    skiToursList: null,
    home: '',
    homes: null,
    area: null,
    locationVectorLayer: null,
    extentMargin: 3,
    status: null,
    agreed: false,
    warningEnabled: true,
    singleClick: null
  }),
  methods: {
    onRoutesFiltered(idList, homeUic) {
      // eslint-disable-next-line no-console
      this.idList = idList
      console.log('AreaView(): Filter ' + idList.length + ' routes. Home is ' + homeUic)

      var extent = this.calcSymbolsExtent(this.extentMargin)

      this.filterVectorLayer(
        this.locationVectorLayer,
        this.layerType.LOCATION,
        function (feature) {
          var uic = feature.get('UIC')
          return uic == homeUic
        },
        this.extentMargin,
        this.area
      )

      // Check, if we have to zoom to specific coordinates
      var x = this.$route.query.x
      var y = this.$route.query.y
      if (typeof x != 'undefined' && x != null && typeof y != 'undefined' && y != null) {
        var z = this.$route.query.z
        if (typeof z == 'undefined' || z == null) {
          z = 13
        }
        this.simpleZoomTo([Number(x), Number(y)], z)
      } else {
        this.zoomTo(extent, 8, 16)
      }
      this.updateClusterDistance()

      this.setVectorLayerVisible(this.layerType.SYMBOLCLUSTER, true)
      this.setVectorLayerVisible(this.layerType.LOCATION, true)
    },
    onMouseOver(e) {
      var features = e.target.getFeatures()
      if (features.getLength() == 0) {
        this.setDefaultHeader()
        return
      }

      var feature = features.item(0)
      var type = feature.get('ltype')

      if (type == this.layerType.HOMES) {
        const title = feature.get('Name')
        this.updateHeader(title, this.status, null)
        return
      }

      if (type == this.layerType.ROUTES) {
        this.routeMouseOver(type, feature)
        return
      }
      
      if (type == this.layerType.SYMBOLCLUSTER) {
        const clusteredFeatures = feature.get('features')
        if (clusteredFeatures.length == 1) {   
          this.routeMouseOver(this.layerType.SYMBOLCLUSTER, clusteredFeatures[0])
        }
        return
      }   
    },
    routeMouseOver(type, feature) {
        var risk
        var stopEle
        var stop
        if (type == this.layerType.SYMBOLCLUSTER) {
          risk = feature.get('risk')
          stopEle = feature.get('stopEle')
          stop = feature.get('stop')
        } else {
          var properties = this.symbols.features.find((item) => item.properties.id === feature.get('id')).properties
          risk = Number(properties.risk)
          stopEle = properties.stopEle
          stop = properties.stop
        }

        var title = `${stop} (${stopEle} m)`
        this.updateHeader(title, this.status, risk)      
    },
    setDefaultHeader() {
      const title = this.$vuetify.lang.t(`$vuetify.regions.${this.area}`)
      this.updateHeader(title, this.status, null)
    },
    onClick(e) {
      var features = e.target.getFeatures()
      if (features.getLength() == 0) return
      var feature = features.item(0)
      var type = feature.get('ltype')
      if (typeof type == 'undefined' || type == null) {     
        let layer = this.singleClick.getLayer(feature);
        type = layer.get('ltype')
      }

      if (type == this.layerType.ROUTES) {
        var id = feature.get('id')
        this.dispose()
        this.$router.push({
          name: 'track-view',
          query: { area: this.area, id: id },
        })
        return
      }
      if (type == this.layerType.HOMES) {
        this.home = feature.get('Name')
        // Clears the onMouseOver highlightening
        features.clear()
        return
      }
      if (type == this.layerType.LOCATION) {
        this.displayHelp('homes')
        return
      }
      if (type == this.layerType.SYMBOLCLUSTER) {
        const clusteredFeatures = feature.get('features')
        if (clusteredFeatures.length == 1) {
          id = clusteredFeatures[0].get('id')
          this.dispose()
          this.$router.push({
            name: 'track-view',
            query: { area: this.area, id: id },
          })
          return            
        }
        var extent = this.calcFeaturesExtent(clusteredFeatures, 5)
        this.zoomTo(extent, 8, 16)
        return
      }
    },
    rejectDisclaimer() {
      this.dispose()
      this.$router.push({
        name: 'regions-view',
      })
    },
    acceptDisclaimer() {
      this.agreed = true
    },
    displayHelp(tag) {
      // eslint-disable-next-line no-console
      console.log('AreaView: Display help')
      this.$refs.help.open(tag)
    },
    dispose() {  
      this.singleClick = null
      this.locationVectorLayer = null
      this.disposeMap()
    },
    displayLocationSearchDialog() {
      var extent = defaultExtents_4326[this.area]
      this.$refs.locationSearchDialog.open(extent, this.onLocationSearchHit)
    },

    // eslint-disable-next-line no-unused-vars
    onLocationSearchHit(coordinates) {
      // eslint-disable-next-line no-console
      const googleCoordinates = transform(coordinates, 'EPSG:4326', 'EPSG:3857')
      this.simpleZoomTo(googleCoordinates, 14)
    },
    disableWarning() {
      this.warningEnabled = false
      this.displayHelp('status')
    },
    filterValidRoutes(feature) {
      var id = feature.get('id')
      return (this.idList.indexOf(id) >= 0)
    },
    calcSymbolsExtent(extentMargin) {
      if (this.idList == null) {
        return defaultExtents_3857[this.area]
      }
      var featureCount = 0
      var feature = null
      var extent = [Number.MAX_VALUE, Number.MAX_VALUE, Number.MIN_VALUE, Number.MIN_VALUE]
      for (var i = 0; i < this.symbols.features.length; i++) {
        feature = this.symbols.features[i]
        var id = feature.properties.id
        if (this.idList.indexOf(id) >= 0) {
          featureCount += 1
          var xCenter = feature.geometry.coordinates[0]
          var yCenter = feature.geometry.coordinates[1]
          var deltaExtent = feature.properties.extent
          extent = this.updateExtent(extent, [deltaExtent[0] + xCenter, deltaExtent[1] + yCenter])
          extent = this.updateExtent(extent, [deltaExtent[2] + xCenter, deltaExtent[3] + yCenter])
        }
      }
      if (featureCount == 0) {
        extent = defaultExtents_3857[this.area]
        return extent
      }
      extent = addExtentMargin(extent, extentMargin)
      return extent
    },    
    displayMessage(type, message) {
      // eslint-disable-next-line no-console
      console.log('RatingView: Display help')
      this.$refs.message.open(type, message)
    }, 
  },
  created() {
    this.regions = store.state.region.regions
    this.area = this.$route.query.area ? this.$route.query.area : 'ch'
    this.status = this.regions.features.find(
      (region) => region.properties.region.toLocaleLowerCase() === this.area
    ).properties.status
    this.home = this.$route.query.home ? this.$route.query.home : ''
    this.symbols = store.state[this.area].symbols
    this.skiToursList = this.symbols.features.map((feature) => {
      let obj = {}
      obj.id = feature.properties.id
      obj.start = feature.properties.start
      obj.stop = feature.properties.stop
      obj.startEle = feature.properties.startEle
      obj.ele = feature.properties.ele
      obj.stopEle = feature.properties.stopEle
      obj.diff = feature.properties.diff == 0 ? feature.properties.ad : feature.properties.diff
      obj.snow = feature.properties.snow[0]
      obj.risk = feature.properties.risk
      obj.rt = (typeof feature.properties.rt == 'undefined' || feature.properties.rt == null)? 0:feature.properties.rt
      obj.distance = 0
      return obj
    })
    this.homes = store.state[this.area].homes
    
    this.setDefaultHeader()

    var collection = collections[this.area]
    var server = getMapServerLink();
    var url = `${server}/${collection}_Routes.tms?x={x}&y={y}&z={z}` 
    var routesVectorLayer = this.createVectorTilesLayer( url, this.layerType.ROUTES, this.filterValidRoutes)
    var homesVectorLayer = this.createVectorLayer(this.homes, this.layerType.HOMES, true)
    this.locationVectorLayer = this.createVectorLayer(this.homes, this.layerType.LOCATION, false)
    var symbolsVectorLayer = this.createClusteredSymbolLayer(
      this.adjustSymbolGeometry(this.symbols),
      this.layerType.SYMBOLCLUSTER,
      false
    )

    var rasterBaseLayerList = this.createRasterLayers(this.area, this.areaViewBaseLayers)

    var extent = this.calcSymbolsExtent(this.extentMargin)
    var centerX = (extent[0] + extent[2]) / 2
    var centerY = (extent[1] + extent[3]) / 2

    const size = calculateMapSize()
    const zoom = this.calcZoomLevel(extent, size)

    this.createMap(
      centerX,
      centerY,
      zoom,
      [routesVectorLayer, homesVectorLayer, symbolsVectorLayer, this.locationVectorLayer],
      rasterBaseLayerList,
      null,
      null
    )

    this.singleClick = this.registerSingleClickHandler(this.onClick)
    this.registerMouseOverHandler(this.onMouseOver)
  },
  mounted() {
    this.agreed = this.$refs.disclaimer.open(this.area, this.status, false, this.rejectDisclaimer, this.acceptDisclaimer)

    // var col = getCustomColor('--navigationColor')
    // // eslint-disable-next-line no-console
    // console.log('mounted(): Color is ' + col)

    var startTime = parseTime('17:00:00')
    var stopTime = parseTime('18:15:00')

    var currentTime = new Date()   
    if (currentTime > startTime && currentTime < stopTime  && this.area == 'au') {
      this.displayMessage(1, this.$vuetify.lang.t(`$vuetify.message.texts.12`))
    }

    if (Math.random() < 0.1 && this.area == 'ch') {
      this.displayMessage(0, this.$vuetify.lang.t(`$vuetify.message.texts.13`))
    }    

    this.startRefresher()
  },
  unmounted() {
  },
  beforeRouteEnter(routeTo, routeFrom, next) {
    // inline async function to wait for async dispatch calls
    const getDataAsync = async function (area) {
      await store.dispatch('region/fetchRegions')
      await store.dispatch(`${area}/fetchSymbols`)
      await store.dispatch(`${area}/fetchHomes`)
      //await store.dispatch(`${area}/fetchSegments`)
      //await store.dispatch(`${area}/fetchRoutes`)
      await store.dispatch('news/fetchNews')
    }

    NProgress.start()
    const area = routeTo.query.area ? routeTo.query.area : 'ch'
    getDataAsync(area).then(() => {
      NProgress.done()
      next()
    })
  },
}
</script>

<style scoped>
.map {
  height: 100%;
}
.info {
  max-width: 500px;
}

.locationSearchButton_Desktop {
  background-color: var(--navigationColor);
  color: white !important;
  border-radius: 50%;
  margin-top: 35px !important;
}

.locationSearchButton_Mobile {
  background-color: var(--navigationColor);
  color: white !important;
  border-radius: 50%;
  margin-top: 75px !important;
}
</style>
