import Map from 'ol/Map.js'
import View from 'ol/View.js'
import GeoJSON from 'ol/format/GeoJSON.js'
import GPX from 'ol/format/GPX.js'
import MVT from 'ol/format/MVT.js';
import VectorTileLayer from 'ol/layer/VectorTile.js';
import VectorTileSource from 'ol/source/VectorTile.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js'
import { Cluster, Vector as VectorSource } from 'ol/source.js'
import LayerGroup from 'ol/layer/Group.js'
import XYZ from 'ol/source/XYZ.js'
import Draw from 'ol/interaction/Draw.js';
// eslint-disable-next-line no-unused-vars
import Modify from 'ol/interaction/Modify.js';
import { Circle as CircleStyle, Fill, Stroke, Style, RegularShape, Text } from 'ol/style.js'
//import GeometryType from 'ol/geom/GeometryType.js'
import LineString from 'ol/geom/LineString.js';
import { defaults as defaultControls, Attribution } from 'ol/control'
import Select from 'ol/interaction/Select'
import { singleClick, pointerMove } from 'ol/events/condition'
import { defaultExtents_3857 } from '../variables'

import ImageLayer from 'ol/layer/Image.js';
import Static from 'ol/source/ImageStatic.js';


import 'ol/ol.css'
import '../assets/global.css'
import {
  getMapServerLink,
  calculateMapSize,
  linearFunction,
  getInfoServerLink,
  addExtentMargin,
  log,
  getIsMobile,
  getIsTouch,
  isObjectValid
} from '../utilities'
import { LayerSwitcherControl } from '../olutils.js'
// import 'ol-layerswitcher/src/ol-layerswitcher.css'
import {
  getRegionRgbaColor,
  getRiskRgbaColor,
  getStaticRgbaColor,
  getCssColor,
  getPrimaryRgbaColor,
  getProtectedAreasRgbaColor
} from '../colors.js'
import { ICONS, getIconStyle, getPieIconIndex } from '../icons.js'

export const olMapMixin = {
  inheritAttrs: false,

  data: () => ({
    currZoom: 0,
    zoomClusterThreashold: 11,
    clusterDistance: 60, 
    symbolLayer: null,   
    mapServerLink: getMapServerLink(),
    map: null,
    idList: null,
    layerType: Object.freeze({
      REGIONS: 1,
      SYMBOLCLUSTER: 2,
      ROUTES: 3,
      HOMES: 4,
      SEGMENTS: 5,
      CRUXES: 6,
      WEBCAMS: 7, // Not yet used
      BULLETIN: 8, // Not yet used, may be never used
      ACCIDENTS: 9,
      LOCATION: 10,
      SKIDEPOTS: 11,
      PROTECTEDAREAS: 12,

      // only icons
      SYMBOLS: 13,
      SYMBOLPIES: 14
    }),
    standardIconStyles: null,
    hoverIconStyles: null,

    // @todo: Replace fixed numbers by enums (this.layerType.XXXX): Unexpected keyword this
    vectorLayerProperties: {
      1 /* this.layerType.REGIONS */: {
        minZoom: 0,
        maxZom: 20,
        zIndex: 10
      },
      2 /* this.layerType.SYMBOLCLUSTER */: {
        minZoom: 0,
        maxZom: 20,
        zIndex: 100
      },
      3 /* this.layerType.ROUTES */: {
        minZoom: 11,
        maxZom: 20,
        zIndex: 20
      },
      4 /* this.layerType.HOMES */: {
        minZoom: 10,
        maxZom: 20,
        zIndex: 50
      },
      5 /* this.layerType.SEGMENTS */: {
        minZoom: 10,
        maxZom: 20,
        zIndex: 30
      },
      6 /* this.layerType.CRUXES */: {
        minZoom: 12,
        maxZom: 20,
        zIndex: 90
      },
      7 /* this.layerType.WEBCAMS */: {
        minZoom: 12,
        maxZom: 20,
        zIndex: 40
      },
      8 /* this.layerType.BULLETIN */: {
        minZoom: 0,
        maxZom: 20,
        zIndex: 20
      },
      9 /* this.layerType.ACCIDENTS */: {
        minZoom: 12,
        maxZom: 20,
        zIndex: 40
      },
      10 /* this.layerType.LOCATION */: {
        minZoom: 0,
        maxZoom: 20,
        zIndex: 70
      },
      11 /* this.layerType.SKIDEPOTS */: {
        minZoom: 12,
        maxZom: 20,
        zIndex: 80
      },       
      12 /* this.layerType.PROTECTEDAREAS */: {
        minZoom: 0,
        maxZoom: 20,
        zIndex: 90
      },      
    },

    areaViewBaseLayers: {
      ch: [
        { name: 'CH_ST_TOPO_GROUP', opacity: 0.8 },
        { name: 'ESRI', opacity: 1.0 },
      ],
      au: [
        { name: 'AP_SG_TOPO_MINI', opacity: 0.8},
        { name: 'ESRI', opacity: 1.0 },
      ],
      fr: [
        { name: 'AP_SG_TOPO_MINI', opacity: 0.8 },
        { name: 'ESRI', opacity: 1.0 },
      ],
      ie: [
        { name: 'AP_SG_TOPO_MINI', opacity: 0.8 },
        { name: 'ESRI', opacity: 1.0 },
      ],
      iw: [
        { name: 'AP_SG_TOPO_MINI', opacity: 0.8 },
        { name: 'ESRI', opacity: 1.0 },
      ],
    },

    trackViewBaseLayers: {
      ch: [
        { name: 'CH_ST_TOPO_GROUP', opacity: 0.8 },
        { name: 'CH_ST_ORTO', opacity: 0.8 },
        { name: 'ESRI', opacity: 1.0 },
        { name: 'AP_OTM_TOPO', opacity: 0.8 },
        { name: 'FORUMAPS', opacity: 0.8 },   
        { name: 'AP_SG_TOPO_MINI', opacity: 0.8},            
      ],
      au: [
        { name: 'AP_SG_TOPO_MINI', opacity: 0.8},
        { name: 'AT_BM_ORTO', opacity: 0.8},
        { name: 'ESRI', opacity: 1.0 },           
        { name: 'AP_OTM_TOPO', opacity: 0.8 },
        { name: 'FORUMAPS', opacity: 0.8 },
      ],
      fr: [
        { name: 'AP_SG_TOPO_MINI', opacity: 0.8 },
        { name: 'FR_IGN_ORTO', opacity: 0.8 },  
        { name: 'ESRI', opacity: 1.0 },
        { name: 'AP_OTM_TOPO', opacity: 0.8 },
        { name: 'FORUMAPS', opacity: 0.8 },
      ],
      ie: [
        { name: 'AP_SG_TOPO_MINI', opacity: 0.8},
        { name: 'AP_OTM_TOPO', opacity: 0.8 },
        { name: 'ESRI', opacity: 1.0 },         
        { name: 'FORUMAPS', opacity: 0.8 },
      ],
      iw: [
        { name: 'AP_SG_TOPO_MINI', opacity: 0.8},
        { name: 'AP_OTM_TOPO', opacity: 0.8 },
        { name: 'ESRI', opacity: 1.0 },       
        { name: 'FORUMAPS', opacity: 0.8 },
      ],
      ap: [
        { name: 'AP_SG_TOPO_MINI', opacity: 0.8},
        { name: 'AP_OTM_TOPO', opacity: 0.8 },
        { name: 'CH_ST_TOPO_GROUP', opacity: 0.8 },             
        { name: 'ESRI', opacity: 1.0 },       
        { name: 'FORUMAPS', opacity: 0.8 },   
      ],      
    },

    defaultOverlayLayers: {
      ch: [         
        { name: 'AP_SG_SACM5', opacity: 0.3 },
        { name: 'AP_SG_ATHM', opacity: 0.3 },
        { name: 'CH_SLF_ATH', opacity: 0.5 },
        { name: 'CH_SLF_CAT', opacity: 0.5 },
        { name: 'AP_SG_FDRISK', opacity: 0.4 },       
        { name: 'AP_SG_PA', Opacity: 0.4}, 
        { name: 'CH_SG_SKIMAP', opacity: 0.5 },
        { name: 'CH_ST_SAC_ROUTES', opacity: 0.8 },
        { name: 'OPENSNOWMAP', opacity: 1.0},                  
        { name: 'CH_ST_HIKING_ROUTES', opacity: 0.8 },
        { name: 'CH_TRANSPORT_STOPS', opacity: 1.0 },
        { name: 'AP_EL_SNOWHEIGHT', opacity: 0.6 },
        { name: 'AP_EL_SNOWDIFF_M48', opacity: 0.6 },     
        { name: 'AP_EL_SNOWDIFF_P48', opacity: 0.6 },              
        { name: 'AP_EL_SNOWCOVER', opacity: 0.4 },      
        { name: 'CH_SG_ISO_HUT', opacity: 0.6 },   
      ],
      au: [
        { name: 'AP_SG_SACM5', opacity: 0.3 },
        { name: 'AP_SG_ATHM', opacity: 0.3 },
        { name: 'AP_SG_FDRISK', opacity: 0.4 },      
        { name: 'AP_SG_PA', Opacity: 0.4},      
        { name: 'AP_EL_SNOWHEIGHT', opacity: 0.6 },
        { name: 'AP_EL_SNOWDIFF_M48', opacity: 0.6 },     
        { name: 'AP_EL_SNOWDIFF_P48', opacity: 0.6 },            
        { name: 'AP_EL_SNOWCOVER', opacity: 0.4 },
        { name: 'OPENSNOWMAP', opacity: 1.0}
      ],
      fr: [
        { name: 'AP_SG_SACM5', opacity: 0.3 },
        { name: 'AP_SG_ATHM', opacity: 0.3 },
        { name: 'AP_SG_FDRISK', opacity: 0.4 },  
        { name: 'AP_SG_PA', Opacity: 0.4},          
        { name: 'AP_EL_SNOWHEIGHT', opacity: 0.6 },
        { name: 'AP_EL_SNOWDIFF_M48', opacity: 0.6 },     
        { name: 'AP_EL_SNOWDIFF_P48', opacity: 0.6 },            
        { name: 'AP_EL_SNOWCOVER', opacity: 0.4 },
        { name: 'OPENSNOWMAP', opacity: 1.0}
      ],
      ie: [
        { name: 'AP_SG_SACM5', opacity: 0.3 },
        { name: 'AP_SG_ATHM', opacity: 0.3 },
        { name: 'AP_SG_FDRISK', opacity: 0.4 },         
        { name: 'AP_SG_PA', Opacity: 0.4},   
        { name: 'AP_EL_SNOWHEIGHT', opacity: 0.6 },
        { name: 'AP_EL_SNOWDIFF_M48', opacity: 0.6 },     
        { name: 'AP_EL_SNOWDIFF_P48', opacity: 0.6 },            
        { name: 'AP_EL_SNOWCOVER', opacity: 0.4 },
        { name: 'OPENSNOWMAP', opacity: 1.0}
      ],
      iw: [
        { name: 'AP_SG_SACM5', opacity: 0.3 },
        { name: 'AP_SG_ATHM', opacity: 0.3 },
        { name: 'AP_SG_FDRISK', opacity: 0.4 },            
        { name: 'AP_EL_SNOWHEIGHT', opacity: 0.6 },
        { name: 'AP_EL_SNOWDIFF_M48', opacity: 0.6 },     
        { name: 'AP_EL_SNOWDIFF_P48', opacity: 0.6 },            
        { name: 'AP_EL_SNOWCOVER', opacity: 0.4 },
        { name: 'OPENSNOWMAP', opacity: 1.0}
      ],
      ap: [
        { name: 'AP_SG_SACM5', opacity: 0.3 },
        { name: 'AP_SG_ATHM', opacity: 0.3 },
        { name: 'AP_SG_FDRISK', opacity: 0.4 },            
        { name: 'AP_EL_SNOWHEIGHT', opacity: 0.6 },
        { name: 'AP_EL_SNOWDIFF_M48', opacity: 0.6 },     
        { name: 'AP_EL_SNOWDIFF_P48', opacity: 0.6 },            
        { name: 'AP_EL_SNOWCOVER', opacity: 0.4 },
        { name: 'OPENSNOWMAP', opacity: 1.0}
      ],      
    },

    ratingViewOverlayLayers: {
      ap: [         
        { name: 'AP_SG_SACM5', opacity: 0.3 },
        { name: 'AP_SG_ATHM', opacity: 0.3 },
        { name: 'CH_SLF_ATH', opacity: 0.5 },
        { name: 'CH_SLF_CAT', opacity: 0.5 },
        { name: 'AP_SG_FDRISK', opacity: 0.4 },        
        { name: 'CH_SG_SKIMAP', opacity: 0.5 },
        { name: 'CH_ST_SAC_ROUTES', opacity: 0.8 },
        { name: 'AP_SG_RT', opacity: 0.8 },
        { name: 'AP_SG_PA', Opacity: 0.4},
        { name: 'OPENSNOWMAP', opacity: 1.0},                  
        { name: 'CH_ST_HIKING_ROUTES', opacity: 0.8 },
        { name: 'CH_TRANSPORT_STOPS', opacity: 1.0 },
        { name: 'AP_EL_SNOWHEIGHT', opacity: 0.6 },
        { name: 'AP_EL_SNOWDIFF_M48', opacity: 0.6 },     
        { name: 'AP_EL_SNOWDIFF_P48', opacity: 0.6 },              
        { name: 'AP_EL_SNOWCOVER', opacity: 0.4 },      
        { name: 'CH_SG_ISO_HUT', opacity: 0.6 },   
      ],  
    },  

    rasterLayerProperties: {
      // --------------- Experimental ---------------
      TEST: {
        url: 'http://localhost:51744/ATHM.tms?x={x}&y={y}&z={z}',
        attributions: '<a href="' + getInfoServerLink() + '" target="_blank">Test</a>',
        title: 'Test',
        tag: 1009,
        type: 'overlay',
        minZoom: 0,
        maxZoom: 18,
        opacity: 1.0,
      },

      // --------------- Governamental ---------------
      AT_BM_TOPO: {
        url: 'https://maps3.wien.gv.at/basemap/geolandbasemap/normal/google3857/{z}/{y}/{x}.png',
        attributions: '<a href="https://www.basemap.at/" target="_blank">basemap.at</a>',
        title: 'BaseMap TopoMap (Austria)',
        tag: 1010,
        type: 'base',
        minZoom: 4,
        maxZoom: 19,
        opacity: 1,
      },
      AT_BM_ORTO: {
        url: 'https://maps1.wien.gv.at/basemap/bmaporthofoto30cm/normal/google3857/{z}/{y}/{x}.jpeg',
        attributions: '<a href="https://www.basemap.at/" target="_blank">basemap.at</a>',
        title: 'BaseMap Orthophoto (Austria)',
        tag: 1066,
        type: 'base',
        minZoom: 4,
        maxZoom: 19,
        opacity: 1,
      },
      AT_BEVPLUS_TOPO: {
        url: '$MAP_HOST$/AT_BEVPLUS_TOPO.tms?x={x}&y={y}&z={z}',
        attributions:
          '<a href="http://www.austrianmap.at/amap/index.php" target="_blank">BEV & OTM</a>',
        title: '>BEV/OTM (East Alps)',
        tag: 1011,
        type: 'base',
        minZoom: 7,
        maxZoom: 18,
        opacity: 0.8,
      },
      AT_BEV_TOPO: {
        url: '$MAP_HOST$/AT_BEV_TOPO.tms?x={x}&y={y}&z={z}',
        attributions:
          '<a href="http://www.austrianmap.at/amap/index.php" target="_blank">BEV</a>',
        title: '>BEV (East Alps)',
        tag: 1011,
        type: 'base',
        minZoom: 7,
        maxZoom: 18,
        opacity: 0.8,
      },      
      AT_BKG_TOPO: {
        url: location.protocol + '//w0.oastatic.com/map/v1/raster/topo_bkg/{z}/{x}/{y}/t.png?project=outdooractive',
        attributions: '<a href="https://www.bkg.bund.de" target="_blank">BKG (Demo)</a>',
        title: 'BKG TopoMap (Germany)',
        tag: 1012,
        type: 'base',
        minZoom: 9,
        maxZoom: 17,
        opacity: 0.8,
      },

      // --------------- Commercial ---------------
      AP_BF_TOPO: {
        url: location.protocol + '//maps.bergfex.at/oek/standard/{z}/{x}/{y}.jpg',
        attributions: '<a href="https://www.bergfex.com/" target="_blank">Bergfex (Demo)</a>',
        title: 'Bergfex TopoMap (Austria)',
        tag: 1013,
        type: 'base',
        minZoom: 4,
        maxZoom: 16,
        opacity: 0.8,
      },
      AP_KPS_TOPO: {
        // Kompass doesn't support https
        url:
          'http://ec2.cdn.ecmaps.de/WmsGateway.ashx.jpg?Experience=kompass&MapStyle=KOMPASS%20Touristik&TileX={x}&TileY={y}&ZoomLevel={z}',
        attributions:
          '<a href="http://www.kompass.de/touren-und-regionen/wanderkarte/" target="_blank">Kompass (Demo)</a>',
        title: 'Kompass TopoMap (Alps)',
        tag: 1014,
        type: 'base',
        minZoom: 0,
        maxZoom: 16,
        opacity: 0.9,
      },
      AP_EL_SNOWCOVER: {
        url: 'https://p20.cosmos-project.ch/9Z1AXBIe7qviVTHslHdiIv1box3zUyv1tw9SKO/{z}/{x}/{y}.png',
        attributions: '<a href="https://info.skitourenguru.ch/index.php/exolabs" target="_blank">Exolabs</a>',
        title: 'Exolabs (Snow-Cover)',
        tag: 1047,
        type: 'overlay',
        minZoom: 9,
        maxZoom: 17,
        opacity: 0.6,
      },
      AP_EL_SNOWHEIGHT: {
        url: 'https://p20.cosmos-project.ch/BfOlLXvmGpviW0YojaYiRqsT9NHEYdn88fpHZlr/{z}/{x}/{y}.png',
        attributions: '<a href="https://info.skitourenguru.ch/index.php/exolabs" target="_blank">Exolabs</a>',
        title: 'Exolabs (Snow-Height)',
        tag: 1048,
        type: 'overlay',
        minZoom: 9,
        maxZoom: 17,
        opacity: 0.6,
      },
      AP_EL_SNOWDIFF_M48: {
        url: 'https://p20.cosmos-project.ch/KjcnnPI9Stvn170oHN0it7cPp8penDXcDJTfY/{z}/{x}/{y}.png',
        attributions: '<a href="https://info.skitourenguru.ch/index.php/exolabs" target="_blank">Exolabs</a>',
        title: 'Exolabs (Snow-Diff -48h)',
        tag: 1068,
        type: 'overlay',
        minZoom: 9,
        maxZoom: 17,
        opacity: 0.6,
      },
      AP_EL_SNOWDIFF_P48: {
        url: 'https://p20.cosmos-project.ch/9iL7f3IuOTviAUp63YV5Vm3LG1HDNwCcTWpf/{z}/{x}/{y}.png',
        attributions: '<a href="https://info.skitourenguru.ch/index.php/exolabs" target="_blank">Exolabs</a>',
        title: 'Exolabs (Snow-Diff +48h)',
        tag: 1069,
        type: 'overlay',
        minZoom: 9,
        maxZoom: 17,
        opacity: 0.6,
      },            

      // --------------- Worldwide ---------------
      GH: {
        url: location.protocol + '//mt1.google.com/vt/lyrs=p&x={x}&y={y}&z={z}',
        attributions: '<a href="https://www.google.com" target="_blank">Google</a>',
        title: 'Google Hybrid',
        tag: 1015,
        type: 'base',
        minZoom: 0,
        maxZoom: 18,
        opacity: 0.8,
      },
      ESRI: {
        url:
          location.protocol +
          '//services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
        attributions: '<a href="https://www.esri.com" target="_blank">ESRI</a>',
        title: 'ESRI TopoMap',
        tag: 1016,
        type: 'base',
        minZoom: 0,
        maxZoom: 19,
        opacity: 0.7,
      },
      OSM: {
        url: location.protocol + '//a.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attributions: '<a href="https://www.openstreetmap.org" target="_blank">OSM</a>',
        title: 'OpenStreetMap',
        tag: 1017,
        type: 'base',
        minZoom: 0,
        maxZoom: 19,
        opacity: 1.0,
      },
      OTM: {
        url: location.protocol + '//opentopomap.org/{z}/{x}/{y}.png',
        attributions: '<a href="https://www.opentopomap.org/about" target="_blank">OpenTopoMap</a>',
        title: 'OpenTopoMap (World)',
        tag: 1018,
        type: 'base',
        minZoom: 0,
        maxZoom: 20,
        opacity: 0.4,
      },
      MAP1: {
        url: 'http://beta.map1.eu/tiles/{z}/{x}/{y}.jpg',
        attributions: '<a href="http://www.map1.eu" target="_blank">MAP1</a>',
        title: 'MAP1',
        tag: 1019,
        type: 'base',
        minZoom: 6,
        maxZoom: 15.5,
        opacity: 0.5,
      },
      OCM: {
        url: 'http://a.tile.opencyclemap.org/cycle/{z}/{x}/{y}.png',
        attributions: '<a href="https://www.opencyclemap.org/" target="_blank">OpenCycleMap</a>',
        title: 'OpenCycleMap',
        tag: 1020,
        type: 'base',
        minZoom: 0,
        maxZoom: 18,
        opacity: 0.5,
      },
      OSMAND: {
        url: location.protocol + '//tile.osmand.net/hd/{z}/{x}/{y}.png',
        attributions: '<a href="https://osmand.net/" target="_blank">OSMAnd</a>',
        title: 'OSMAnd',
        tag: 1021,
        type: 'base',
        minZoom: 0,
        maxZoom: 18,
        opacity: 0.7,
      },
      OSMHUM: {
        url: location.protocol + '//a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
        attributions: '<a href="https://www.hotosm.org/" target="_blank">OSM Humanitarian</a>',
        title: 'OSM Humanitarian',
        tag: 1022,
        type: 'base',
        minZoom: 0,
        maxZoom: 18,
        opacity: 0.6,
      },
      WIKIMEDIA: {
        url: location.protocol + '//maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png',
        attributions: '<a href="https://www.wikimedia.org/" target="_blank">Wikimedia</a>',
        title: 'Wikimedia',
        tag: 1023,
        type: 'base',
        minZoom: 0,
        maxZoom: 18,
        opacity: 0.6,
      },
      MM: {
        url: 'http://tiles.mountainmap.net/osm_tiles/{z}/{x}/{y}.png',
        attributions: '<a href="http://mountainmap.net/about " target="_blank">MountainMap</a>',
        title: 'MountainMap',
        tag: 1024,
        type: 'base',
        minZoom: 0,
        maxZoom: 18,
        opacity: 0.8,
      },
      FORUMAPS: {
        url: 'https://www.4umaps.com/{z}/{x}/{y}.png',
        attributions: '<a href="https://www.4umaps.com/ " target="_blank">4UMaps</a>',
        title: '4UMaps',
        tag: 1049,
        type: 'base',
        minZoom: 0,
        maxZoom: 18,
        opacity: 0.8,
      },
      OPENSNOWMAP: {
        url: 'https://tiles.opensnowmap.org/pistes/{z}/{x}/{y}.png',
        attributions: '<a href="https://www.opensnowmap.org" target="_blank">OpenSnowMap</a>',
        title: 'OpenSnowMap',
        tag: 1070,
        type: 'overlay',
        minZoom: 13,
        maxZoom: 18,
        opacity: 0.8,
      },       

      // --------------- IGN ---------------
      FR_IGN_TOPO_KEY: {
        url:
          // Skitourenguru GmbH: Licence 19010 - Usages gratuits des SCAN 25/100/OACI
          'https://wxs.ign.fr/xxxxxxxxxxxxxxxxxxxxxxxx/geoportail/wmts?layer=GEOGRAPHICALGRIDSYSTEMS.MAPS&style=normal&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fjpeg&TileMatrix={z}&TileCol={x}&TileRow={y}',
        attributions:
          '<a href="https://geoservices.ign.fr/services-web-issus-des-scans-ign" target="_blank">IGN</a>',
        title: 'IGN TopoMap (France)',
        type: 'base',
        tag: 1026,
        minZoom: 8,
        maxZoom: 18,
        opacity: 0.8,
      },
      FR_IGN_ORTO: {
        url:
          'https://wxs.ign.fr/essentiels/geoportail/wmts?layer=ORTHOIMAGERY.ORTHOPHOTOS&style=normal&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fjpeg&TileMatrix={z}&TileCol={x}&TileRow={y}',
        attributions:
          '<a href="https://geoservices.ign.fr/services-web-essentiels" target="_blank">IGN</a>',
        title: 'IGN Orthophoto (France)',
        type: 'base',
        tag: 1065,
        minZoom: 8,
        maxZoom: 18,
        opacity: 0.8,
      },      
      FR_IGN_SACM_KEY: {
        url:
          // Public Key: altimetrie
          'https://wxs.ign.fr/altimetrie/geoportail/wmts?layer=GEOGRAPHICALGRIDSYSTEMS.SLOPES.MOUNTAIN&style=normal&tilematrixset=PM&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix={z}&TileCol={x}&TileRow={y}',
        attributions:
          '<a href="https://geoservices.ign.fr/services-web-experts-altimetrie" target="_blank">IGN</a>',
        title: 'IGN Slope (France)',
        type: 'overlay',
        tag: 1027,
        minZoom: 12,
        maxZoom: 18,
        opacity: 0.4,
      },
      FR_IGN_TOPO: {
        url: '$MAP_HOST$/WestAlps_IGN.tms?x={x}&y={y}&z={z}',
        attributions: '<a href="http://www.ign.fr/" target="_blank">IGN</a>',
        title: 'IGN TopoMap (France)',
        tag: 1032,
        type: 'base',
        minZoom: 8,
        maxZoom: 16,
        opacity: 0.8,
      },

      // --------------- Swisstopo ---------------
      CH_ST_TOPO: {
        url: 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe/default/current/3857/{z}/{x}/{y}.jpeg',
        attributions:
          '<a href="https://map.geo.admin.ch" target="_blank">Swisstopo</a>',
        title: 'Swisstopo TopoMap (Switzerland)',
        tag: 1028,
        type: 'base',
        minZoom: 6,
        maxZoom: 19,
        opacity: 1,    
        tilePixelRatio: 1
      },

      CH_ST_ORTO: {
        url: 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg',
        attributions:
          '<a href="https://map.geo.admin.ch" target="_blank">Swisstopo</a>',
        title: 'Swisstopo TopoMap (Switzerland)',
        tag: 1054,
        type: 'base',
        minZoom: 6,
        maxZoom: 20,
        opacity: 1,
      },
      CH_ST_TOPO_GROUP: {
        group: 1,
        names: ['CH_ST_TOPO10K', 'CH_ST_TOPO25K', 'CH_ST_TOPO50K', 'CH_ST_TOPO100K', 'CH_ST_TOPO200K', 'CH_ST_TOPO500K', 'CH_ST_TOPO1000K'],
        title: 'Swisstopo TopoMap (Switzerland)',
        tag: 1028,
        type: 'base',
        minZoom: 6,
        maxZoom: 16,
        opacity: 1,
        attributions:
          '<a href="https://map.geo.admin.ch" target="_blank">Swisstopo</a>',        
      },
      CH_ST_TOPO10K: {
        url: 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swisstlm3d-karte-farbe/default/current/3857/{z}/{x}/{y}.png',
        attributions:
          '<a href="https://www.geocat.ch/geonetwork/srv/ger/md.viewer#/full_view/b353f39c-6349-49e0-97d0-c522fc2fbf0f/tab/complete" target="_blank">Swisstopo</a>',
        title: 'Swisstopo TopoMap (Switzerland)',
        tag: 1055,
        type: 'base',
        minZoom: 15.5,
        maxZoom: 20,
        opacity: 1,
      },                   
      CH_ST_TOPO25K: {
        url: 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe-pk25.noscale/default/current/3857/{z}/{x}/{y}.jpeg',
        attributions:
          '<a href="https://map.geo.admin.ch" target="_blank">Swisstopo</a>',
        title: 'Swisstopo TopoMap (Switzerland)',
        tag: 1028,
        type: 'base',
        minZoom: 14.5,
        maxZoom: 15.5,
        opacity: 1,
      },   
      CH_ST_TOPO50K: {
        url: 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe-pk50.noscale/default/current/3857/{z}/{x}/{y}.jpeg',
        attributions:
          '<a href="https://map.geo.admin.ch" target="_blank">Swisstopo</a>',
        title: 'Swisstopo TopoMap (Switzerland)',
        tag: 1028,
        type: 'base',
        minZoom: 13.5,
        maxZoom: 14.5,
        opacity: 1,
      },
      CH_ST_TOPO100K: {
        url: 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe-pk100.noscale/default/current/3857/{z}/{x}/{y}.jpeg',
        attributions:
          '<a href="https://map.geo.admin.ch" target="_blank">Swisstopo</a>',
        title: 'Swisstopo TopoMap (Switzerland)',
        tag: 1028,
        type: 'base',
        minZoom: 12.5,
        maxZoom: 13.5,
        opacity: 1,
      },
      CH_ST_TOPO200K: {
        url: 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe-pk200.noscale/default/current/3857/{z}/{x}/{y}.jpeg',
        attributions:
          '<a href="https://map.geo.admin.ch" target="_blank">Swisstopo</a>',
        title: 'Swisstopo TopoMap (Switzerland)',
        tag: 1028,
        type: 'base',
        minZoom: 11.5,
        maxZoom: 12.5,
        opacity: 1,
      },
      CH_ST_TOPO500K: {
        url: 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe-pk500.noscale/default/current/3857/{z}/{x}/{y}.jpeg',
        attributions:
          '<a href="https://map.geo.admin.ch" target="_blank">Swisstopo</a>',
        title: 'Swisstopo TopoMap (Switzerland)',
        tag: 1028,
        type: 'base',
        minZoom: 10,
        maxZoom: 11.5,
        opacity: 1,
      },
      CH_ST_TOPO1000K: {
        url: 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe-pk1000.noscale/default/current/3857/{z}/{x}/{y}.jpeg',
        attributions:
          '<a href="https://map.geo.admin.ch" target="_blank">Swisstopo</a>',
        title: 'Swisstopo TopoMap (Switzerland)',
        tag: 1028,
        type: 'base',
        minZoom: 6,
        maxZoom: 10,
        opacity: 1,
      },
      CH_ST_SACM: {
        url: 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.hangneigung-ueber_30/default/current/3857/{z}/{x}/{y}.png',
        attributions:
          '<a href="https://www.geocat.ch/geonetwork/srv/ger/md.viewer#/full_view/77f0637f-8d52-45bc-b824-d6e5719de55b/tab/complete" target="_blank">Swisstopo</a>',
        title: 'Swisstopo Slope (Switzerland)',
        tag: 1029,
        type: 'overlay',
        minZoom: 14,
        maxZoom: 19,
        opacity: 1,
      },
      CH_ST_WILDLIFE_RESERVES: {
        url: 'https://wmts.geo.admin.ch/1.0.0/ch.bafu.wrz-jagdbanngebiete_select/default/current/3857/{z}/{x}/{y}.png',
        attributions:
          '<a href="https://natur-freizeit.ch/snow-sports-and-respect" target="_blank">Bafu</a>',
        title: 'Wildlife reserves (Switzerland)',
        tag: 1050,
        type: 'overlay',
        minZoom: 10,
        maxZoom: 19,
        opacity: 1,        
      },
      CH_ST_WILDLIFE_AREAS: {
        url: 'https://wmts.geo.admin.ch/1.0.0/ch.bafu.wrz-wildruhezonen_portal/default/current/3857/{z}/{x}/{y}.png',
        attributions:
          '<a href="https://natur-freizeit.ch/snow-sports-and-respect" target="_blank">Bafu</a>',
        title: 'Wildlife areas (Switzerland)',
        tag: 1051,
        type: 'overlay',
        minZoom: 10,
        maxZoom: 19,
        opacity: 1,        
      },      
      CH_ST_SAC_ROUTES: {
        url: 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo-karto.skitouren/default/current/3857/{z}/{x}/{y}.png',
        attributions:
          '<a href="https://www.geocat.ch/geonetwork/srv/ger/md.viewer#/full_view/33090bf2-e8e5-4776-9f64-00d7a6170808/tab/complete" target="_blank">Swisstopo</a>',
        title: 'Ski routes (SAC)',
        tag: 1052,
        type: 'overlay',
        minZoom: 10,
        maxZoom: 19,
        opacity: 1,        
      }, 
      CH_ST_HIKING_ROUTES: {
        url: 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swisstlm3d-wanderwege/default/current/3857/{z}/{x}/{y}.png',
        attributions:
          '<a href="https://www.geocat.ch/geonetwork/srv/ger/catalog.search#/metadata/4a064664-a346-4c1a-b424-4e8a0f0b56cb" target="_blank">Swisstopo</a>',
        title: 'Hiking routes (SAC)',
        tag: 1071,
        type: 'overlay',
        minZoom: 10,
        maxZoom: 19,
        opacity: 1,        
      },             
      CH_TRANSPORT_STOPS: {
        url: 'https://wmts.geo.admin.ch/1.0.0/ch.bav.haltestellen-oev/default/current/3857/{z}/{x}/{y}.png',
        attributions:
          '<a href="https://www.geocat.ch/geonetwork/srv/ger/md.viewer#/full_view/841d42ff-8177-4e07-a96b-e8e5455ae048/tab/complete" target="_blank">BAV</a>',
        title: 'Public transport stops',
        tag: 1053,
        type: 'overlay',
        minZoom: 12,
        maxZoom: 19,
        opacity: 1,        
      },     

      // --------------- Skitourenguru BaseMaps ---------------
      CH_TMC_TOPO: {
        url: '$MAP_HOST$/Switzerland.tms?x={x}&y={y}&z={z}',
        attributions: '<a href="' + getInfoServerLink() + '/index.php/tmc" target="_blank">TopoMapCreator</a>',
        title: 'TMC TopoMap (Switzerland)',
        tag: 1030,
        type: 'base',
        minZoom: 9,
        maxZoom: 15,
        opacity: 0.8,
      },
      AP_OTM_TOPO: {
        url: '$MAP_HOST$/AP_SG_TOPO_OTM.tms?x={x}&y={y}&z={z}',
        attributions: '<a href="https://www.opentopomap.org/about" target="_blank">OpenTopoMap</a>',
        title: 'OpenTopoMap (Alps)',
        tag: 1031,
        type: 'base',
        minZoom: 4,
        maxZoom: 18,
        opacity: 0.8,
      },
      AP_SG_TOPO_MINI: {
        url: '$MAP_HOST$/AP_SG_TOPO_MINI.tms?x={x}&y={y}&z={z}',
        attributions: '<a href="' + getInfoServerLink() + '/index.php/mixtopo-de" target="_blank">OTM/Swisstopo/BEV/LDBV</a>',
        title: 'OTM/Swisstopo/BEV/LDBV',
        tag: 1067,
        type: 'base',
        minZoom: 4,
        maxZoom: 18,
        opacity: 0.8,
      },         
      IE_IGM_TOPO: {
        url: '$MAP_HOST$/IE_IGM_TOPO.tms?x={x}&y={y}&z={z}',
        attributions: '<a href="https://www.igmi.org/" target="_blank">IGM</a>',
        title: 'IGM TopoMap (NE-Italy)',
        tag: 1033,
        type: 'base',
        minZoom: 4,
        maxZoom: 16,
        opacity: 0.8,
      },
      IW_IGM_TOPO: {
        url: '$MAP_HOST$/IW_IGM_TOPO.tms?x={x}&y={y}&z={z}',
        attributions: '<a href="https://www.igmi.org/" target="_blank">IGM</a>',
        title: 'IGM TopoMap (NW-Italy)',
        tag: 1034,
        type: 'base',
        minZoom: 4,
        maxZoom: 16,
        opacity: 0.8,
      },

      // --------------- Skitourenguru Overlays ---------------
      CH_SG_ISO_HUT: {
        url: '$MAP_HOST$/CH_SG_ISO_HUT.tms?x={x}&y={y}&z={z}',
        attributions: '<a href="' + getInfoServerLink() + '/index.php/isolation" target="_blank">A. Eisenhut</a>',
        title: 'Slope (Switzerland)',
        tag: 1060,
        type: 'overlay',
        minZoom: 8,
        maxZoom: 16,
        opacity: 0.6,
      },
      CH_SG_CORRIDORS: {
        url: '$MAP_HOST$/CH_SG_CORRIDORS.tms?x={x}&y={y}&z={z}',
        attributions: '<a href="' + getInfoServerLink() + '/index.php/corridors" target="_blank">Skitourenguru</a>',
        title: 'Corridors (Switzerland)',
        tag: 1045,
        type: 'overlay',
        minZoom: 10,
        maxZoom: 18,
        opacity: 0.6,
      },
      CH_SG_SKIMAP: {
        url: '$MAP_HOST$/CH_SG_SKIMAP.tms?x={x}&y={y}&z={z}',
        attributions: '<a href="' + getInfoServerLink() + '/index.php/corridors" target="_blank">Skitourenguru</a>',
        title: 'SkiMap (Switzerland)',
        tag: 1046,
        type: 'overlay',
        minZoom: 10,
        maxZoom: 18,
        opacity: 0.3,
      },
      AP_SG_RT: {
        url: '$MAP_HOST$/AP_SG_RT.tms?x={x}&y={y}&z={z}',
        attributions: '<a href="' + getInfoServerLink() + '/index.php/corridors" target="_blank">Skitourenguru</a>',
        title: 'Skitourenguru Routes (Alps)',
        tag: 1073,
        type: 'overlay',
        minZoom: 9,
        maxZoom: 16,
        opacity: 0.8,
      },        
      AP_SG_PA: {
        url: '$MAP_HOST$/AP_SG_PA.tms?x={x}&y={y}&z={z}',
        attributions: '<a href="' + getInfoServerLink() + '/index.php/protareas-de" target="_blank">bafu.admin.ch, digitizetheplanet.org, biodiv-sports.fr</a>',
        title: 'Protected Areas (Alps)',
        tag: 1061,
        type: 'overlay',
        minZoom: 9,
        maxZoom: 16,
        opacity: 0.8,
      },  
      AP_SG_ATHM: {
        url: '$MAP_HOST$/AP_SG_ATHM.tms?x={x}&y={y}&z={z}',
        attributions:
          '<a href="' + getInfoServerLink() + '/index.php/athm-de" target="_blank">Skitourenguru</a>',
        title: 'ATHM (Alps)',
        tag: 1062,
        type: 'overlay',
        minZoom: 14,
        maxZoom: 18,
        opacity: 0.3,
      },
      AP_SG_SACM5: {
        url: '$MAP_HOST$/AP_SG_SACM5.tms?x={x}&y={y}&z={z}',
        attributions: '<a href="' + getInfoServerLink() + '/index.php/slope-de" target="_blank">Skitourenguru</a>',
        title: 'Slope (Alps)',
        tag: 1063,
        type: 'overlay',
        minZoom: 14,
        maxZoom: 18,
        opacity: 0.3,
      },
      AP_SG_SACM10: {
        url: '$MAP_HOST$/AP_SG_SACM10.tms?x={x}&y={y}&z={z}',
        attributions: '<a href="' + getInfoServerLink() + '/index.php/slope-de" target="_blank">Skitourenguru</a>',
        title: 'Slope (Alps)',
        tag: 1063,
        type: 'overlay',
        minZoom: 14,
        maxZoom: 18,
        opacity: 0.3,
      },      
      AP_SG_FDRISK: {
        url: '$MAP_HOST$/AP_SG_FDRISK.tms?x={x}&y={y}&z={z}',
        attributions: '<a href="' + getInfoServerLink() + '/index.php/fdrisk" target="_blank">Skitourenguru</a>',
        title: 'Falldown risk (Alps)',
        tag: 1064,
        type: 'overlay',
        minZoom: 14,
        maxZoom: 18,
        opacity: 0.3,
      },
      

      // --------------- SLF ---------------
      CH_SLF_ATH: {
        url: 'https://map.slf.ch/public/mapcache/wmts?app=guru&layer=ch.slf.terrainclassification-hom&style=default&tilematrixset=GoogleMapsCompatible&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image/png; mode=8bit&TileMatrix={z}&TileCol={x}&TileRow={y}',
        attributions:
          '<a href="https://content.whiterisk.ch/help/kartenmaterial/lawinengelaendekarten" target="_blank">SLF</a>',
        title: 'ATH (Switzerland)',
        tag: 1057,
        type: 'overlay',
        minZoom: 14,
        maxZoom: 18,
        opacity: 0.5,
      },
      CH_SLF_CAT: {
        url: 'https://map.slf.ch/public/mapcache/wmts?app=guru&layer=ch.slf.terrainclassification-hybr&style=default&tilematrixset=GoogleMapsCompatible&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image/png; mode=8bit&TileMatrix={z}&TileCol={x}&TileRow={y}',
        attributions:
          '<a href="https://content.whiterisk.ch/help/kartenmaterial/lawinengelaendekarten" target="_blank">SLF</a>',
        title: 'CAT (Switzerland)',
        tag: 1058,
        type: 'overlay',
        minZoom: 14,
        maxZoom: 18,
        opacity: 0.5,
      },      

      
    },
  }),
  methods: {
    // --- Public Methods ----

    createMap(centerX, centerY, zoom, vectorLayers, baseRasterLayers, overlayRasterLayers, onLayerSwitcher) {    
      var layers = []

      // Care about base map rasters
      for (var i = 0; i < baseRasterLayers.length; i++) {
        baseRasterLayers[i].setVisible(i==baseRasterLayers.length-1)
      }
      var baseGroup = new LayerGroup({
        title: this.$vuetify.lang.t(`$vuetify.layerGroups.base`),
        fold: 'close',
        layers: baseRasterLayers,
        base: true
      })
      layers.push(baseGroup)

      // Care about overlay map rasters
      if (typeof overlayRasterLayers != 'undefined' && overlayRasterLayers != null) {
        var overlayGroup = new LayerGroup({
          title: this.$vuetify.lang.t(`$vuetify.layerGroups.overlay`),
          fold: 'open',
          layers: overlayRasterLayers,
          base: false
        })
        layers.push(overlayGroup)
      }

      // Care about vector layers
      var vectorGroup = new LayerGroup({
        title: this.$vuetify.lang.t(`$vuetify.layerGroups.objects`),
        fold: 'close',
        layers: vectorLayers,
        base: false
      })
      layers.push(vectorGroup)

      this.map = new Map({
        layers: layers,
        view: new View({
          center: [centerX, centerY],
          zoom: zoom,
          minZoom: 4,
          maxZoom: 18,
          enableRotation: false,
        }),

        controls: defaultControls({ attribution: false }).extend([
          new Attribution({
            label: 'C', //'©',
            collapseLabel: '\u2715', //'\u2A2F'
          }),
        ]),
      })

      var that = this

      // Change mouse cursor when over marker
      this.map.on('pointermove', function (e) {
        if (e.dragging) {
          return
        }
        if (typeof that.map == 'undefined' || that.map == null) {
          return
        }
        var pixel = that.map.getEventPixel(e.originalEvent)
        var hit = that.map.hasFeatureAtPixel(pixel)
        that.map.getTargetElement().style.cursor = hit ? 'pointer' : ''
      })

      // Care about layer switcher
      if (typeof onLayerSwitcher != 'undefined' && onLayerSwitcher != null) {
        this.map.addControl(new LayerSwitcherControl(onLayerSwitcher, null))
      }

      // Handle zoom events
      this.symbolLayer = this.getLayer(this.layerType.SYMBOLCLUSTER)
      if (typeof this.symbolLayer != 'undefined' && this.symbolLayer != null) {
        this.updateClusterDistance()
        this.map.on('moveend', function() {
          that.updateClusterDistance()

        });
      }
    },

    updateClusterDistance() {
      if (typeof this.map == 'undefined' || this.map == null) {
        return
      }
      var newZoom = this.map.getView().getZoom();
      if (this.currZoom == newZoom) {   
        return
      }  
      var changeDistance = (this.currZoom > this.zoomClusterThreashold) != (newZoom > this.zoomClusterThreashold)
      this.currZoom = newZoom
      if (changeDistance) {
        log('updateClusterDistance(): Zoom = ' + this.currZoom)
        var symbolsSource = this.symbolLayer.getSource().getSource()
        // Updating of "distance" only works, if a new Cluster is allocated. 
        var vectorSource = new Cluster({
          distance: this.currZoom > this.zoomClusterThreashold? 0:this.clusterDistance,
          minDistance: 0,
          source: symbolsSource,
        });
        //this.symbolLayer.getSource().getSource().clear()
        this.symbolLayer.getSource().clear()
        this.symbolLayer.setSource(vectorSource)
      }  
    },

    addVectorLayer(vectorLayer, afterType) {
      var groups = this.map.getLayers()
      for (var j = 0; j < groups.getLength(); j++) {
        var group = groups.item(j)
        var layers = group.getLayers()
        for (var i = 0; i < layers.getLength(); i++) {
          var layer = layers.item(i)
          if (layer.get('ltype') == afterType) {
            layers.insertAt(i+1, vectorLayer)
            group.setLayers(layers)
            return
          }
        }
      }
    },
    removeVectorLayer(type) {
      var groups = this.map.getLayers()
      for (var j = 0; j < groups.getLength(); j++) {
        var group = groups.item(j)
        var layers = group.getLayers()
        for (var i = 0; i < layers.getLength(); i++) {
          var layer = layers.item(i)
          if (layer.get('ltype') == type) {
            layers.removeAt(i)
            group.setLayers(layers)
            layer.getSource().clear()
            layer.setSource(null)
            return
          }
        }
      }
    },
    addImageLayer(tag, url, epsg, extent, attributions) {      
      var raster = new ImageLayer({
        source: new Static({
          attributions: attributions,
          url: url,
          projection: 'EPSG:' + epsg,
          imageExtent: extent,
        }),
        opacity: 0.7,
        title: this.$vuetify.lang.t(`$vuetify.rasterLayers.${tag}`),
        attributions: attributions,
        info: this.parseInfo(attributions),
      })  

      var groupTitle = this.$vuetify.lang.t(`$vuetify.layerGroups.overlay`)
      
      var groups = this.map.getLayers()
      for (var j = 0; j < groups.getLength(); j++) {
        var group = groups.item(j)
        if (group.get('title') == groupTitle) {
          var layers = group.getLayers()
          layers.push(raster)
          group.setLayers(layers)
        }
      }      
    },
    // eslint-disable-next-line no-unused-vars
    removeImageLayer(tag) {
      var title = this.$vuetify.lang.t(`$vuetify.rasterLayers.${tag}`)
      var groups = this.map.getLayers()
      for (var j = 0; j < groups.getLength(); j++) {
        var group = groups.item(j)
        var layers = group.getLayers()
        for (var i = 0; i < layers.getLength(); i++) {
          var layer = layers.item(i)
          if (layer.get('title') == title) {
            layers.removeAt(i)
            group.setLayers(layers)
            layer.setSource(null)
            return
          }
        }
      }
    }, 
    createRasterLayers(area, defaultLayers) {
      const rasterList = []
      const areaDefaultLayers = defaultLayers[area]

      for (var i = areaDefaultLayers.length-1; i >=0 ; i--) {
        var defaultLayer = areaDefaultLayers[i]
        const raster = this.createRasterLayer(defaultLayer.name, defaultLayer.opacity)
        raster.setVisible(i == 0)
        rasterList.push(raster)
      }
      return rasterList
    },

    createRasterLayer(mapName, opacity) {
      var rasterLayerProperties = this.getRasterLayerProperties(mapName)
      if (typeof rasterLayerProperties.group == 'undefined' || rasterLayerProperties.group == null || rasterLayerProperties.group == 0) {
        // Its a normal classical layer
        return this.createRasterSingleLayer(rasterLayerProperties, opacity)
      }

      // The layer is a group of layers: Applies only to CH_ST_TOPO_GROUP
      var groupLayers = [];
      for (var i = 0; i < rasterLayerProperties.names.length; i++) {
        const singleMapName = rasterLayerProperties.names[i]
        var singleRasterLayerProperties = this.getRasterLayerProperties(singleMapName)
        var layer = this.createRasterSingleLayer(singleRasterLayerProperties, opacity)
        layer.setVisible(true)
        groupLayers.push(layer)
      }

      var attributions = '&copy; ' + this.$vuetify.lang.t(`$vuetify.tabs.map`) + ' ' + rasterLayerProperties.attributions

      var baseGroup = new LayerGroup({
        title: this.$vuetify.lang.t(`$vuetify.rasterLayers.${rasterLayerProperties.tag}`),
        fold: 'open',
        layers: groupLayers,
        info: this.parseInfo(attributions),
      })
      return baseGroup;
    },

    createRasterSingleLayer(rasterLayerProperties, opacity) {
      if (typeof opacity == 'undefined' || opacity == null) {
        opacity = rasterLayerProperties.opacity
      }
      var url = rasterLayerProperties.url
      url = url.replace('$MAP_HOST$', this.mapServerLink)

      var attributions = '&copy; ' + this.$vuetify.lang.t(`$vuetify.tabs.map`) + ' ' + rasterLayerProperties.attributions
      var source = new XYZ({attributions: attributions, url: url})
      var raster = new TileLayer({
        title: this.$vuetify.lang.t(`$vuetify.rasterLayers.${rasterLayerProperties.tag}`),
        type: '', //rasterLayerProperties.type,
        minZoom: rasterLayerProperties.minZoom,
        maxZoom: rasterLayerProperties.maxZoom,
        source: source,
        opacity: opacity,
        attributions: attributions,
        info: this.parseInfo(attributions),
      })
      return raster
    },
    featuresToGpx(features) {
      var str = new GPX().writeFeatures(features, {
        dataProjection:'EPSG:4326',
        featureProjection:'EPSG:3857'
      });
      return str
    },
    getFeatureFromGpx(data) {
      var features = new GPX().readFeatures(data, {
        dataProjection:'EPSG:4326',
        featureProjection:'EPSG:3857'
      });
      var feature = this.takeFirstLineFeature(features)
      if (feature == null) return null 
      var style = new Style({
        stroke: new Stroke({
          color: [0, 0, 0, 1],
          width: 4,
        }),
      })
      feature.setStyle(style)
      return feature
    },
    takeFirstLineFeature(features) {
      for (let i = 0; i < features.length; i++) {
        var geometry = features[i].get('geometry')
        var type = geometry.getType()
        if (type == 'LineString' || type == 'MultiLineString') {
          return features[i]
        }
      }
      return null
    },
    getLineStringGeometry(feature, cutGpx) {
      var geometry = feature.get('geometry')
      var coordinates = geometry.getCoordinates()
      if (typeof coordinates == 'undefined' || coordinates == null) {
        return null
      }
      var type = geometry.getType()
      if (type == 'LineString') {
        if (!cutGpx) return geometry
        return this.filterMaxLineString(geometry)
      }
      if (type == 'MultiLineString') {
        // Take first LineString of MultiLineString
        coordinates = coordinates[0]
        if (typeof coordinates == 'undefined' || coordinates == null) {
          return null
        }
        geometry = new LineString(coordinates)
        if (!cutGpx) return geometry
        return this.filterMaxLineString(geometry)
      }
      return null
    },
    filterMaxLineString(geometry) {
      var maxDist = 0
      var maxIndex
      var i      
      const startCoordinate = geometry.getFirstCoordinate()
      const coordinates = geometry.getCoordinates()

      for (i = 1; i < coordinates.length; i++) {
        var dist = this.calcHorizontalDistance(startCoordinate, coordinates[i])
        if (dist > maxDist) {
          maxDist = dist
          maxIndex = i
        }
      }        
      maxIndex = this.searchSummit(coordinates, maxIndex)
      if (maxIndex == 0) {
        // Take all
        maxIndex = coordinates.length -1 
      }
      
      var filteredCoordinates = []
      for (i = 0; i < maxIndex; i++) {
        filteredCoordinates.push(coordinates[i].slice())
      }
      var filteredgeometry = new LineString(filteredCoordinates)

      var filteredLength = filteredgeometry.getLength()
      var totalLength = geometry.getLength()
      if (filteredLength < totalLength/10) {
        return geometry
      }

      return filteredgeometry
    },
    calcHorizontalDistance(coordinate1, corrdinate2) {
      const xDiff = corrdinate2[0]-coordinate1[0]
      const yDiff = corrdinate2[1]-coordinate1[1]
      return Math.sqrt(xDiff*xDiff + yDiff*yDiff)
    },
    searchSummit(coordinates, maxIndex) {  
      var i 
      var ele     
      var summitIndex = maxIndex 
      var maxEle = coordinates[maxIndex][2]

      if (!isObjectValid(maxEle)) return {
        // There is no elevation
        maxIndex
      }

      for (i = 0; i < coordinates.length; i++) {
        ele = coordinates[i][2]
        if (isObjectValid(ele) && ele > maxEle) {
          maxEle = ele
          summitIndex = i
        }
      }  
      return summitIndex
    },
    getFilteredFeatures(clusteredFeatures) {
      if ((this.idList == null) || (typeof this.idList == 'undefined')) {
        return clusteredFeatures
      }
      var filteredClusteredFeatures = []
      for (var i=0; i < clusteredFeatures.length; i++) {
        var id = this.getFeatureProperty(clusteredFeatures[i], 'id')
        if (this.idList.indexOf(id) >= 0) {
          filteredClusteredFeatures.push(clusteredFeatures[i])
        }
      }
      return filteredClusteredFeatures
    },

    clusteredSymbolStylingFunction (feature) {
      feature.set('ltype', this.layerType.SYMBOLCLUSTER)
      return this.getFinalStyle(this.layerType.SYMBOLCLUSTER, feature, true)
    },        
    createClusteredSymbolLayer(data, type, visible) {
      this.initIcons()

      var localVisible = visible
      if (typeof localVisible == 'undefined' || localVisible == null) {
        localVisible = true
      }

      var properties = this.vectorLayerProperties[type]
      var features = new GeoJSON().readFeatures(data)
      var vectorSource = new VectorSource({
        features: features,
      })   
      
      for(var i=0; i < features.length; i++) {
        var geometry = features[i].getGeometry()
        var coordinates = geometry.getCoordinates()

        // Make sure there are no two equal coordinates: A requirement for good clustering
        let delta = Math.random() - 0.5
        coordinates = [coordinates[0] + delta, coordinates[1] + delta]
        geometry.setCoordinates(coordinates)  
      }

      vectorSource = new Cluster({
        distance: this.clusterDistance,
        minDistance: 0,
        source: vectorSource,
      });

      var that = this
      var vector = new VectorLayer({
        title: this.$vuetify.lang.t(`$vuetify.vectorLayers.${type}`),
        source: vectorSource,
        minZoom: properties.minZoom,
        maxZoom: properties.maxZoom,
        visible: localVisible,
        zIndex: properties.zIndex,
        style: function (feature) {
          return that.clusteredSymbolStylingFunction(feature)
        }
      })

      vector.set('ltype', type)
      return vector      
    },
    vectorTilesStylingFunction (type, feature) {
      return this.getFinalStyle(type, feature, true)
    },     
    createVectorTilesLayer(url, type, filterFeatureFctn, visible) {
      var localVisible = visible
      if (typeof localVisible == 'undefined' || localVisible == null) {
        localVisible = true
      }

      var properties = this.vectorLayerProperties[type] 

      const vectorSource = new VectorTileSource({
        attributions: '@ Skitourenguru',
        format: new MVT(),
        url: url
      })
      
      var that = this
      var vector = new VectorTileLayer({
        // declutter: true, // Decluttering is used to avoid overlapping labels
        title: this.$vuetify.lang.t(`$vuetify.vectorLayers.${type}`),
        minZoom: properties.minZoom,
        maxZoom: properties.maxZoom,
        visible: localVisible,
        source: vectorSource,
        opacity: 1,
        zIndex: properties.zIndex,

        // Problems with MouseOver:
        // https://stackoverflow.com/questions/33322722/cannot-set-a-style-for-clicked-features-in-vector-layer
        style: function (feature) {
          var display = filterFeatureFctn == null ? true : filterFeatureFctn(feature)
          if (!display) {
            var noStyle = new Style()
            noStyle.display = false  
            return noStyle
          }          
          return that.vectorTilesStylingFunction(type, feature)
        }
      });

      vector.set('ltype', type)
      return vector 
    },
    createVectorLayer(data, type, visible) {
      this.initIcons()

      var localVisible = visible
      if (typeof localVisible == 'undefined' || localVisible == null) {
        localVisible = true
      }

      var properties = this.vectorLayerProperties[type]

      var vectorSource = new VectorSource({
        features: new GeoJSON().readFeatures(data),
      })

      var features = vectorSource.getFeatures()
      for (var i = 0; i < features.length; i++) {
        features[i].set('ltype', type)
        var myStyle = this.getFinalStyle(type, features[i], true)
        features[i].setStyle(myStyle)
      }

      var vector = new VectorLayer({
        title: this.$vuetify.lang.t(`$vuetify.vectorLayers.${type}`),
        source: vectorSource,
        minZoom: properties.minZoom,
        maxZoom: properties.maxZoom,
        visible: localVisible,
        zIndex: properties.zIndex,
      })

      vector.set('ltype', type)
      return vector
    },

    createEmptyVectorLayer(type, visible) {
      this.initIcons()

      var localVisible = visible
      if (typeof localVisible == 'undefined' || localVisible == null) {
        localVisible = true
      }

      var properties = this.vectorLayerProperties[type]
      var vectorSource = new VectorSource({wrapX: false});

      var vector = new VectorLayer({
        title: this.$vuetify.lang.t(`$vuetify.vectorLayers.${type}`),
        source: vectorSource,
        minZoom: properties.minZoom,
        maxZoom: properties.maxZoom,
        visible: localVisible,
      })

      vector.set('ltype', type)
      return vector
    },
    registerSingleClickHandler(onFeatureClick) {
      var that = this
      var singleClickSelect = new Select({
        layers: function (layer) {
          var type = layer.get('ltype')
          return type != that.layerType.SEGMENTS
        },
        condition: singleClick,
        hitTolerance: 6,
        style: null,
      })
      this.map.addInteraction(singleClickSelect)
      singleClickSelect.on('select', onFeatureClick)
      return singleClickSelect
    },
    registerDrawHandler(onDrawStart, onDrawEnd, manualVector) {

      const type = manualVector.get('ltype')

      // Create a style function for select/modify
      const hooverStyleFunction = (function () {
        const styles = {};
     
        // The selected line will become transparent
        styles['LineString'] = [
          new Style({
            stroke: new Stroke({
              color: [0, 0, 0, 0.5],
              width: 4,
            }),
          }),
        ];

        styles['MultiLineString'] = styles['LineString']
      
        // The point for new points (Alt-Click to delete a point)
        styles['Point'] = [
          new Style({
            image: new CircleStyle({
              radius: 7,
              fill: new Fill({
                color: [0, 0, 0, 0.5],
              })
            }),
            zIndex: 100000,
          }),
        ];
      
        return function (feature) {
          return styles[feature.getGeometry().getType()];
        };
      })();
      
      // Add Draw Interaction
      var draw = new Draw({
        source: manualVector.getSource(),
        snapTolerance: getIsMobile() || getIsTouch()? 10:2,
        type: 'LineString',
        style: [new Style({
          image: new RegularShape({
            stroke: new Stroke({
              color: getPrimaryRgbaColor(),
              width: 1,
            }),
            points: 4,
            radius: 12,
            radius2: 0,
            angle: Math.PI / 4,
          }),
        }),
        new Style({
          image: new CircleStyle({
            radius: 12,
            stroke: new Stroke({
              color: getPrimaryRgbaColor(),
              width: 4,
            }),
          }),
        })]
      })
      var that = this
      draw.on('drawstart', function (event) {
        manualVector.getSource().clear();

        const feature = event.feature;
        feature.set('ltype', type)
        var myStyle = that.getFinalStyle(type, feature, true)
        feature.setStyle(myStyle)   

        onDrawStart(event);
      })
      draw.on('drawend', function (event) {
        onDrawEnd(event);
      })
      this.map.addInteraction(draw);

      // Add Select interaction
      var selectOptions = {
        layers: function (layer) {
          var type = layer.get('ltype')
          return type == that.layerType.ROUTES
        },
        condition: pointerMove,
        hitTolerance: 50,
        style: hooverStyleFunction,
      }
      var select = new Select(selectOptions)
      this.map.addInteraction(select)

      // Add Modify interaction
      const modify = new Modify({
        features: select.getFeatures(),
        style: hooverStyleFunction,
      });

      this.map.addInteraction(modify)
    },
    registerMouseOverHandler(onFeaturePointerMove) {
      var that = this
      var selectOptions = {
        layers: function (layer) {
          var type = layer.get('ltype')
          return (type != that.layerType.SEGMENTS) && (type != that.layerType.ROUTES) && (type != that.layerType.PROTECTEDAREAS)
        },
        condition: pointerMove,
        hitTolerance: 6,
        style: this.getDefaultStyleFunction(),
      }

      var pointerMoveSelect = new Select(selectOptions)
      this.map.addInteraction(pointerMoveSelect)
      pointerMoveSelect.on('select', onFeaturePointerMove)
    },

    adjustSymbolGeometry(symbols) {
      var len = symbols.features.length
      for (var i = 0; i < len; i++) {
        var feature = symbols.features[i]
        var coordinates = feature.geometry.coordinates

        if ((feature.geometry.type == 'MultiPoint') && (coordinates.length == 3)) {
          // Delete start coordinates
          coordinates.splice(0, 1)

          // Delete stop coordinates
          coordinates.splice(-1, 1)
        }
      }
      return symbols
    },
    setVectorLayerVisible(type, visible) {
      this.getLayer(type).setVisible(visible)
    },
    zoomTo(extent, minZoom, maxZoom) {
      if (typeof extent == 'undefined' || extent == null) return false
      if (extent[2] <= extent[0] || extent[3] <= extent[1]) return false

      // Be careful with getSize(): The function often returns 'undefined' or wrong values
      // var size = this.map.getSize()

      var size = calculateMapSize()
      // eslint-disable-next-line no-console
      console.log('zoomTo(): Calculated size ' + size[0] + ' * ' + size[1])

      let zoom = this.calcZoomLevel(extent, size, minZoom, maxZoom)
      this.map.getView().setZoom(zoom)
      var xCenter = (extent[2] + extent[0]) / 2

      // Forces a rendering clusters if center/zoom doesn't change
      xCenter += (Math.random() + 0.5)

      const yCenter = (extent[3] + extent[1]) / 2
      this.map.getView().setCenter([xCenter, yCenter])



      // Doesn't work on all browsers
      // this.map.getView().fit(extent, size)
      return true
    },
    simpleZoomTo(coordinates, zoom) {
      this.map.getView().setZoom(zoom)
      this.map.getView().setCenter(coordinates)
    },
    calcZoomLevel(extent, size, minZoom, maxZoom) {
      const zoomX = this.calcZoomLevelOneDimensional(Math.abs(extent[2] - extent[0]), size[0])
      const zoomY = this.calcZoomLevelOneDimensional(Math.abs(extent[3] - extent[1]), size[1])
      var zoom = Math.min(zoomX, zoomY)

      // Check limits, if they are present
      if (typeof minZoom != 'undefined' && minZoom != null) {
        zoom = Math.max(zoom, minZoom)
      }
      if (typeof maxZoom != 'undefined' && maxZoom != null) {
        zoom = Math.min(zoom, maxZoom)
      }
      return zoom
    },
    calcZoomLevelOneDimensional(deltaMeter, deltaPixel) {
      const pixelSize = deltaMeter / deltaPixel
      const zoom = Math.log((6378137 * Math.PI) / (pixelSize * 256)) / Math.log(2) + 1
      return zoom
    },
    calcFeaturesExtent(features, extentMargin) {
      var extent = [Number.MAX_VALUE, Number.MAX_VALUE, Number.MIN_VALUE, Number.MIN_VALUE]
      for (var i = 0; i < features.length; i++) {
        var geometry = features[i].get('geometry')
        var geometryExtent = geometry.getExtent()
        extent = this.updateExtent(extent, [geometryExtent[0], geometryExtent[1]])
        extent = this.updateExtent(extent, [geometryExtent[2], geometryExtent[3]])
      }
      extent = addExtentMargin(extent, extentMargin)
      return extent
    },    

    filterVectorFeature(feature, type, filterFeatureFctn, extent) {
      var noStyle = new Style()
      noStyle.display = false      
      var featureCount = 0
      var display = filterFeatureFctn == null ? true : filterFeatureFctn(feature)
      if (display) {
        var myStyle = this.getFinalStyle(type, feature, true)
        feature.setStyle(myStyle)
        var geometry = feature.get('geometry')
        var geometryExtent = geometry.getExtent()

        extent = this.updateExtent(extent, [geometryExtent[0], geometryExtent[1]])
        extent = this.updateExtent(extent, [geometryExtent[2], geometryExtent[3]])

        featureCount = 1
      } else {
        feature.setStyle(noStyle)
      }
      return { extent: extent, count: featureCount }
    },

    filterVectorLayer(vectorLayer, type, filterFeatureFctn, extentMargin, area) {
      var featureCount = 0

      var features = vectorLayer.getSource().getFeatures()
      var extent = [Number.MAX_VALUE, Number.MAX_VALUE, Number.MIN_VALUE, Number.MIN_VALUE]

      for (var i = 0; i < features.length; i++) {
        var result = this.filterVectorFeature(features[i], type, filterFeatureFctn, extent)
        featureCount += result.count
        extent = result.extent
      }

      if (featureCount == 0) {
        extent = defaultExtents_3857[area]
      } else {
        extent = addExtentMargin(extent, extentMargin)
      }
      return { extent: extent, count: featureCount }
    },
    getRegionStatusText(status, on) {
      const text = this.$vuetify.lang.t(`$vuetify.regionStatus.${status}.short`)
      if (on && status == 1) return ''
      return text
    },

    // --- Private Methods ----
    parseInfo(attributionString) {
      if (typeof attributionString == 'undefined' || attributionString == null) {
        return null
      }
      try {
          //const myRegex = /href="(.*)"/
          const myRegex = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/
          var found = attributionString.match(myRegex)
          var info = found[2]
          return info
      } catch (error) {
        log('attributionString(): Can not parse attribution string')
        return null
      }
    },

    getLayer(type) {
      var groups = this.map.getLayers()
      for (var j = 0; j < groups.getLength(); j++) {
        var group = groups.item(j)
        var layers = group.getLayers()
        for (var i = 0; i < layers.getLength(); i++) {
          var layer = layers.item(i)
          if (layer.get('ltype') == type) {
            return layer
          }
        }
      }
      return null
    },
    getLayerByTitle(title) {
      var groups = this.map.getLayers()
      for (var j = 0; j < groups.getLength(); j++) {
        var group = groups.item(j)
        var layers = group.getLayers()
        for (var i = 0; i < layers.getLength(); i++) {
          var layer = layers.item(i)
          if (layer.get('title') == title) {
            return layer
          }
        }
      }
      return null
    },


    initIcons() {
      if (typeof this.standardIconStyles != 'undefined' && this.standardIconStyles != null) {
        return
      }
      this.standardIconStyles = this.createIcons(1)

      if (typeof this.hoverIconStyles != 'undefined' && this.hoverIconStyles != null) {
        return
      }
      this.hoverIconStyles = this.createIcons(0.5)
    },
    createIcons(opacity) {
      var iconStyles = Object.assign({}, opacity)

      iconStyles.HOMES = getIconStyle(ICONS.HOMES, 0.06, 0, opacity)
      iconStyles.LOCATION = getIconStyle(ICONS.LOCATION, 0.12, 0, opacity)

      iconStyles.SYMBOLS = []
      // On the backend the scaling factor for KML files can be found in the const SCALING_FACTORS
      iconStyles.SYMBOLS.push(getIconStyle(ICONS.SYMBOLS[0], 0.05 * 1.2, 0, opacity))
      iconStyles.SYMBOLS.push(getIconStyle(ICONS.SYMBOLS[1], 0.05 * 0.9, 0, opacity))
      iconStyles.SYMBOLS.push(getIconStyle(ICONS.SYMBOLS[2], 0.05 * 1.2, 0, opacity))

      iconStyles.CRUXES = []
      iconStyles.CRUXES.push(getIconStyle(ICONS.CRUXES[0], 0.1, 0, opacity))
      iconStyles.CRUXES.push(getIconStyle(ICONS.CRUXES[1], 0.1, 0, opacity))
      iconStyles.CRUXES.push(getIconStyle(ICONS.CRUXES[2], 0.1, 0, opacity))

      iconStyles.WEBCAMS = getIconStyle(ICONS.WEBCAMS, 0.08, (Math.PI * 30) / 180, opacity)
      iconStyles.SKIDEPOTS = getIconStyle(ICONS.SKIDEPOTS, 0.1, 0, opacity)
      iconStyles.ACCIDENTS = getIconStyle(ICONS.ACCIDENTS, 0.08, 0, opacity)

      iconStyles.SYMBOLPIES = []
      for (var i=0; i<ICONS.SYMBOLPIES.length; i++) {
        iconStyles.SYMBOLPIES.push(getIconStyle(ICONS.SYMBOLPIES[i], 0.12, 0, opacity))
      }

      return iconStyles
    },
    updateExtent(extent, point) {
      if (point[0] < extent[0]) {
        extent[0] = point[0]
      }
      if (point[1] < extent[1]) {
        extent[1] = point[1]
      }
      if (point[0] > extent[2]) {
        extent[2] = point[0]
      }
      if (point[1] > extent[3]) {
        extent[3] = point[1]
      }
      return extent
    },

    getRasterLayerProperties(mapName) {
      var template = this.rasterLayerProperties[mapName]

      return template
    },

    getStyle(standard, type, i) {
      var style
      var color
      switch (type) {
        case this.layerType.SYMBOLS: {
          return standard ? this.standardIconStyles.SYMBOLS[i] : this.hoverIconStyles.SYMBOLS[i]
        }
        case this.layerType.SYMBOLPIES: {
          return standard ? this.standardIconStyles.SYMBOLPIES[i] : this.hoverIconStyles.SYMBOLPIES[i]
        }        
        case this.layerType.SEGMENTS: {
          style = new Style({
            stroke: new Stroke({
              color: getRiskRgbaColor(i + 0.5),
              width: 6,
            }),
          })
          return style
        }
        case this.layerType.HOMES: {
          return standard ? this.standardIconStyles.HOMES : this.hoverIconStyles.HOMES
        }
        case this.layerType.LOCATION: {
          return standard ? this.standardIconStyles.LOCATION : this.hoverIconStyles.LOCATION
        }
        case this.layerType.ROUTES: {
          color = getStaticRgbaColor()
          if (!standard) {
            color = color.slice(0)
            color[3] = 0.3
          }
          style = new Style({
            stroke: new Stroke({ color: getCssColor(color), width: 4 }),
          })
          return style
        }
        case this.layerType.REGIONS: {
          var fillColor = this.getRegionFillColor(i)
          if (!standard) {
            fillColor = [255, 255, 255, 0.2]
          }
          var width = this.getRegionStrokeWidth(i)
          style = new Style({
            stroke: new Stroke({
              color: this.getRegionStrokeColor(i),
              width: width,
            }),
            fill: new Fill({ color: fillColor }),
          })
          return style
        }
        case this.layerType.CRUXES: {
          return standard ? this.standardIconStyles.CRUXES[i] : this.hoverIconStyles.CRUXES[i]
        }
        case this.layerType.WEBCAMS: {
          return standard ? this.standardIconStyles.WEBCAMS : this.hoverIconStyles.WEBCAMS
        }
        case this.layerType.SKIDEPOTS: {
          return standard ? this.standardIconStyles.SKIDEPOTS : this.hoverIconStyles.SKIDEPOTS
        }
        case this.layerType.ACCIDENTS: {
          return standard ? this.standardIconStyles.ACCIDENTS : this.hoverIconStyles.ACCIDENTS
        }
        case this.layerType.PROTECTEDAREAS: {
          var protColor = getProtectedAreasRgbaColor(i)
          if (!standard) {
            protColor = protColor.slice(0)
            protColor[3] = 0.3
          }          
          style = new Style({
            fill: new Fill({ color: protColor }),
          })
          return style
        }
      }
      throw 'getStyle(): Unsupported type ' + type
    },

    getFeatureProperty(feature, name) {
      var value = feature.get(name)
      if (typeof value != 'undefined' && value == null) {
        return value
      }
      return feature.getProperties()[name]
    },

    calcClusterScalingFactor(count) {
      let y = 1 + Math.log(count-1) / Math.log(200)
      if (y < 1) return 1
      if (y > 2) return 2
      return y
    },

    getFinalStyle(type, feature, standard) {
      var risk
      var i
      switch (type) {
        case this.layerType.SYMBOLS: {
          risk = this.getFeatureProperty(feature, 'risk')
          i = this.getRiskCategory(Number(risk))
          return this.getStyle(standard, type, i)
        }
        case this.layerType.SYMBOLCLUSTER: {
          const clusteredFeatures = feature.get('features')
          var filteredClusteredFeatures = this.getFilteredFeatures(clusteredFeatures)
          if (filteredClusteredFeatures.length == 0) {
            var noStyle = new Style()
            noStyle.display = false  
            return noStyle;
          }
 
          if (clusteredFeatures.length == 1) {
            risk = this.getFeatureProperty(clusteredFeatures[0], 'risk')
            i = this.getRiskCategory(Number(risk))
            return this.getStyle(standard, this.layerType.SYMBOLS, i)
          }

          var colorCount = [0, 0, 0]
          for (var j=0; j < filteredClusteredFeatures.length; j++) {
            risk = this.getFeatureProperty(filteredClusteredFeatures[j], 'risk')
            i = this.getRiskCategory(Number(risk))
            colorCount[i] += 1
          }

          var index = getPieIconIndex(colorCount)
          
          style = this.getStyle(standard, this.layerType.SYMBOLPIES, index)
          var count = filteredClusteredFeatures.length
          // const factor = this.calcClusterScalingFactor(count)
          const factor = 1
          var text = new Text({
            font: `${12*factor}px Roboto`,
            text: count.toString(),
            fill: new Fill({
              color: '#000',
            }),
          })
          style.setText(text)
          return style

          // Caution: Icons are flickering, if more then 33
          // https://github.com/openlayers/openlayers/issues/3137

          // var clonedStyle = style.clone()    
          // var scale = clonedStyle.getImage().getScale()
          // clonedStyle.getImage().setScale(scale*factor)
          // return clonedStyle
        }
        case this.layerType.SEGMENTS: {
          risk = this.getFeatureProperty(feature, 'segRisk')
          i = this.getRiskCategory(Number(risk))
          return this.getStyle(standard, type, i)
        }
        case this.layerType.CRUXES: {
          i = this.getFeatureProperty(feature, 'class') - 1
          return this.getStyle(standard, type, i)
        }
        case this.layerType.REGIONS: {
          var status = this.getFeatureProperty(feature, 'status')
          i = Number(status)
          const width = calculateMapSize()[0]
          const fontSize = Math.floor(linearFunction(width, 600, 1920, 16, 32))

          var textStyle = new Text({
            // https://www.w3schools.com/cssref/pr_font_font.asp
            // https://vuetifyjs.com/en/styles/text/
            font: '500 ' + fontSize + 'px Roboto',
            rotation: (-15 * Math.PI) / 180,
            overflow: true,
            fill: new Fill({ color: status == 1 ? this.getRegionStrokeColor() : this.getRegionStrokeColor() }),
            // stroke: new Stroke({
            //   color: 'white', width: 4
            // }),
            text: this.getRegionStatusText(status, false),
          })
          var style = this.getStyle(standard, type, i)
          style.setText(textStyle)
          return style
        }
        case this.layerType.PROTECTEDAREAS: {
          var prot = this.getFeatureProperty(feature, 'prot_id')
          return this.getStyle(standard, type, Number(prot))
        }

        default: {
          return this.getStyle(standard, type, 0)
        }
      }
    },

    getRiskCategory(risk) {
      if (risk < 1) return 0
      if (risk < 2) return 1
      return 2
    },

    // eslint-disable-next-line no-unused-vars
    getRegionStrokeColor(i) {
      return [51, 51, 51, 1]
    },

    // eslint-disable-next-line no-unused-vars
    getRegionStrokeWidth(i) {
      return 1
    },

    getRegionFillColor(i) {
      var color = getRegionRgbaColor(i)
      return color
    },

    // https://github.com/openlayers/openlayers/blob/12043e147c0ec6079c645f14366e065b75012ace/src/ol/interaction/Select.js
    getDefaultStyleFunction() {
      var that = this
      return function (feature) {
        var type = feature.getProperties()['ltype']
        return that.getFinalStyle(type, feature, false)
      }
    },

    disposeMap() {
      var groups = this.map.getLayers()
      for (var j = 0; j < groups.getLength(); j++) {
        var group = groups.item(j)
        var layers = group.getLayers()
        for (var i = 0; i < layers.getLength(); i++) {
          var layer = layers.item(i)
          var type = layer.get('ltype')
          if (typeof type != 'undefined' && type != null) {
            this.disposeLayer(layer)
          }
        }
      }  
      
      this.symbolLayer = null           
      this.map.setTarget(null)
      this.map = null
    },

    disposeLayer(layer) {
      var type = layer.get('ltype')
      log(`disposeLayer(): "${type}"`)
      if (type == this.layerType.SYMBOLCLUSTER) {
        var source = layer.getSource().getSource()
        if (typeof source != 'undefined' && source != null) {
          source.clear()
          layer.getSource().setSource(null)
        }
      }
      source = layer.getSource()
      if (typeof source != 'undefined' && source != null) {
        source.clear()
      }
      layer.setSource(null)
    }
  },
}
