export const regionColors = {
  RESERVED: {
    MAP: [255, 255, 255, 1],
    EYES: [255, 255, 255, 1],
    HEADER: [255, 255, 255, 1],
  },
  ACTIVE: {
    // 1: Blue
    MAP: [183, 200, 205, 0.7],
    EYES: [183, 200, 205, 0.7],
    HEADER: [183, 200, 205, 0.7],
  },
  TEST: {
    // 2: lightgray
    MAP: [204, 204, 204, 0.5],
    EYES: [204, 204, 204, 0.5],
    HEADER: [204, 204, 204, 0.5],
  },
  WAIT: {
    // 3: lightgray
    MAP: [204, 204, 204, 0.5],
    EYES: [204, 204, 204, 0.5],
    HEADER: [204, 204, 204, 0.5],
  },
  DEMO: {
    // 4: gray
    MAP: [153, 153, 153, 0.6],
    EYES: [153, 153, 153, 0.6],
    HEADER: [153, 153, 153, 0.6],
  },
  PROJECT: {
    // 5: darkgray
    MAP: [32, 32, 32, 0.4],
    EYES: [32, 32, 32, 0.4],
    HEADER: [32, 32, 32, 0.4],
  },
}

export const riskColors = {
  LOW: {
    ICONS: [51, 204, 102, 1],
    EYES: [51, 204, 102, 0.77],
    HEADER: [51, 204, 102, 0.7],
  },
  MID: {
    ICONS: [255, 153, 51, 1],
    EYES: [255, 153, 51, 0.8],
    HEADER: [255, 153, 51, 0.8],
  },
  HIGH: {
    ICONS: [227, 6, 19, 1],
    EYES: [227, 6, 19, 0.48],
    HEADER: [227, 6, 19, 0.5],
  },
}

// https://tdekoning.github.io/rgba-converter/
export const dangerColors = [
  [255, 255, 255, 1],         // UNKNOW (0)
  [170, 255, 127, 1],         // Low(1)
  [255, 255, 0, 1],           // Moderate(2)
  [255, 170, 0, 0.8],         // Considerable(3)
  [255, 0, 0, 0.5],           // High(4)
  [255, 255, 255, 255, 0.5]   // Very High (5)
]

export const primaryColor = [94, 129, 150, 1]
export const staticColor = [51, 51, 51, 1]
export const navigationColor = [76, 76, 76, 1]

export const protectedAreasColors = [
  [255, 54, 26, 0.5],         // Mandatory (10)
  [255, 204, 0, 0.5],         // Recommended (20)
]

export function getCssColor(rgba) {
  return `rgba(${rgba[0]},${rgba[1]},${rgba[2]},${rgba[3]})`
  //return "#" + componentToHex(rgba[0]) + componentToHex(rgba[1]) + componentToHex(rgba[2])
}

export function getRegionCssColor(status) {
  return getCssColor(getRegionRgbaColor(status))
}

export function getRegionRgbaColor(status, opacityMode) {
  var mode = opacityMode
  if (typeof mode == 'undefined' || mode == null) {
    mode = 'MAP'
  }

  var myArray = Object.values(regionColors)
  var color = myArray[status][mode]
  return color
}

export function getRiskCssColor(risk, opacityMode) {
  return getCssColor(getRiskRgbaColor(risk, opacityMode))
}

export function getRiskRgbaColor(risk, opacityMode) {
  var level = 1
  if (risk >= 2) {
    level = 2
  }
  if (risk < 1) {
    level = 0
  }

  var mode = opacityMode
  if (typeof mode == 'undefined' || mode == null) {
    mode = 'ICONS'
  }

  var myArray = Object.values(riskColors)
  var color = myArray[level][mode]
  return color
}

export function getCustomColor(variableName) {
  //var bodyStyles = getComputedStyle(document.documentElement, null)
  var bodyStyles = getComputedStyle(document.documentElement)
  var color = bodyStyles.getPropertyValue(variableName)
  return color
}

export function getPrimaryCssColor() {
  return getCssColor(primaryColor)
}

export function getPrimaryRgbaColor() {
  return primaryColor
}

export function getStaticCssColor() {
  return getCssColor(staticColor)
}

export function getStaticRgbaColor() {
  return staticColor
}

export function getNavigationCssColor() {
  return getCssColor(navigationColor)

  // Doesn't work: Are we getting the color to early?
  // return getCssColor(getCustomColor('--navigationColor'))
}

export function getNavigationRgbaColor() {
  return navigationColor
}

export function getDangerRgbaColor(danger) {
  return dangerColors[danger]
}

export function getDangerCssColor(danger) {
  return getCssColor(getDangerRgbaColor(danger))
}

export function getProtectedAreasRgbaColor(prot) {
  if (prot == 10) return protectedAreasColors[0]
  return protectedAreasColors[1]
}

export function getProtectedAreasCssColor(prot) {
  return getCssColor(getDangerRgbaColor(prot))
}


