<template>
  <div>
    <app-header @displayHelp="displayHelp" @dispose="dispose"></app-header>
    <div v-resize="onResize" class="overflow-y-auto" :style="{ height: viewContentHeight + 'px' }">
      <v-container class="col-md-10 col-lg-8">
        <v-card flat class="px-4 py-0 mx-sm-0 mt-2 mb-8" width="100%">
          <donation></donation>
        </v-card>
        <v-expansion-panels flat multiple v-model="panels">
          <v-expansion-panel v-model="panels" v-for="newsItem in newsItems" :key="newsItem.title">
            <v-expansion-panel-header :id="newsItem.id" disable-icon-rotate class="px-4" @click="readNews(newsItem.id)">
              <div>
                <div class="title" id="newsTitleId">{{ newsItem.content.title }}</div>
                <div class="caption">{{ newsItem.date }}</div>
              </div>
              <template v-slot:actions>
                <v-icon v-if="newsItemRead(newsItem.id)" color="grey" class="ml-6"> mdi-email-open </v-icon>
                <v-icon v-else :style="{ color: getWarningColor() }" class="ml-6"> mdi-email </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="body-1" v-html="newsItem.content.content"></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </div>
    <help-dialog ref="help"></help-dialog>
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader.vue'
import HelpDialog from '../components/HelpDialog.vue'
import { utilityMixin } from '../mixins/utilityMixin'
import { resizeMixin } from '../mixins/resizeMixin'
import NProgress from 'nprogress'
import store from '@/store/store'
import Donation from '../components/Donation.vue'
import { log, headerHeight } from '../utilities'

export default {
  name: 'news-view',
  mixins: [resizeMixin, utilityMixin],
  data: () => ({
    newsItems: [],
    readIds: [],
    maxUnreadNews: 9,
    panels: []
  }),
  components: {
    AppHeader,
    HelpDialog,
    Donation,
  },
  methods: {
    newsItemRead(newsId) {
      return this.readIds.includes(newsId)
    },

    // Marks the news in object and store "as read"
    readNews(newsId) {
      if (!this.readIds.includes(newsId)) {
        this.readIds.push(newsId)
        store.dispatch('news/setNewsRead', newsId)
      }
    },
    displayHelp(tag) {
      // eslint-disable-next-line no-console
      console.log('NewsView: Display help')
      this.$refs.help.open(tag)
    },
    dispose() {  
  
    },    
    // Makes sure, that all news that exceed the number 9 are for sure marked as read
    filterNews() {
      for (var i=this.maxUnreadNews; i<this.newsItems.length; i++) {
        var id = this.newsItems[i].id
        this.readNews(id)
      }
    }
  },
  mounted() {
    if (this.panels.length != 0) {
      // An id for a news to be opened was specified, now scroll to it
      var id = Number(this.$route.query.id)
      var elem = document.getElementById(id.toString())
      elem.scrollIntoView(true) 
      
      // On smartphone the Header doesn't display unless the following workaround is applied
      // https://stackoverflow.com/questions/13614112/using-scrollintoview-with-a-fixed-position-header
      var scrolledY = window.scrollY;
      if(scrolledY) {
        window.scroll(0, scrolledY - headerHeight)
      }
    }
  },
  created() {
    const items = store.state.news.items
    this.newsItems = items.map((item) => {
      return { id: item.id, content: item[this.$vuetify.lang.current], date: item.date }
    })

    this.readIds = store.state.news.readIds || []
    this.filterNews()
    this.updateHeader(this.$vuetify.lang.t('$vuetify.newsChannel.title'), 1, null)

    var id = Number(this.$route.query.id)
    if (typeof id != 'undefined' && id != null) {
      // eslint-disable-next-line no-console
      log('created(): Open and scroll to news ' + id)  
      for (var i=0; i<this.newsItems.length; i++) {
        if (this.newsItems[i].id == id) {
          this.panels = [i]
          break       
        }      
      }  
    }
  },
  beforeRouteEnter(routeTo, routeFrom, next) {
    // inline async function to wait for async dispatch calls
    const getDataAsync = async function () {
      await store.dispatch('news/fetchNews')
      NProgress.done()
    }
    NProgress.start()
    getDataAsync().then(() => {
      NProgress.done()
      next()
    })
  },
}
</script>

<style scoped>
.v-expansion-panels {
  border: 1.2px solid lightgray;
}

.v-card {
  border: 1.2px solid lightgray;
}

/* Lines between the expansion apanels */
.v-expansion-panel::after {
  border-top: solid;
  border-top-width: 1.2px;
  border-top-color: rgba(0, 0, 0, 0.12);
  opacity: 1;
}

/* style the hidden child component with the help of a deep selector:
https://vue-loader.vuejs.org/guide/scoped-css.html#child-component-root-elements */
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0px 16px;
}

#newsTitleId {
  line-height: 140%;
}

</style>
