<template>
  <v-dialog overlay-opacity="0.0" v-model="dialog" @keydown.esc="close()" max-width="500" scrollable>
    <v-card max-height="50vh">
      <v-toolbar :color="getHeaderColor()" dense flat>
        <v-toolbar-title class="text-truncate pr-5">{{
          $vuetify.lang.t(`$vuetify.layerGroups.title`)
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn class="close-button" icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-4 text font-weight-normal textDense">
        <v-expansion-panels flat v-model="panel">
          <v-expansion-panel v-for="compGroup in compGroups" :key="compGroup.title">
            <v-expansion-panel-header class="title">
              {{compGroup.title}}
            </v-expansion-panel-header>

            <v-expansion-panel-content v-if="compGroup.base">   
              <v-radio-group v-model="radio">          
                <v-row class="ma-0" v-for="compLayer in compGroup.compLayers" :key="compLayer.title">            
                  <v-col class="px-0 pb-0 pt-1" cols="8">               
                    <v-radio @click="onRadioClicked(compGroup)" class="dense pa-0" :label="compLayer.title"></v-radio>               
                  </v-col>
                  <v-col class="py-0 pl-0 pr-2" cols="3" align="center">
                    <v-slider 
                      v-model="compLayer.opacity"
                      @input="onSliderMove(compLayer)"
                      min="0"
                      step="0.1"
                      max="1">
                    </v-slider>
                  </v-col>             
                  <v-col v-if="compLayer.info != null" class="px-0 pb-0 pt-0" cols="1" align="right"> 
                    <v-btn
                      dark
                      class="my-button"
                      small
                      :color="getPrimaryColor()"
                      icon
                      target="_blank"
                      :href="compLayer.info">
                        <v-icon size="24px">mdi-information</v-icon>
                    </v-btn> 
                      <!-- <a :href="compLayer.info" target="_blank" class="font-weight-bold">Info</a> -->
                  </v-col>
                
                </v-row>
              </v-radio-group>  
            </v-expansion-panel-content>            

            <v-expansion-panel-content v-else>             
              <v-row class="ma-0" v-for="compLayer in compGroup.compLayers" :key="compLayer.title">
                <v-col class="px-0 pb-0 pt-1" cols="8">               
                  <v-checkbox @click="onCheckboxClicked(compLayer)" class="dense pa-0" v-model="compLayer.visible" :label="compLayer.title"></v-checkbox>               
                </v-col>
                <v-col class="py-0 pl-0 pr-2" cols="3" align="center">
                  <v-slider 
                    v-model="compLayer.opacity"
                    @input="onSliderMove(compLayer)"
                    min="0"
                    step="0.1"
                    max="1">
                  </v-slider>
                </v-col>             
                <v-col v-if="compLayer.info != null" class="px-0 pb-0 pt-0" cols="1" align="right"> 
                  <v-btn
                    dark
                    class="my-button"
                    small
                    :color="getPrimaryColor()"
                    icon
                    target="_blank"
                    :href="compLayer.info">
                      <v-icon size="24px">mdi-information</v-icon>
                  </v-btn> 
                    <!-- <a :href="compLayer.info" target="_blank" class="font-weight-bold">Info</a> -->
                </v-col>
              </v-row>
            </v-expansion-panel-content>

          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { utilityMixin } from '../mixins/utilityMixin'
import { log } from '../utilities'

export default {
  name: 'overlay-dialog',
  mixins: [utilityMixin],
  data: () => ({
    dialog: false,
    compGroups: [],

    // The radio button of the base maps
    radio: 0,

    // An array of indexes of panels that shall be open
    panel: 1
  }),
  methods: {
    // eslint-disable-next-line no-unused-vars
    open(groups) {
      var base
      var title
      var visible
      var info
      var opacity

      this.compGroups = []
      this.dialog = true

      for (var j = 0; j < groups.getLength(); j++) {
        var group = groups.item(j)

        title = group.get('title')     
        base = group.get('base') 

        var compGroup = {olGroup: group, title: title, base: base, compLayers: []}
        log('Group: ' + title)
        var layers = group.getLayers()

        for (var i = 0; i < layers.getLength(); i++) {
          var layer = layers.item(i)

          title = layer.get('title')     
          visible = layer.get('visible')
          info = layer.get('info')
          opacity = layer.get('opacity')

          if (base && visible) {
            this.radio = layers.getLength()-(i+1)
          }

          var compLayer = {olLayer: layer, title: title, visible: visible, info: info, opacity: opacity}
          compGroup.compLayers.unshift(compLayer)
          log(' - Layer: ' + title)
        }

        // unshift = push front
        this.compGroups.unshift(compGroup)

      }

    },
    close() {
      this.dialog = false
    },
    onCheckboxClicked(currentCompLayer) {
      currentCompLayer.olLayer.set('visible', currentCompLayer.visible)
    },

    onRadioClicked(currentCompGroup) {
      for (var i = 0; i < currentCompGroup.compLayers.length; i++) {
        var compLayer = currentCompGroup.compLayers[i]
        if (i == this.radio) {
          compLayer.visible = true
          compLayer.olLayer.set('visible', true)
        } else {
          compLayer.visible = false
          compLayer.olLayer.set('visible', false)
        }
      }
    },


    onSliderMove(currentCompLayer) {
      currentCompLayer.olLayer.set('opacity', currentCompLayer.opacity)
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.close-button {
  background-color: var(--navigationColor);
  color: white !important;
  border-radius: 0%;
  margin-right: -16px !important;
}

.my-button:hover {
  color: gray !important;
}

.v-radio {
  margin-bottom: 2px !important;
}

.v-input {
  margin-top: 0px !important;
}

.v-expansion-panels {
  border: 1.2px solid lightgray;
}

/* Lines between the expansion apanels */
.v-expansion-panel::after {
  border-top: solid;
  border-top-width: 1.2px;
  border-top-color: rgba(0, 0, 0, 0.12);
  opacity: 1;
}

.v-expansion-panel-header,
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap 
{
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.v-dialog > .v-card > .v-card__text {
    padding: 8px !important
}

/* Below each v-row it takes too muchspace */
.v-input >>> .v-messages {
  display: none !important;
}   

/* Problems on smartphones with width 
div >>>.v-dialog {
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
} */

</style>
