<template>
  <v-container>
    <div class="text-h6 ml-1 mr-1 mt-3 mb-4 justify-left">{{ $vuetify.lang.t(`$vuetify.intro.supported.title`) }}</div>
    <v-row class="text ml-1 mr-1 mb-3 textDense">{{ $vuetify.lang.t(`$vuetify.intro.supported.content`) }}</v-row>

    <v-row no-gutters class="pl-1">
      <v-col cols="2" class="pt-2">
        <a :href="$vuetify.lang.t(`$vuetify.intro.supported.partners.bfu.link`)" target="_blank">
          <v-img width="95%" src="../assets/Logo_BFU.svg"></v-img>
        </a>
      </v-col>
      <v-col cols="10" class="pt-2">   
      </v-col>
    </v-row>

    <v-row no-gutters class="pl-1">  
      <v-col cols="4" class="pt-1 pl-2">
        <a :href="$vuetify.lang.t(`$vuetify.intro.supported.partners.sac.link`)" target="_blank">
          <v-img width="90%" src="../assets/Logo_SAC.svg"></v-img>
        </a>
      </v-col>   
      <v-col cols="4" class="pt-1 pl-2">
        <a href="https://www.alpenverein.at/portal/bergsport/sicheramberg/skitouren/index.php" target="_blank">
          <v-img width="100%" src="../assets/Logo_OeAV.svg"></v-img>
        </a>
      </v-col>  
      <v-col cols="4" class="pt-0 pl-2">
        <a href="https://www.alpenverein.de" target="_blank">
          <v-img width="80%" src="../assets/Logo_DAV.svg"></v-img>
        </a>
      </v-col>            
    </v-row>

    <v-row no-gutters class="pl-1">
      <v-col cols="4" class="pt-0 pl-2">
        <a href="https://alpenverein.it/" target="_blank">
          <v-img width="80%" src="../assets/Logo_AVS.svg"></v-img>
        </a>
      </v-col>       
      <v-col cols="2" class="pt-2 pl-2">
        <a href="https://www.petzl.com/fondation/fondation-petzl?language=fr" target="_blank">
          <v-img width="75%" src="../assets/Logo_Petzl.svg"></v-img>
        </a>
      </v-col>      
      <v-col cols="2" class="pt-2 pl-4">
        <a href="http://www.baechli-bergsport.ch/" target="_blank">
          <v-img class="mt-1" width="80%" src="../assets/Logo_Baechli.svg"></v-img>
        </a>
      </v-col>          
      <v-col cols="4" class="pt-4 pl-6">
        <a :href="$vuetify.lang.t(`$vuetify.intro.supported.partners.colltex.link`)" target="_blank">
          <v-img width="65%" src="../assets/Logo_Colltex.svg"></v-img>
        </a>
      </v-col> 
      <v-col cols="2" class="pt-2">
        <!--  <a href="https://www.mammut.ch/" target="_blank">
          <v-img width="75%" src="../assets/Logo_Mammut.svg"></v-img>
        </a> -->
      </v-col>                
      <v-col cols="2" class="pt-2">
      </v-col>       
    </v-row>

  </v-container>
</template>

<script>
import { utilityMixin } from '../mixins/utilityMixin'
export default {
  name: 'partners',
  mixins: [utilityMixin],
  methods: {},
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
